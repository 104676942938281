<template>
  <div 
  ref="cardBoxBackground" 
  :style="{
    height: '305px', width: 375, background: 'linear-gradient(180deg, ' + ($parent.zoom >= 4 ? '#f6f6f6' : '#f6f6f6') + ' 0%, rgba(255,255,255,1) 100%)' }"
  >
    <div id="card-box" ref="cardBox" class="m-0 py-1 is-relative is-clipped" :style="{ height: '305px' }">
      <div v-if="true" id="card-slider" ref="cardSlider" class="mx-4 my-0 is-relative is-flex is-align-items-left is-justify-content-left" :style="{height: '250px', width: sliderWidth + 'px', transform: 'translateX(' + left + 'px)'}" :class="{card_slider_transition: card_transition}">
        <div :style="{width: card_width + 'px', margin: '0.5rem ' + card_right_space + 'px 0 0', opacity: (selectedNum == 99999 ? 1 : 0.5)}" class="has-background-white c-border-radius-5 c-box-shadow-1" style="height: 250px;">
          <div class="px-2 py-4 has-text-left is-relative c-border-radius-3" style="height: 250px;">
            <div class="columns is-mobile is-gapless is-marginless" data-action="click->home#filterButton">
              <div class="column">
                <div class="c-ml-m025 mt-1 pl-4 has-text-grey-dark"><span :class="{'button': genre.length < 8, 'c-line-height-6 is-size-6 c-line-height-crop-6-6 has-text-weight-semibold': genre.length >= 8}" class="is-rounded is-link">{{ genre }}</span></div>
              </div>
              <div class="column is-narrow">
                <div class="pr-4 has-text-grey-lighter">
                  <div class="is-size-1 c-line-height-1 letter-spacing-m0125 is-family-monospace">{{ i_t_list.length }}<span class="pl-1 is-size-6 c-line-height-6">件</span></div>
                </div>
              </div>
            </div>
            <div class="pt-3 pb-3 pl-4 pr-2" style="height: 150px; overflow-y: scroll; overflow-x: hidden;" :style="{width: card_width - 40 + 'px'}">
              <div v-for="(i_t, index) in i_t_list" :key="index" @click="$parent.selectMarker(index)" class="is-flex is-align-items-center mb-3">
                <template v-if="i_t[0] === 'item'">
                  <ion-icon name="ellipse" class="is-size-6 mr-2 color-dot"></ion-icon>
                  <div class="is-size-7">{{ items[i_t[1]].name }}</div>
                </template>
                <template v-if="i_t[0] === 'tweet'">
                  <ion-icon name="ellipse" class="is-size-6 mr-2 color-tweet"></ion-icon>
                  <div class="is-size-7">{{ tweets[i_t[1]].place_name }}</div>
                </template>
              </div>
            </div>
            <div class="scroll-bottom-white is-relative c-height-3"></div>
          </div>
        </div>

        <div v-for="(i_t, index) in i_t_list" :key="index" class="mt-2">
          <div :style="{
          width: card_width + 'px', 
          margin: '0 ' + card_right_space + 'px 0 0', 
          background: ((selectedNum - 1 == index || selectedNum == index || selectedNum + 1 == index || selectedNum - 99999 == index) && i_t[0] === 'item') ? 'url(\'' + items[i_t[1]].h_thumimg + '\')' : '#ffffff', 
          backgroundSize: ((selectedNum - 1 == index || selectedNum == index || selectedNum + 1 == index || selectedNum - 99999 == index) && i_t[0] === 'item') ? '250px' : '235px', 
          transform: (selectedNum == index ? 'scale(1,1)' : 'scale(0.9,0.9)')
          }" class="mask">
          <template v-if="selectedNum - 1 == index || selectedNum == index || selectedNum + 1 == index || selectedNum - 99999 == index">
            <template v-if="i_t[0] === 'item'">
            <a :href="'/items/' + items[i_t[1]].id + geneUrlParams" class="m-0 p-0">
            <div class="m-0 p-0 text-box-bg" style="width: 250px; height: 250px;">
              <div :ref="'descBox' + index"  class="py-4 px-4 text-box is-clipped has-text-white has-text-weight-semibold" style="width: 250px; height: 250px;">
                <div class="mt-2 is-size-5 c-line-height-5">{{ items[i_t[1]].name }}</div>
                <div class="mt-1 c-is-size-7h c-line-height-7h letter-spacing-01">{{ items[i_t[1]].bunrui }}</div>
                <!--<div class="mt-3 py-1 px-3 is-inline-block c-is-size-7h has-background-white has-text-black-ter">NEW</div>-->
              </div>
            </div>
            </a>
            </template>
            
            <template v-if="i_t[0] === 'tweet'">
            <div class="m-0 p-0 text-box-bg" style="width: 250px; height: 250px;">
              <div class="pt-2 has-text-centered c-line-height-7 c-is-size-7h has-text-grey-dark">TWEET</div>
              <a :href="tweets[i_t[1]].url" class="has-text-grey-dark" target="_blank" rel="noopener">
                <div class="tweet-box mx-2 my-1 p-4 has-background-white c-border-radius-7 c-border-ccc">
                  <article class="media">
                    <figure class="media-left">
                      <p class="image is-48x48">
                        <img class="is-rounded" loading="lazy" decoding="async" :src="tweets[i_t[1]].profile_image_url_https">
                      </p>
                    </figure>
                    <div class="media-content">
                      <div class="content">
                        <strong class="has-text-grey-dark c-line-height-7 is-size-7">{{tweets[i_t[1]].name}}</strong>
                        <div class="has-text-grey c-line-height-7 is-size-7">@{{tweets[i_t[1]].screen_name}}</div>
                      </div>
                    </div>
                  </article>
                  <div style="max-height: 135px; overflow-y: scroll;">
                  <div class="mt-3 c-line-height-6 is-size-7 c-line-height-crop-6-7" v-html="tweets[i_t[1]].full_text"></div>
                  <div class="mt-3 c-border-radius-8" v-if="tweets[i_t[1]].photo_url != ''">
                    <img loading="lazy" decoding="async" :src="tweets[i_t[1]].photo_url">
                  </div>
                  </div>
                </div>
              </a>
            </div>
            </template>
          </template>

          </div>
        </div>

      </div>
    </div>
    <svg class="shape" version="1.1" viewBox="0 0 250 250">
    <clipPath id="svgPath">
      <path d="M224.203775,0.243664717 C231.631675,0.243664717 235.345625,0.243664717 239.34355,1.50779484 C243.7086,3.0965446 247.147125,6.53506462 248.735875,10.9001197 C250,14.8980297 250,18.6119822 250,26.0398869 L250,224.203778 C250,231.631683 250,235.345635 248.735875,239.343545 C247.147125,243.708602 243.7086,247.147117 239.34355,248.73587 C235.345625,250 231.631675,250 224.203775,250 L26.0398898,250 C18.6119894,250 14.89804,250 10.9001147,248.73587 C6.53506469,247.147117 3.09653969,243.708602 1.50778972,239.343545 C0.243664717,235.345635 0.243664717,231.631683 0.243664717,224.203778 L0.243664717,26.0398869 C0.243664717,18.6119822 0.243664717,14.8980297 1.50778972,10.9001222 C3.09653969,6.53506755 6.53506469,3.0965446 10.9001147,1.50779484 C14.89804,0.243664717 18.6119894,0.243664717 26.0398898,0.243664717 L224.203775,0.243664717 Z" />
    </clipPath>
    <clipPath id="svgPath2">
      <path d="M0,125 C0,0 0,0 125,0 C250,0 250,0 250,125 C250,250 250,250 125,250 C0,250 0,250 0,125"/>
    </clipPath>
    </svg>
  </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm'
export default {
  props: ['filter', 'items', 'i_t_list', 'height', 'selecteditem', 'tweets', 'update_date'],
  data: function() {
    return {
      left: 0,
      card_width: (375),
      card_right_space: 20,
      card_margin: 300,
      card_transition: true,
      preCount: 0,
      preX: 0,
      hasScrolledX: false,
      hasScrolledY: false,
      positionX: 0,
      startX: 0,
      startY: 0,
      preLeft: 0,
      velocity: 0,
      initEnd: false,
      descHeight: 110,
      selectedNum: 99999
    }
  },
  computed: {
    genre: function () {
      let text = ""
      if(this.filter.motive == '') text = '更新＆新着'
      if(this.filter.motive != '') text = this.filter.motive
      if(this.filter.category != '') text += ("・" + this.filter.category)
      if(this.filter.tag != '') text += ("・" + this.filter.tag)
      return text
    },
    selectedCard: function() {
      //console.log('card selectedCard')
      if (this.selectedNum === 99999) {
        return 0
      } else {
        return this.selectedNum + 1
      }
    },
    sliderWidth: function() {
      let w =
        (this.card_width + this.card_right_space) *
          (this.i_t_list.length + 2) +
        this.card_margin
      return w
    },
    descWidth: function() {
      return this.card_width - (102 + 2 + 10)
    },
    geneUrlParams: function() {
      let params = ""
      if(this.filter.motive != '') params +=  "/" + this.filter.motive
      if(this.filter.category != '') params +=  "/" + this.filter.category
      if(this.filter.tag != '') params +=  "/" + this.filter.tag
      return params
    }
  },
  watch: {
    selectedCard: function() {
      this.moveCards()
    },
    selecteditem: function() {
      // マップ上のアイコンを選択
      if(this.selectedCard == this.selecteditem + 1) {
        //console.log('match')
      } else {
        this.selectedNum = this.selecteditem
      }
    }
  },
  mounted: function() {
    //console.log("card/mounted")
    this.init()
  },
  methods: {
    init: function() {
      //console.log("card/init")
      let _self = this
      if (this.$refs.cardBox.clientWidth - 300 > 100) {
        this.card_width = this.$refs.cardBox.clientWidth - 100
        this.card_margin = 100
        if (this.card_width > 500) {
          this.card_width = 420
          this.card_margin = this.$refs.cardBox.clientWidth - 420
        }
      } else {
        this.card_width = this.$refs.cardBox.clientWidth - 100
        this.card_margin = 100
      }
      this.card_width = 250
      this.$refs.cardBox.addEventListener("touchstart", this.handleStart, false)
      this.$refs.cardBox.addEventListener("touchend", this.handleEnd, false)
      this.$refs.cardBox.addEventListener("touchmove", this.handleMove, false)
      this.initEnd = true
      // for handleMove
      let clientRect = this.$refs.cardBox.getBoundingClientRect()
      this.positionX = clientRect.left + window.pageXOffset - this.positionX
      //this.selectedNum = this.selectedCard
      //alert(this.selectedCard)
      if(this.selectedCard != this.selecteditem + 1) {
        this.selectedNum = this.selecteditem
      }
      if(this.selectedNum !== 99999) this.moveCards(false)
    },
    handleStart: function(e) {
      //console.log("card/handleStart")
      this.startX = e.changedTouches[0].pageX
      this.startY = e.changedTouches[0].pageY
      this.card_transition = false
    },
    handleEnd: function() {
      //console.log("card/handleEnd")
      this.card_transition = true
      this.preX = 0
      this.hasScrolledX = false
      this.hasScrolledY = false
      this.afterChange(this.getScrollCount())
    },
    handleMove: function(e) {
      //console.log("card/handleMove")
      let x = e.changedTouches[0].pageX - this.positionX
      let y = e.changedTouches[0].pageY
      if ((this.startX - x > 12 || x - this.startX > 12) && !this.hasScrolledY) {
        this.hasScrolledX = true
      }
      if ((this.startY - y > 12 || y - this.startY > 12) && !this.hasScrolledX) {
        this.hasScrolledY = true
      }
      if (this.hasScrolledX && !this.hasScrolledY) {
        e.preventDefault()
        if (this.preX != 0) this.left -= (this.preX - x) * 1.5
        this.preX = x
      }
      this.velocity = this.preLeft - e.changedTouches[0].pageX
      this.preLeft = e.changedTouches[0].pageX
    },
    getScrollCount: function() {
      //console.log("card/getScrollCount")
      let length = this.i_t_list.length
      let m = 1
      if (Math.abs(this.velocity) < 4) m = this.card_width / 2
      let c = this.preCount
      let k = this.preCount * (this.card_width + this.card_right_space) * -1
      if (this.left < k - m) length > c ? c++ : (c = 0)
      if (this.left > k + m) c > 0 ? c-- : (c = length)
      return c
    },
    moveCards: function(transition = true) {
      //console.log("card/moveCards")
      this.card_transition = transition
      this.left = this.selectedCard * (this.card_width + this.card_right_space) * -1
      this.preCount = this.selectedCard
    },
    afterChange: function(count) {
      //console.log("card/afterChange")
      let _self = this
      if (this.preCount == count) {
        this.moveCards()
      } else {
        setTimeout(function() {
          _self.$parent.selectMarker(count === 0 ? 99999 : (count-1))
        }, 200)
        this.selectedNum = count === 0 ? 99999 : (count-1)
      }
    },
    pre: function() {
      let id = this.selectedCard - 1
      if (id < 0) id = this.i_t_list.length - 1
      this.afterChange(id)
    },
    next: function() {
      let id = this.selectedCard + 1
      if (id == this.i_t_list.length) id = 0
      this.afterChange(id)
    }
  }
}
</script>

<style scoped lang="scss">
#card-box {
  margin: 10px 0 0 0;
  padding: 0;
  height: 105px;
  position: relative;
  z-index: 9999;
  overflow: scroll;
}

#card-slider {
  margin: 10px 0;
  padding: 0;
  height: 85px;
  position: relative;
  display: flex;
  justify-content: left;
  align-items: left;
}
#card-box {
  z-index: 9999999;
  .mask {
    -webkit-clip-path: url(#svgPath);
    clip-path: url(#svgPath);
    background: white;
    transition-duration: 0.3s;
  }
  #card-slider {
    z-index: 2;
  }
  .card_slider_transition {
    transition-duration: 0.3s;
  }
  .text-box-bg {
    background: url('/img/ami.svg');
  }
  .text-box {
    background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(255,255,255,0) 100%);
  }
}

.scroll-bottom-white {
  margin-top: -2rem;
  width: 100%;
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}

.tweet-box {
  overflow-y: scroll;
}
</style>
