import { Controller } from "stimulus"
import * as Util from "./util.js"
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import relativeTime from 'dayjs/plugin/relativeTime';

export default class extends Controller {
  static targets = ["query", "datetime"]

  initialize() {
    Util.consoleLog("search initialize")
    Util.consoleLog(Date.now())
  }

  connect() {
    Util.consoleLog("search connect!")
    this.init()
  }

  disconnect() {
    Util.consoleLog("search disconnect!")
    window.removeEventListener("scroll", this.function_scrolling, false)
  }

  init() {
    Util.consoleLog("search init")
    this.sizes = {
      w: Util.getIOSWindowWidth(),
      h: Util.getIOSWindowHeight()
    }
    this.search_params = {
      searchBox: false
    }
    this.initScroll()
    Util.sendPageview()// analytics
    this.changeFormatDatetime()
  }
////////////////
// Datetime
////////////////
changeFormatDatetime() {
  dayjs.locale('ja');
  dayjs.extend(relativeTime);
  this.datetimeTargets.forEach((el, i) => {
    el.innerHTML = dayjs(new Date(el.dataset.datetime)).fromNow()
  })
}
////////////////
// Search
////////////////
searchOn() {
  document.getElementById('search-box').classList.toggle("search_box_on")
  this.search_params.searchBox = !this.search_params.searchBox
  if(this.search_params.searchBox) {
    document.getElementById('search-box-input').focus()
  }
}
searchNext() {
  Util.consoleLog("search searchNext")
  this.createSarchList(this.queryTarget.value)
}
createSarchList(filterText = '') {
  Util.consoleLog("search createSarchList")
  const params = new URLSearchParams()
  params.set('words', filterText)
  document.getElementById('search-list').innerHTML = ""
  fetch('/home/search/?' + params.toString())
  .then(response => response.text())
  .then(items => {
    let t = document.querySelector('#search_template')
    JSON.parse(items).forEach((item, i) => {
      t.content.querySelector('.item-name').innerHTML = item[1]
      t.content.querySelector('.item-link').href = Routes.item_path(item[0])
      let clone = document.importNode(t.content, true)
      document.getElementById('search-list').appendChild(clone)
    })
  })
}

////////////////
// Navi
////////////////
  scrollTop() {
    Util.consoleLog("search scrollTop")
    Util.utiltween(document.scrollingElement.scrollTop, 0, document.documentElement, "scrollTop")
  }

////////////////
// Scroll
////////////////
  initScroll() {
    this.scrolly = 999
    this.scrolling()
    this.function_scrolling = this.scrolling.bind(this) // 一旦変数に入れないとremoveEventListenerが効かない
    window.addEventListener("scroll", this.function_scrolling, false)
  }

  scrolling() {
    Util.consoleLog("search scrolling")
    if(this.scrolly < this.preScroll) {
      if((this.preScroll - this.scrolly) > 6) {
        Util.consoleLog("search down")
      }
    } else {
      if((this.scrolly - this.preScroll) > 6) {
        Util.consoleLog("search up")
      }
    }
    this.scrolly = window.pageYOffset
    this.preScroll = this.scrolly
    if(this.scrolly < Util.getIOSWindowHeight() / 2) {
      if(document.getElementById('scrollTopRightBtn') != null) {
        document.getElementById('scrollTopRightBtn').classList.add("button_none")
      }
    } else {
      Util.consoleLog("home scrolltop remove")
      if(document.getElementById('scrollTopRightBtn') != null) {
        document.getElementById('scrollTopRightBtn').classList.remove("button_none")
      }
    }
  }

}
