import { Controller } from "stimulus"
import Vue from 'vue/dist/vue.esm'
import Map from '../vue/map-item.vue'
import * as Util from "./util.js"
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import relativeTime from 'dayjs/plugin/relativeTime'

export default class extends Controller {
  static targets = ["category", "list", "landmark", "map_item", "railline", 'locations', "map", 'query', 'search_collection', 'search_results', "tag", "photo_select", "graph", "graph_color", "datetime" ]

  initialize() {
    Util.consoleLog("items initialize")
    let _app = this
    this.originalMapEl = document.querySelector('#mapbox').innerHTML
    document.addEventListener('turbolinks:visit', function teardown() {
      document.querySelector('#mapbox').innerHTML = _app.originalMapEl
      document.removeEventListener('turbolinks:visit', teardown)
    })
  }

  connect() {
    Util.consoleLog("items connect!")
    this.init()
  }

  disconnect() {
    Util.consoleLog("items disconnect!")
    window.removeEventListener("scroll", this.function_scrolling, false)
    window.removeEventListener("resize", this.adjustNaviTop, false)
    this.mapvue.$destroy()
    if(this.myChart != undefined) this.myChart.destroy()
  }

  async init() {
    Util.consoleLog("items init")
    this.map_params = Util.map_params(this.mapTarget.dataset, this.data)
    let myPosition = await Util.getMyPosition(this.map_params.locationNum)
    this.map_params.face = Util.face(this.locationsTargets, this.map_params.locationNum, this.mapTarget.dataset, myPosition)
    this.navi_params = Util.naviParams()
    this.search_params = { searchBox: false }
    this.setMapboxHeight()
    this.initScroll()
    this.initMapVue()
    if(this.map_params.isPC) Util.drawPath(this.navi_params)
    if(this.map_params.isPC) this.setRouteLinks()
    Util.consoleLog("items init end")
    window.addEventListener("resize", this.adjustNaviTop, false)
    Util.sendPageview()// analytics
    this.anchorScroll()

    this.changeFormatDatetime()
    this.chart()
    //this.setLightbox()
  }

////////////////
// Datetime
////////////////
  changeFormatDatetime() {
    dayjs.locale('ja');
    dayjs.extend(relativeTime);
    this.datetimeTargets.forEach((el, i) => {
      el.innerHTML = dayjs(new Date(el.dataset.datetime)).fromNow()
    })
  }

////////////////
// chart
////////////////
  chart() {
    let labels = []
    let data = []
    let ctx = document.getElementById("myRadarChart");
    if(ctx != null) {
      this.graphTargets.forEach(function(graph) {
        labels.push(graph.dataset.name)
        data.push(graph.dataset.num)
      })
      let colors = {
        "grey": {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          borderColor: 'rgba(0, 0, 0, 0.1)',
          pointBackgroundColor: 'rgba(0, 0, 0, 0.1)',
          pointBorderColor: 'rgba(0, 0, 0, 0.1)',
          pointHoverBackgroundColor: undefined,
          pointHoverBorderColor: undefined
        },
        "red": {
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgb(255, 99, 132)',
          pointBackgroundColor: 'rgb(255, 99, 132)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(255, 99, 132)'
        },
        "blue": {
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          pointBackgroundColor: 'rgb(54, 162, 235)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(54, 162, 235)'
        },
        "custom": {
          backgroundColor: this.graph_colorTarget.dataset.backgroundcolor,
          borderColor: this.graph_colorTarget.dataset.bordercolor,
          pointBackgroundColor: this.graph_colorTarget.dataset.pointbackgroundcolor,
          pointBorderColor: this.graph_colorTarget.dataset.pointbordercolor,
          pointHoverBackgroundColor: this.graph_colorTarget.dataset.pointhoverbackgroundcolor,
          pointHoverBorderColor: this.graph_colorTarget.dataset.pointhoverbordercolor
        }
      }
      let color = this.graph_colorTarget.dataset.color
      this.myChart = new Chart(ctx, {
        type: 'radar',
        data: {
          labels: labels,
          datasets: [
            {
                data: data,
                label: "",
                borderDash: [],
                borderDashOffset: 0,
                borderJoinStyle: 'miter',
                borderWidth: 3,
                fill: true,
                order: 0,
                lineTension: 0,
                backgroundColor: colors[color]['backgroundColor'],
                borderColor: colors[color]['borderColor'],
                pointBackgroundColor: colors[color]['pointBackgroundColor'],
                pointBorderColor: colors[color]['pointBorderColor'],
                pointHoverBackgroundColor: colors[color]['pointHoverBackgroundColor'],
                pointHoverBorderColor: colors[color]['pointHoverBorderColor']
            }
          ]
        },
        options: {
          plugins:{
              legend:{
                display: false
              }
          },
          layout: {
            padding: {
                bottom: 0
            }
          },
          scales: {
            r: {
              min: 0,
              max: 3
            },
          },
        }, 
      });
      ctx.style.height = this.map_params.height - 100 + 'px'
    }

  }

////////////////
// Anchor
////////////////
  anchorScroll() {
    Util.consoleLog("anchorScroll")
    let url = new URL(location.href);
    let c = url.searchParams.get("anchor_id");
    let arr = ['info', 'about', 'menu', 'photos', 'itemevent', 'itemapps', 'itemtweet']
    if(arr.includes(c)) {
      Util.consoleLog(arr[arr.indexOf(c)])
      setTimeout(() => {
        Util.naviScroll('#' + arr[arr.indexOf(c)])
      }, 150);
    }
  }
////////////////
// Links
////////////////
  setRouteLinks() {
    Util.consoleLog("items setRouteLinks")
    Util.setRouteLinks(this.map_params.face, this.listTargets[0].dataset)
  }

////////////////
// location
////////////////
  locationButton(e) {
    Util.consoleLog("items locationButton")
    let toggle_el = document.getElementById('location-modal')
    toggle_el.classList.toggle('is-active')
  }

////////////////
// Photos
////////////////
  selectPhoto(e) {
    Util.consoleLog("items selectPhoto")
    Util.naviScroll('#photos')
    let id = typeof(e.target.dataset.id) == 'undefined' ? e.currentTarget.dataset.id : e.target.dataset.id
    let p = document.getElementById('main-photo')
    //let pp = document.getElementById('main-photo-link')
    let t = document.getElementById('main-photo-title')
    this.photo_selectTargets.forEach((el, i) => {
      el.classList.remove("item-photo-item--current")
      if(id == i) el.classList.add("item-photo-item--current")
      if(id == i) {
        p.src = el.dataset.src
        //pp.href = el.dataset.src
        if(t != null) t.innerHTML = el.dataset.title + " " +  el.dataset.text
      }
    })
    let pre = document.getElementById('main-photo-pre-button')
    pre.dataset.id = id == 0 ? this.photo_selectTargets.length - 1 : Number(id) - 1
    let next = document.getElementById('main-photo-next-button')
    next.dataset.id = id == this.photo_selectTargets.length - 1 ? 0 : Number(id) + 1
    let main = document.getElementById('main-photo')
    main.dataset.id = Number(id)
    
  }

////////////////
// MAP
////////////////
  setMapboxHeight() {
    Util.consoleLog("item setMapboxHeight")
    var box = document.getElementById('mapbox')
    box.style.height = this.map_params.height + 'px'
  }
  
  initMapVue() {
    Util.consoleLog("items initMapVue")
    let _app = this
    this.mapvue = new Vue({
      el: '#mapbox',
      data: {
        face: this.map_params.face,
        height: this.map_params.height,
        initialPositionToggle: true,
        isPC: this.map_params.isPC,
        locationName: Util.locationNames(this.locationsTargets)[this.map_params.locationNum],
        landmarks: Util.getDatasets(this.landmarkTargets),
        map_items: this.map_itemTargets.length > 0 ? Util.getDatasets(this.map_itemTargets) : [],
        mapboxAt: this.map_params.mapbox_at,
        mapboxPath: this.map_params.mapbox_path,
        padding: this.map_params.isPC ? [60, 80] : [100, 100],
        position: this.map_params.position,
        raillines: Util.getDatasets(this.raillineTargets),
        selectedPhotoNum: 99999,
        type: 'item',
        vItem: Util.getDatasets(this.listTargets)[0]
      },
      components: { 'lmap': Map },
      beforeCreate: function() {
        Util.consoleLog("items map beforeCreate")
      },
      created: function() {
        Util.consoleLog("items map created")
      },
      beforeDestroy: function () {
        Util.consoleLog("items map beforeDestroy")
      }
    })
    Util.consoleLog("mapvue")
    Util.consoleLog(this.mapvue)
    this.mapvue.$on('selectLocation', function () {
      _app.locationModal()
    })
    this.mapvue.$on('viewPhoto', function (id) {
      _app.photoModal(id)
    })
  }

  returnToInitialPosition() {
    Util.consoleLog("items returnToInitialPosition")
    this.mapvue.$data.initialPositionToggle = !this.mapvue.$data.initialPositionToggle
  }

  flytoSelectedPhoto(e) {
    Util.consoleLog("items flytoSelectedPhoto")
    Util.naviScroll('#mapbox')
    let id = typeof(e.target.dataset.id) == 'undefined' ? e.currentTarget.dataset.id : e.target.dataset.id
    this.mapvue.$data.selectedPhotoNum = Number(id)
  }

////////////////
// Modal
////////////////
  locationModal() {
    Util.consoleLog("items locationModal")
    let toggle_el = document.getElementById('location-modal')
    toggle_el.classList.toggle('is-active')
  }

  snsModal() {
    Util.consoleLog("items snsModal")
    let toggle_el = document.getElementById('sns-modal')
    toggle_el.classList.toggle('is-active')
  }

  maplinkModal() {
    Util.consoleLog("items maplinkModal")
    let toggle_el = document.getElementById('maplink-modal')
    toggle_el.classList.toggle('is-active')
  }

  photoModal(id = 'close') {
    Util.consoleLog("items photoModal")
    let toggle_el = document.getElementById('photo_modal')
    toggle_el.classList.toggle('is-active')
    if(id != 'close') {
      let p = document.getElementById('photo_modal_src')
      let t = document.getElementById('photo_modal_text')
      this.photo_selectTargets.forEach((el, i) => {
        Util.consoleLog("items photoModal")
        if(Number(id) == el.dataset.num) {
          p.src = el.dataset.src
          t.innerHTML = el.dataset.title + " " + el.dataset.text
        }
      })
    }
  }

////////////////
// Scroll
////////////////
  initFilter() {
    if(document.getElementById('filter') != undefined) document.getElementById('filter').style.left = (this.map_params.sizes.w - 240)/2 + 'px'
  }
  initScroll() {
    this.initFilter()
    this.scrolly = 999
    this.scrolling()
    this.function_scrolling = this.scrolling.bind(this) // 一旦変数に入れないとremoveEventListenerが効かない
    window.addEventListener("scroll", this.function_scrolling, false)
  }

  scrollTop() {
    Util.consoleLog("items scrollTop")
    Util.utiltween(document.scrollingElement.scrollTop, 0, document.documentElement, "scrollTop")
  }

  scrolling() {
    Util.consoleLog("items scrolling")
    this.scrolly = window.pageYOffset
    //if(this.scrolly < this.preScroll) {
    //  if((this.preScroll - this.scrolly) > 6) {
    //    Util.consoleLog("items down")
    //    if(!this.map_params.isPC) document.getElementById('filter').classList.add("filter_on")
    //  }
    //} else {
    //  if((this.scrolly - this.preScroll) > 6) {
    //    Util.consoleLog("items up")
    //    if(!this.map_params.isPC) document.getElementById('filter').classList.remove("filter_on")
    //  }
    //}
    this.preScroll = this.scrolly

    if(this.scrolly < Util.getIOSWindowHeight() / 2) {
      if(document.getElementById('scrollTopRightBtn') != null) {
        document.getElementById('scrollTopRightBtn').classList.add("button_none")
      }
    } else {
      Util.consoleLog("home scrolltop remove")
      if(document.getElementById('scrollTopRightBtn') != null) {
        document.getElementById('scrollTopRightBtn').classList.remove("button_none")
      }
    }

    if(this.map_params.isPC) Util.sync(this.navi_params)

    Util.consoleLog("items Height")
    if(this.map_params.isPC) this.adjustNaviTop()
  }
  
  adjustNaviTop() {
    Util.consoleLog("adjustNaviTop")
    let height = document.querySelector('#nav-box .toc').clientHeight
    Util.naviToggle(this.scrolly, height, document.querySelector('#footer').offsetTop)
  }

  naviScroll(e) {
    e.preventDefault()
    Util.naviScroll('#' + e.target.dataset.id)
  }
  
////////////////
// Search
////////////////
  searchOn() {
    this.scrollTop()
    Util.searchOn(this.search_params)
  }
  searchNext() {
    Util.searchNext(this.queryTarget.value)
  }
}
