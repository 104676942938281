<template>
  <div class="columns is-gapless">
    <div class="column">
<!--
//............................................
//........................a...................
//.mmmmmmmmmmmmmmm....aaaaaaaa..appppppppp....
//.mmmmmmmmmmmmmmmm..maaaaaaaaa.apppppppppp...
//.mmmmmmmmmmmmmmmm..maaaaaaaaa.appppppppppp..
//.mmmm..mmmmm..mmmm.maa...aaaa.apppp...pppp..
//.mmm...mmmm...mmmm...aaaaaaaa.appp....pppp..
//.mmm...mmmm...mmmm.maaaaaaaaa.appp....pppp..
//.mmm...mmmm...mmmm.maaaaaaaaa.appp....pppp..
//.mmm...mmmm...mmmm.maaa..aaaa.appp....pppp..
//.mmm...mmmm...mmmm.maa..aaaaa.appppp.ppppp..
//.mmm...mmmm...mmmm.maaaaaaaaa.apppppppppp...
//.mmm...mmmm...mmmm.maaaaaaaaa.apppppppppp...
//.mmm...mmmm...mmmm..aaaaa.aaa.appp.pppp.....
//..............................appp..........
//..............................appp..........
//..............................appp..........
//............................................
-->
      <div class="map-frame m-0 p-0 is-relative">
      
        <l-map ref="map" style="width: 100%;" :style="{height: height + 'px'}" :max-bounds="maxBounds" :zoom="12" :center="center" :options="mapOptions" @update:zoom="zoomUpdated" @moveend="handleMoveend">
          <l-tile-layer :url="url_l" :options="{ maxZoom: 20 }" :attribution="attribution"></l-tile-layer>

<!--
//.........................
//.lll.....................
//.lll.....................
//.lll.....................
//.lll.....................
//.lll..mmmmmmmmmmmmmmm....
//.lll..mmmmmmmmmmmmmmmm...
//.lll..mmmmmmmmmmmmmmmm...
//.lll..mmmm..mmmmm..mmmm..
//.lll..mmm...mmmm...mmmm..
//.lll..mmm...mmmm...mmmm..
//.lll..mmm...mmmm...mmmm..
//.lll..mmm...mmmm...mmmm..
//.lll..mmm...mmmm...mmmm..
//.lll..mmm...mmmm...mmmm..
//.lll..mmm...mmmm...mmmm..
//.lll..mmm...mmmm...mmmm..
//.........................
-->
          <template v-for="(obj, index) in landmarks">
            <template v-if="zoom >= obj.zoom && item.name != obj.name">
              <l-marker :lat-lng="obj.latlng" :icon="generateLandmarkIcons(index)" :zIndexOffset="0">
                <l-tooltip v-if="obj.icon != 'none'" :content="obj.name" />
              </l-marker>
              <template v-if="obj.icon != 'none' && obj.name.endsWith('駅')">
                <l-marker :lat-lng="obj.latlng" :icon="generateLandmarkText(index)" :zIndexOffset="0">
                </l-marker>
              </template>
            </template>
          </template>
<!--
//...............................................................................
//......................... iii..................................................
//......................... iii..ttt.............................................
//......................... iii..ttt.............................................
//...............................ttt........ee...................................
//.mmmmmmmmmmmmmmm......... iiiiitttttt..eeeeeee...emmmmmmmm.mmmmmm...sssssss....
//.mmmmmmmmmmmmmmmm........ iiiiitttttt.eeeeeeeee..emmmmmmmmmmmmmmm..mssssssss...
//.mmmmmmmmmmmmmmmm........ ii.iitttttt.eeeeeeeeee.emmmmmmmmmmmmmmmm.msssssssss..
//.mmmm..mmmmm..mmmm....... ii...ttt...teee...eeee.emmm..mmmmm..mmmm.msss..ssss..
//.mmm...mmmm...mmmm....... ii...ttt...teeeeeeeeee.emmm..mmmm...mmmm.mssssss.....
//.mmm...mmmm...mmmm....... ii...ttt...teeeeeeeeee.emm....mmm...mmmm..ssssssss...
//.mmm...mmmm...mmmm....... ii...ttt...teeeeeeeeee.emm....mmm...mmmm...ssssssss..
//.mmm...mmmm...mmmm....... ii...ttt...teee....ee..emm....mmm...mmmm.mss..sssss..
//.mmm...mmmm...mmmm....... ii...tttt.tteeee..eeee.emm....mmm...mmmm.msss..ssss..
//.mmm...mmmm...mmmm....... ii...tttttt.eeeeeeeeee.emm....mmm...mmmm.msssssssss..
//.mmm...mmmm...mmmm....... iii..tttttt..eeeeeeee..emm...mmmm...mmmm.mssssssss...
//.mmm...mmmm...mmmm....... iii...ttttt...eeeeee...emm...mmmm...mmmm...ssssss....
//...............................................................................
-->
          <template v-for="(obj, index) in map_items">
            <template v-if="zoom >= 16">
              <template v-if="obj.isInView && obj.object_type != 'enclosure' && obj.object_type != 'photo' && !obj.name.startsWith('-')">
                <l-marker :lat-lng="obj.latlng2" :icon="generateNameIcons(index)" :zIndexOffset="index+999"></l-marker>
                <l-polyline
                  :lat-lngs="[obj.latlng, obj.latlng_line_left, obj.latlng_line_right]"
                  :color="'#777'" :weight="1">
                </l-polyline>
              </template>
              <template v-if="obj.isInView && obj.object_type == 'photo'">
                <l-marker :lat-lng="obj.latlng2" :icon="generateNameIcons(index)" :zIndexOffset="index+999" @click="viewPhoto(obj.picture_num)"></l-marker>
                <l-polyline
                  :lat-lngs="[obj.latlng, obj.latlng_line_left]"
                  :color="'#777'" :weight="1">
                </l-polyline>
              </template>
              <template v-for="(obj, index) in generateMapItemPolylines()">
                <l-polyline
                  :lat-lngs="obj"
                  :color="'#FA6400'" :weight="2" :dashArray="'2, 4'">
                </l-polyline>
              </template>
              <template v-if="obj.object_type == 'arrow'">
                <l-marker :lat-lng="obj.latlng" :icon="generateMapItemArrows(index)" :zIndexOffset="0">
                  <template v-if="!obj.name.startsWith('-')">
                    <l-tooltip :content="obj.name" />
                  </template>
                </l-marker>
              </template>
              <template v-if="obj.object_type == 'point'">
                <l-marker :lat-lng="obj.latlng" :icon="dot_icon" :zIndexOffset="index+99">
                  <template v-if="!obj.name.startsWith('-')">
                    <l-tooltip :content="obj.name" />
                  </template>
                </l-marker>
              </template>
              <template v-if="obj.object_type == 'photo'">
                <l-marker :lat-lng="obj.latlng" :icon="generateMapItemPhotos(index)" :zIndexOffset="0">
                </l-marker>
              </template>
            </template>
          </template>
          <template v-for="(obj, index) in generateMapItemPolygones()">
            <l-polygon
              :lat-lngs="obj"
              :color="'#FFCC00'" :fillColor="'#FFCC00'" :fillOpacity=".2" :weight="1">
            </l-polygon>
          </template>

<!--
//................................
//.....................aiii.illl..
//.....................aiii.ill...
//.....................aiii.ill...
//..............a...........ill...
//.rrrrrrrr..aaaaaaa...aiii.ill...
//.rrrrrrrr.aaaaaaaaa..aiii.ill...
//.rrrrrrrrraaaaaaaaa..aii..ill...
//.rrrrr...raaa...aaaa.aii..ill...
//.rrrr......aaaaaaaaa.aii..ill...
//.rrr......aaaaaaaaaa.aii..ill...
//.rrr.....raaaaaaaaaa.aii..ill...
//.rrr.....raaa...aaaa.aii..ill...
//.rrr.....raaa..aaaaa.aii..ill...
//.rrr.....raaaaaaaaaa.aii..ill...
//.rrr......aaaaaaaaaa.aiii.illl..
//.rrr.......aaaaaaaaa.aiii.illl..
//................................
-->

          <template v-if="zoom <= 13">
            <template v-for="(obj, index) in rls">
              <l-polyline
                :lat-lngs="obj"
                color="#ddd" :weight="3" dashArray='8, 8' dashOffset='0'>
              </l-polyline>
            </template>
          </template>

<!--
//...................................
//.........dddd......................
//..........ddd..............tttt....
//..........ddd...............ttt....
//..........ddd......o........ttt....
//...dddddddddd...oooooooo.ottttttt..
//..ddddddddddd..ooooooooooottttttt..
//..ddddddddddd.oooooooooooottttttt..
//.ddddd..ddddd.oooo...oooo...ttt....
//.dddd....dddd.oooo....oooo..ttt....
//.dddd....dddd.ooo.....oooo..ttt....
//.dddd....dddd.ooo.....oooo..ttt....
//.dddd....dddd.oooo....oooo..ttt....
//..dddd..ddddd.ooooo..oooo...ttt....
//..ddddddddddd..oooooooooo...ttttt..
//...dddddddddd..ooooooooo....ttttt..
//....ddddd.ddd....oooooo......tttt..
//...................................
-->       
          <l-marker :lat-lng="item.latlng" :icon="active_icon"
              @click="flyto('', $event)" :zIndexOffset="999" :ref="'mk0'"></l-marker>
          
<!--
//.........................................
//...ttt...........................tttt....
//...ttt...........................tttt....
//...ttt........e..................tttt....
//.tttttttt..eeeeeee..exxxx..xxxxxttttttt..
//.tttttttt.eeeeeeeee..xxxx.xxxxxxttttttt..
//.tttttttteeeeeeeeeee.xxxxxxxxx.xttttttt..
//...ttt...eeee...eeee..xxxxxxxx...tttt....
//...ttt...eeeeeeeeeee...xxxxxx....tttt....
//...ttt...eeeeeeeeeee...xxxxx.....tttt....
//...ttt...eeeeeeeeeee...xxxxxx....tttt....
//...ttt...eeee....ee...xxxxxxx....tttt....
//...tttt.teeeee..eeee.xxxxxxxxx...tttt.t..
//...tttttt.eeeeeeeeee.xxxx.xxxxx..tttttt..
//...tttttt.eeeeeeeee.exxxx..xxxxx..ttttt..
//....ttttt...eeeeee..exxx....xxxx..ttttt..
//.........................................
-->
        <template v-if="view_label">
          <l-marker :lat-lng="item.latlng" :icon="location_label" :zIndexOffset="999"></l-marker>
        </template>
<!--
//...........................................................
//...............................tttt........................
//................................ttt........................
//.......................ee.......ttt........e...............
//.mmmmmmmmmmmmmmm.....eeeeeee.eettttttt..eeeeeee...rrrrrrr..
//.mmmmmmmmmmmmmmmm...eeeeeeee.eettttttt.eeeeeeeee..rrrrrrr..
//.mmmmmmmmmmmmmmmm..meeeeeeeeeeetttttttteeeeeeeee..rrrrrrr..
//.mmmm..mmmmm..mmmm.meee..eeee...ttt...teee...eeee.rrrrr....
//.mmm...mmmm...mmmmmmeeeeeeeeee..ttt...teeeeeeeeee.rrrr.....
//.mmm...mmmm...mmmmmmeeeeeeeeee..ttt...teeeeeeeeee.rrr......
//.mmm...mmmm...mmmmmmeeeeeeeeee..ttt...teeeeeeeeee.rrr......
//.mmm...mmmm...mmmmmmee....eee...ttt...tee....eee..rrr......
//.mmm...mmmm...mmmm.meee..eeeee..ttt..tteee..eeeee.rrr......
//.mmm...mmmm...mmmm.meeeeeeeee...ttttttteeeeeeeee..rrr......
//.mmm...mmmm...mmmm..eeeeeeeee...tttttt.eeeeeeeee..rrr......
//.mmm...mmmm...mmmm...eeeeee......ttttt...eeeee....rrr......
//...........................................................
-->
          <template v-if="meter > 50">
            <l-marker :lat-lng="meter_yx" :icon="meter_icon" :zIndexOffset="888"></l-marker>
          </template>
          <l-polyline
            :lat-lngs="[face.latlng, item.latlng]"
            :color="polyline.color" :weight="0.5" :zIndexOffset="777">
          </l-polyline>

<!--
//..............................................................................
//.lll............................................tiii..........................
//.lll.....................................tttt...tiii..........................
//.lll.....................................tttt...tiii..........................
//.lll.......o..........c...........a......tttt.............o...................
//.lll...oooooooo....ccccccc....aaaaaaaa.aatttttt.tiii...ooooooo...onnnnnnnnn...
//.lll..oooooooooo..ccccccccc..caaaaaaaaaaatttttt.tiii..ooooooooo..onnnnnnnnnn..
//.lll..oooooooooo..cccccccccc.caaaaaaaaaaatttttt.tiii.ioooooooooo.onnnnnnnnnn..
//.lll.loooo...oooooccc...cccc.caa...aaaa..tttt...tiii.iooo...oooo.onnnn..nnnn..
//.lll.looo....oooooccc....c.....aaaaaaaa..tttt...tiiiiioo....oooo.onnn...nnnn..
//.lll.looo....oooooccc........caaaaaaaaa..tttt...tiiiiioo.....ooo.onnn...nnnn..
//.lll.looo....oooooccc........caaaaaaaaa..tttt...tiiiiioo.....ooo.onnn...nnnn..
//.lll.looo....oooooccc....ccc.caaa..aaaa..tttt...tiiiiioo....oooo.onnn...nnnn..
//.lll.loooo..oooooocccc..cccc.caa..aaaaa..tttt.t.tiii.iooo..ooooo.onnn...nnnn..
//.lll..oooooooooo..cccccccccc.caaaaaaaaa..ttttttttiii.iooooooooo..onnn...nnnn..
//.lll...oooooooo....cccccccc..caaaaaaaaa...tttttttiii..ooooooooo..onnn...nnnn..
//.lll....oooooo......cccccc....aaaaa.aaa...tttttttiii...oooooo....onnn...nnnn..
//..............................................................................
-->
          <template v-if="view_label">
            <l-marker :lat-lng="location_yx" :icon="location_label_icon" :zIndexOffset="999"></l-marker>
          </template>

<!--
//.............................................
//....ffffff...................................
//...fffffff...................................
//...ffff.ff...................................
//...ffff........a..........c..........ee......
//.ffffffff..aaaaaaaa....ccccccc.....eeeeeee...
//.ffffffff.aaaaaaaaaa..ccccccccc...eeeeeeee...
//.ffffffff.aaaaaaaaaa..cccccccccc.eeeeeeeeee..
//...ffff...aaaa...aaa.cccc...cccc.eeee..eeee..
//...ffff.....aaaaaaaa.cccc....c..ceeeeeeeeee..
//...ffff....aaaaaaaaa.cccc.......ceeeeeeeeee..
//...ffff...aaaaaaaaaa.cccc.......ceeeeeeeeee..
//...ffff...aaaa..aaaa.cccc....cccceee....eee..
//...ffff...aaaa..aaaa.ccccc..cccc.eeee..eeee..
//...ffff...aaaaaaaaaa..cccccccccc.eeeeeeeeee..
//...ffff...aaaaaaaaaa...cccccccc...eeeeeeeee..
//...ffff.....aaaa.aaa....cccccc.....eeeeee....
//.............................................
-->
          <l-marker @click="selectLocation()" :lat-lng="face.latlng" :icon="face_icon" :zIndexOffset="999"></l-marker>


<!--
//.......................................................
//.............iiii.....................llll.............
//.............iiii.....................lll..............
//.............iiii.....................lll..............
//......c........................c......lll......ee......
//...ccccccc...iiii.rrrrrrrr..ccccccc...lll...eeeeeee....
//..ccccccccc..iiii.rrrrrrrr.ccccccccc..lll..eeeeeeeee...
//..cccccccccc.iii..rrrrrrrr.cccccccccc.lll..eeeeeeeeee..
//.cccc...cccc.iii..rrrrr...cccc...cccc.lll.leee...eeee..
//.cccc....c...iii..rrrr....cccc....c...lll.leeeeeeeeee..
//.cccc........iii..rrr.....cccc........lll.leeeeeeeeee..
//.cccc........iii..rrr.....cccc........lll.leeeeeeeeee..
//.cccc....ccc.iii..rrr.....cccc....ccc.lll.leee....ee...
//.ccccc..cccc.iii..rrr.....ccccc..cccc.lll.leeee..eeee..
//..cccccccccc.iii..rrr......cccccccccc.lll..eeeeeeeeee..
//...cccccccc..iiii.rrrr......cccccccc..llll..eeeeeeee...
//....cccccc...iiii.rrrr.......cccccc...llll...eeeeee....
//.......................................................

-->
            <template v-if="zoom < 15">
            <l-circle
              :lat-lng="face.latlng"
              :radius="circles_radius"
              :fillColor="circles_color3"
              :stroke="false"
              :fillOpacity="0.1"
              @click="flyto('face', $event)"
            ></l-circle>
            <l-circle
              :lat-lng="item.latlng"
              :radius="circles_radius"
              :fillColor="circles_color3"
              :stroke="false"
              :fillOpacity="0.1"
              @click="flyto('', $event)"
            ></l-circle>
            </template>


        </l-map>
<!--
//......................................................
//...................................ttt................
//...................................ttt................
//..............o....................ttt........e.......
//.rrrrrrrr..oooooooo...uuu....uuuuuutttttt..eeeeeee....
//.rrrrrrrr.oooooooooo..uuu....uuuuuutttttt.eeeeeeeee...
//.rrrrrrrrroooooooooo..uuu....uuu.uuttttttteeeeeeeeee..
//.rrrrr...rooo...oooo..uuu....uuu...ttt...teee...eeee..
//.rrrr....rooo....oooo.uuu....uuu...ttt...teeeeeeeeee..
//.rrr.....roo.....oooo.uuu....uuu...ttt...teeeeeeeeee..
//.rrr.....roo.....oooo.uuu....uuu...ttt...teeeeeeeeee..
//.rrr.....rooo....oooo.uuu...uuuu...ttt...teee....ee...
//.rrr.....roooo..oooo..uuuu.uuuuu...tttt.tteeee..eeee..
//.rrr......oooooooooo..uuuuuuuuuu...tttttt.eeeeeeeeee..
//.rrr......ooooooooo...uuuuuuuuuuu..tttttt.eeeeeeeee...
//.rrr........oooooo.....uuuuu.uuuu...ttttt...eeeeee....
//......................................................
-->
        <a v-if="maplink_on" class="is-size-7 has-text-grey-darker" v-bind:href="'http://maps.apple.com?saddr='+face.lat+','+face.lng+'&daddr='+item.lat+','+item.lng+'&center='+item.lat+','+item.lng">
          <div class="maplink-button is-flex is-align-items-center px-3 py-0 has-background-white c-height-3 c-border-radius-6 has-text-centered" :style="{top: height - 131 + 'px', left: '20px'}">
            <ion-icon name="arrow-redo-sharp" class="is-size-6 pr-1 has-text-link"></ion-icon>
            Apple マップ
          </div>
        </a>
        
        <a v-if="maplink_on" class="is-size-7 has-text-grey-darker" v-bind:href="'yjmap://route/?lat0='+face.lat+'&lon0='+face.lng+'&to='+encodeURI(item.name)+'&lat1='+item.lat+'&lon1='+item.lng+'&trans=walk'">
          <div class="maplink-button is-flex is-align-items-center px-3 py-0 has-background-white c-height-3 c-border-radius-6 has-text-centered" :style="{top: height - 91 + 'px', left: '65px'}">
            <ion-icon name="arrow-redo-sharp" class="is-size-6 pr-1 has-text-link"></ion-icon>
            Yahoo! マップ
          </div>
        </a>
        
        <a v-if="maplink_on" class="is-size-7 has-text-grey-darker" v-bind:href="'http://maps.google.com?saddr='+face.lat+','+face.lng+'&daddr='+item.lat+','+item.lng+'&center='+item.lat+','+item.lng+'&directionsmode=walking&zoom=17'">
          <div class="maplink-button is-flex is-align-items-center px-3 py-0 has-background-white c-height-3 c-border-radius-6 has-text-centered" :style="{top: height - 51 + 'px', left: '110px'}">
            <ion-icon name="arrow-redo-sharp" class="is-size-6 pr-1 has-text-link"></ion-icon>
            Google Maps
          </div>
        </a>

        <div v-if="!is_pc" @click="maplink()" class="ml-4 p-0 maplink-circle is-flex is-align-items-center is-justify-content-center is-clickable has-background-white" :style="{top: height - 65 + 'px', left: '0px'}">
          <ion-icon name="close-outline" v-if="maplink_on" class="is-size-3"></ion-icon>
          <div class="has-text-weight-semibold c-is-size-8" v-if="!maplink_on">ルート</div>
        </div>

<!--
//.............................................................................
//.bbb.........................................................................
//.bbb......................tttt....tttt.......................................
//.bbb.......................ttt.....ttt.......................................
//.bbb.......................ttt.....ttt........o..............................
//.bbbbbbbbbb..buuu....uuuuuttttttttttttttt..ooooooo...onnnnnnnnn....sssssss...
//.bbbbbbbbbbb.buuu....uuuuuttttttttttttttt.ooooooooo..onnnnnnnnnn..sssssssss..
//.bbbbbbbbbbb.buuu....uuuuuttttttttttttttttoooooooooo.onnnnnnnnnn.nsssssssss..
//.bbbb...bbbb.buuu....uuu...ttt.....ttt...tooo...oooo.onnnn..nnnn.nsss..ssss..
//.bbbb....bbb.buuu....uuu...ttt.....ttt...too.....ooo.onnn...nnnn.nsssssss....
//.bbb.....bbbbbuuu....uuu...ttt.....ttt...too.....ooo.onnn...nnnn..ssssssss...
//.bbb.....bbbbbuuu...uuuu...ttt.....ttt...too.....ooo.onnn...nnnn...ssssssss..
//.bbbb...bbbb.buuu...uuuu...ttt.....ttt...tooo...oooo.onnn...nnnn.nsss..ssss..
//.bbbbb.bbbbb.buuuuuuuuuu...ttt..t..ttt..ttoooo..oooo.onnn...nnnn.nsss..ssss..
//.bbbbbbbbbbb..uuuuuuuuuu...tttttt..tttttttoooooooooo.onnn...nnnn.nsssssssss..
//.bbbbbbbbbb...uuuuuuuuuu...tttttt..tttttt.ooooooooo..onnn...nnnn..sssssssss..
//.bbbbbbbbb.....uuuuu.uuu....ttttt...ttttt...ooooo....onnn...nnnn...ssssss....
//.............................................................................
-->
        <div v-if="!maplink_on" class="map-buttons has-text-right is-flex is-align-items-center is-justify-content-flex-end" style="margin-left: auto; height: 40px; width: 200px;" :style="{top: height - 70 + 'px'}">

          <template v-if="map_items.length > 0">
          <button @click="flyto('', $event, 19)" class="mr-2 button is-small is-rounded is-white" style="box-shadow: 0 0 6px rgba(0,0,0,0.1);">拡大</button>
          <button @click="flyto('', $event)" class="mr-2 button is-small is-rounded is-white" style="box-shadow: 0 0 6px rgba(0,0,0,0.1);"><span title="Badge top right" class="badge"></span>周辺情報</button>
          <button @click="fitBounds()" class="button is-small is-rounded is-white" style="box-shadow: 0 0 6px rgba(0,0,0,0.1);">最初の位置</button>
          </template>
          <template v-else>
          <button @click="flyto('', $event, 19)" class="mr-2 button is-small is-rounded  is-white" style="box-shadow: 0 0 6px rgba(0,0,0,0.1);">拡大</button>
          <button @click="fitBounds()" class="button is-small is-rounded is-white" style="box-shadow: 0 0 6px rgba(0,0,0,0.1);">最初の位置</button>
          </template>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
/* global L */
import Vue from 'vue/dist/vue.esm'
import {LMap, LTileLayer, LImageOverlay, LCircle, LMarker, LPolyline, LPolygon, LTooltip} from 'vue2-leaflet'

export default {
  replace: true,
  props: {
    face: Object,
    height: Number,
    initialpositiontoggle: Boolean,
    is_pc: Boolean,
    item: Object,
    landmarks: Array,
    locationname: String,
    map_items: Array,
    mapbox_at: String,
    mapbox_path: String,
    padding: Array,
    position: Object,
    raillines: Array,
    selected_photo_num: Number,
    type: String
  },
  components: {
    LMap,
    LTileLayer,
    LImageOverlay,
    LCircle,
    LMarker,
    LPolyline,
    LPolygon,
    LTooltip
  },
  data: function () {
    return {
      url_l: 'https://api.mapbox.com/styles/v1/' + this.mapbox_path + '/tiles/256/{z}/{x}/{y}@2x?access_token=' + this.mapbox_at,
      crs: L.CRS.Simple,
      maxBounds: new L.LatLngBounds([this.position['left_top_lat'], this.position['left_top_lng']], [this.position['right_bottom_lat'], this.position['right_bottom_lng']]),
      zoom: 1,
      center: L.latLng(this.face.lat, this.face.lng),
      mapOptions: {
        zoomControl: false,
        attributionControl: true
      },
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a>, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      dot_icon: L.divIcon({className: 'my_marker', html: '<span class="info_dot"></span>', iconSize: [8, 8]}),
      active_icon: this.map_items.length > 0 ? L.divIcon({className: 'my_marker selected', html: '<span class="dot"></span>', iconAnchor: [28, 28], iconSize: [56, 56]}) : L.divIcon({className: 'my_marker selected', html: '<span class="dot"></span>', iconAnchor: [28, 28], iconSize: [56, 56]}),
      face_icon: L.divIcon({className: 'face', html: '', iconSize: [38, 38]}),
      landmark_icons: [],
      polyline: {
        type: 'polyline',
        color: '#444'
      },
      maplink_on: false,
      map_items_org: [],
      circles_color: '#E02020',
      circles_color2: '#485fc7',
      circles_color3: '#555',
      view_label: true
    }
  },
  computed: {
    location_yx: function () {
      let lat = this.face.lat
      let lng = this.face.lng
      return L.latLng(lat, lng)
    },
    location_label: function () {
      let text = this.item.name
      let width = this.getWidth(text, 14, 12, 600)
      let anchor_lng = width / 2
      if(this.item.lat > this.face.lat) {
        let location_label_icon_class = 'location_label_top'
        return L.divIcon({className: location_label_icon_class, html: '<div class="location_label_text has-text-weight-semibold has-text-centered">' + text + '</div>', iconAnchor: [anchor_lng, 40], iconSize: [width, 25]})

      } else {
        let location_label_icon_class = 'location_label_bottom'
        return L.divIcon({className: location_label_icon_class, html: '<div class="location_label_text has-text-weight-semibold has-text-centered">' + text + '</div>', iconAnchor: [anchor_lng, -14], iconSize: [width, 25]})
      }
    },
    location_label_icon: function () {
      let text = "仮の現在地： " + this.locationname
      let width = this.getWidth(text, 10, 12, 600)
      let anchor_lng = width / 2
      if(this.face.lat >= this.item.lat) {
        let location_label_icon_class = 'location_label_top'
        return L.divIcon({className: location_label_icon_class, html: '<div class="location_label_text has-text-weight-semibold has-text-centered">' + text + '</div>', iconAnchor: [anchor_lng, 44], iconSize: [width, 25]})
      } else {
        let location_label_icon_class = 'location_label_bottom'
        return L.divIcon({className: location_label_icon_class, html: '<div class="location_label_text has-text-weight-semibold has-text-centered">' + text + '</div>', iconAnchor: [anchor_lng, -19], iconSize: [width, 25]})
      }
    },
    rls: function () {
      let rls = []
      let name = this.raillines[0].name
      let points = []
      for(let i = 0; i < this.raillines.length; i++) {
        if(this.raillines[i].name != name && points.length > 0) {
          rls.push(points)
          points = []
          name = this.raillines[i].name
        }
        points.push(this.raillines[i].latlng)
      }
      rls.push(points)
      return rls
    },
//...........................................................
//...............................tttt........................
//................................ttt........................
//.......................ee.......ttt........e...............
//.mmmmmmmmmmmmmmm.....eeeeeee.eettttttt..eeeeeee...rrrrrrr..
//.mmmmmmmmmmmmmmmm...eeeeeeee.eettttttt.eeeeeeeee..rrrrrrr..
//.mmmmmmmmmmmmmmmm..meeeeeeeeeeetttttttteeeeeeeee..rrrrrrr..
//.mmmm..mmmmm..mmmm.meee..eeee...ttt...teee...eeee.rrrrr....
//.mmm...mmmm...mmmmmmeeeeeeeeee..ttt...teeeeeeeeee.rrrr.....
//.mmm...mmmm...mmmmmmeeeeeeeeee..ttt...teeeeeeeeee.rrr......
//.mmm...mmmm...mmmmmmeeeeeeeeee..ttt...teeeeeeeeee.rrr......
//.mmm...mmmm...mmmmmmee....eee...ttt...tee....eee..rrr......
//.mmm...mmmm...mmmm.meee..eeeee..ttt..tteee..eeeee.rrr......
//.mmm...mmmm...mmmm.meeeeeeeee...ttttttteeeeeeeee..rrr......
//.mmm...mmmm...mmmm..eeeeeeeee...tttttt.eeeeeeeee..rrr......
//.mmm...mmmm...mmmm...eeeeee......ttttt...eeeee....rrr......
//...........................................................
    meter: function () {
      function radians(deg) {
          return deg * Math.PI / 180
      }
      let latlng = this.item
      let lat1 = this.face.lat
      let lng1 = this.face.lng
      let d2 =6378137.0 * Math.acos(Math.cos(radians(lat1))* 
        Math.cos(radians(latlng.lat))*
        Math.cos(radians(latlng.lng)-radians(lng1))+
        Math.sin(radians(lat1))*
        Math.sin(radians(latlng.lat)))
      return Math.round(d2)
    },
    meter_yx: function () {
      let yx = this.item.latlng
      let lat = this.face.latlng.lat + ((yx.lat - this.face.latlng.lat) / 2)
      let lng = this.face.latlng.lng + ((yx.lng - this.face.latlng.lng) / 2)
      return L.latLng(lat, lng)
    },
    meter_icon: function () {
      function radians(deg) {
          return deg * Math.PI / 180
      }
      let text = ""
      let d =6378137.0 * Math.acos(Math.cos(radians(this.face.lat))* 
        Math.cos(radians(this.meter_yx.lat))*
        Math.cos(radians(this.meter_yx.lng)-radians(this.face.lng))+
        Math.sin(radians(this.face.lat))*
        Math.sin(radians(this.meter_yx.lat)))
      //if(this.zoom > 13) text += Math.round(d).toLocaleString() + 'm' + " / "
      text += this.meter.toLocaleString() + 'm'
      let width = this.getWidth(text, 6, 12, 600)
      return L.divIcon({className: 'is-size-7 c-line-height-6 c-font-feature-settings-palt-1 has-background-black has-text-white has-text-weight-semibold has-text-centered c-border-radius-10', html: text, iconSize: [width, 16]})
    },
    circles_radius: function() {
      function radians(deg) {
          return deg * Math.PI / 180
      }
      let latlng = this.item
      let lat1 = this.face.lat
      let lat2 = latlng.lat
      let lng1 = this.face.lng
      let lng2 = latlng.lng
      let d =6378137.0 * Math.acos(Math.cos(radians(lat1))* 
        Math.cos(radians(lat2))*
        Math.cos(radians(lng2)-radians(lng1))+
        Math.sin(radians(lat1))*
        Math.sin(radians(lat2)))
      return Math.round(d) / 4
    },
//....................................................
//...................................llll.............
//...................................lll..............
//...................................lll..............
//.................c..........a......lll......ee......
//...sssssss....ccccccc....aaaaaaaa..lll....eeeeeee...
//..sssssssss..ccccccccc..aaaaaaaaa..lll...eeeeeeee...
//..sssssssss.cccccccccccaaaaaaaaaaa.lll..eeeeeeeeee..
//..ssss.ssss.cccc...ccccaaaa...aaaa.lll..eeee..eeee..
//..sssssss...cccc...cc.....aaaaaaaa.lll.leeeeeeeeee..
//..sssssssss.ccc.........aaaaaaaaaa.lll.leeeeeeeeee..
//...ssssssss.cccc........aaaaaaaaaa.lll.leeeeeeeeee..
//..sss..ssssscccc...ccccaaaa...aaaa.lll.leee....eee..
//.ssss...ssssccccc..ccccaaaa..aaaaa.lll..eeee..eeee..
//.ssssssssss..ccccccccccaaaaaaaaaaa.lll..eeeeeeeeee..
//..sssssssss..ccccccccc..aaaaaaaaaa.llll..eeeeeeeee..
//...ssssss......cccccc....aaaaaaaaa.llll...eeeeee....
//....................................................
    scale_face_yx: function () {
      let yx = this.item.latlng
      let lat = this.face.latlng.lat + ((yx.lat - this.face.latlng.lat) / 4)
      let lng = this.face.latlng.lng + ((yx.lng - this.face.latlng.lng) / 4)
      return L.latLng(lat, lng)
    },
    scale_marker_yx: function () {
      let yx = this.item.latlng
      let lat = this.face.latlng.lat + (((yx.lat - this.face.latlng.lat) / 4) * 3)
      let lng = this.face.latlng.lng + (((yx.lng - this.face.latlng.lng) / 4) * 3)
      return L.latLng(lat, lng)
    },
    scale_face_icon: function () {
      function radians(deg) {
          return deg * Math.PI / 180
      }
      let latlng = this.item
      let lat1 = this.face.lat
      let lat2 = this.scale_face_yx.lat
      let lng1 = this.face.lng
      let lng2 = this.scale_face_yx.lng
      let d =6378137.0 * Math.acos(Math.cos(radians(lat1))* 
        Math.cos(radians(lat2))*
        Math.cos(radians(lng2)-radians(lng1))+
        Math.sin(radians(lat1))*
        Math.sin(radians(lat2)))
      let text = Math.round(d).toLocaleString() + 'm'
      let width = this.getWidth(text, 6, 12)
      return L.divIcon({className: 'is-size-7 c-line-height-6 c-font-feature-settings-palt-1 has-background-grey-light has-text-white has-text-weight-semibold has-text-centered c-border-radius-10', html: text, iconSize: [width, 16]})
    },
    scale_marker_icon: function () {
      function radians(deg) {
          return deg * Math.PI / 180
      }
      let latlng = this.item
      let lat1 = this.face.lat
      let lat2 = this.scale_marker_yx.lat
      let lng1 = this.face.lng
      let lng2 = this.scale_marker_yx.lng
      let d =6378137.0 * Math.acos(Math.cos(radians(lat1))* 
        Math.cos(radians(lat2))*
        Math.cos(radians(lng2)-radians(lng1))+
        Math.sin(radians(lat1))*
        Math.sin(radians(lat2)))
      let text = Math.round(d).toLocaleString() + 'm'
      let width = this.getWidth(text, 6, 12)
      return L.divIcon({className: 'is-size-7 c-line-height-6 c-font-feature-settings-palt-1 has-background-grey-light has-text-white has-text-weight-semibold has-text-centered c-border-radius-10', html: text, iconSize: [width, 16]})
    }
  },
  watch: {
    initialpositiontoggle: function () {
      //console.log('watch initialpositiontoggle')
      this.fitBounds()
    },
    selected_photo_num: function (id) {
      //console.log('watch selected_photo_num')
      this.flytoPhoto(id)
    }
  },
  methods: {
    fitBounds: function () {
      //console.log('[Vue] fitBounds')
      this.view_label = true

      // 座標を取得
      let width = this.getWidth("仮の現在地： " + this.locationname, 10, 12, 600)
      let face_xy = this.getXY(this.face.lat, this.face.lng)
      //face_xy.y = face_xy.y + (this.face.lat > this.item.lat ? -10 : 10)
      //face_xy.x = face_xy.x + (width / 2) * (this.face.lng > this.item.lng ? 1 : -1)
      // 座標を取得
      let item_width = this.getWidth(this.item.name, 10, 12, 600)
      let item_xy = this.getXY(this.item.lat, this.item.lng)
      //item_xy.y = item_xy.y + (this.face.lat <= this.item.lat ? -33 : 33)
      //item_xy.x = item_xy.x + (item_width / 2) * (this.face.lng <= this.item.lng ? 1 : -1)

      this.$refs.map.mapObject.fitBounds([this.getLatLng(item_xy.x, item_xy.y), this.getLatLng(face_xy.x, face_xy.y)], {
        padding: this.padding,
        animate: true
      })
    },
    flyto: function (face = '', event, zoom = 16) {
      //console.log('flyTo')
      this.view_label = false
      if (event.originalEvent) {
        event.originalEvent.preventDefault()
      }
      if(face == 'face') {
        this.$refs.map.mapObject.flyTo(this.face.latlng, zoom, {
          animate: true,
          duration: 0.2
        })
      } else {
        this.$refs.map.mapObject.flyTo(this.item.latlng, zoom, {
          animate: true,
          duration: 0.2
        })
      }
    },
    flytoPhoto: function (id, zoom = 19) {
      //console.log('flyToPhoto')
      this.view_label = false
      for(let i = 0; i < this.map_items.length; i++) {
        if(this.map_items[i].picture_num == id) {
          this.$refs.map.mapObject.flyTo(this.map_items[i].latlng, zoom, {
            animate: true,
            duration: 0.2
          })
        }
      }
    },
    zoomUpdated: function () {
      //console.log('zoomUpdated')
      let map = this.$refs.map.mapObject
      this.zoom = map.getZoom()
      this.map_items = this.map_items_org
      this.textCollisionDetection()
    },
    handleMoveend: function () {
      //console.log('handleMoveend')
      let map = this.$refs.map.mapObject
      this.zoom = map.getZoom()
      this.map_items = this.map_items_org
      this.textCollisionDetection()

    },
//...................................................................................
//....CCCCCCCCC...............llll.llll.iii............iiii..........................
//...CCCCCCCCCCC..............lll..lll..iii............iiii..........................
//..CCCCCCCCCCCC..............lll..lll..iii............iiii..........................
//..CCCCC...CCCCC......o......lll..lll...........................o...................
//..CCCC.....CCCC...ooooooo...lll..lll..iii...sssssss..iiii...ooooooo...nnnnnnnnnn...
//..CCC......CC....ooooooooo..lll..lll..iii..sssssssss.iiii..ooooooooo..nnnnnnnnnnn..
//.CCCC...........ooooooooooo.lll..lll..iii..sssssssss.iiii.ooooooooooo.nnnnnnnnnnn..
//.CCCC...........oooo...oooo.lll..lll..iii..ssss.ssss.iiii.oooo...oooo.nnnnn..nnnn..
//.CCCC..........Cooo....oooo.lll..lll..iii..sssssss...iiii.ooo.....ooo.nnnn...nnnn..
//.CCCC..........Cooo.....ooo.lll..lll..iii..sssssssss.iiii.ooo.....ooo.nnnn...nnnn..
//..CCCC.....CCCCCooo.....ooo.lll..lll..iii...ssssssss.iiii.ooo.....ooo.nnnn...nnnn..
//..CCCC.....CCCCCooo....oooo.lll..lll..iii..sss..sssssiiii.oooo...oooo.nnnn...nnnn..
//..CCCCCC.CCCCCC.oooo..ooooo.lll..lll..iii.ssss...ssssiiii.ooooo..oooo.nnnn...nnnn..
//...CCCCCCCCCCC..oooooooooo..lll..lll..iii.ssssssssss.iiii.ooooooooooo.nnnn...nnnn..
//....CCCCCCCCC....ooooooooo..llll.llll.iii..sssssssss.iiii..ooooooooo..nnnn...nnnn..
//.....CCCCCCC......oooooo....llll.llll.iii...ssssss...iiii....ooooo....nnnn...nnnn..
//...................................................................................
    textCollisionDetection: function () {
      //console.log('textCollisionDetection')
      let _self = this
      if(typeof(this.$refs.map) == 'undefined') {
        setTimeout(_self.textCollisionDetection, 2000)
        return false
      } else {
        let jj = 0
        let countIsInView = 0
        for(let elem of this.map_items) {
          this.map_items[jj].isInView = this.isMarkerOutOfView(elem)
          this.map_items[jj].isInView = true
          //console.log(elem.name + " isInView:" + this.map_items[jj].isInView)
          if(this.map_items[jj].isInView) countIsInView++
          jj++
        }
        if(countIsInView ==0) return false;
        //console.log(this.map_items)
        this.collisionAreas = this.getCollisionAreas()
        let j = 0
        let elemArea = {}
        for(let elem of this.map_items) {
          let XY = this.getXY(elem.lat, elem.lng)
          if(elem.object_type != "photo") {
            let class_name = "dot_name c-py-00625 c-pl-025 has-text-weight-normal c-height-6 c-line-height-6 c-is-size-7h has-text-weight-semibold c-font-feature-settings-palt-1 letter-spacing-0 has-text-grey-dark"
            elemArea = {
              x1: XY.x - 10,
              y1: XY.y - 16,
              x2: XY.x + this.getTextWidth(elem.name, 8, 0.625, 600, 'rem', class_name) + 10,
              y2: XY.y + 10
            }
          } else {
            elemArea = {
              x1: XY.x,
              y1: XY.y - 42,
              x2: XY.x + 62,
              y2: XY.y
            }
          }
          //
          let i = 0
          let myNum = 0
          // idがマッチし、typeがtextのときの順位を取得する
          for(let area of this.collisionAreas) {
            if(area.id == elem.id && area.type == 'text') {
              myNum = i
            }
            i++
          }
          // idがマッチしたものの座標をアップデートする
          let position_yx = {}
          if(this.collisionAreas[myNum].name == 'FACE' || this.collisionAreas[myNum].name == 'FLAG' || this.collisionAreas[myNum].name == 'location') {
          } else {
            position_yx = this.recursiveCollisionDetection(elemArea, myNum)
            this.collisionAreas[myNum].y1 += position_yx.y
            this.collisionAreas[myNum].y2 += position_yx.y
            this.collisionAreas[myNum].x1 += position_yx.x
            this.collisionAreas[myNum].x2 += position_yx.x
          }
          this.map_items[j].latlng2 = this.getLatLng(
            this.collisionAreas[myNum].x1, 
            this.collisionAreas[myNum].y1 + ((this.collisionAreas[myNum].y2 - this.collisionAreas[myNum].y1) / 2)
          )
          if(this.collisionAreas[myNum].name != 'photo') {
            this.map_items[j].latlng_line_left = this.getLatLng( 
              this.collisionAreas[myNum].x1, 
              this.collisionAreas[myNum].y1 + ((this.collisionAreas[myNum].y2 - this.collisionAreas[myNum].y1) / 2) + 7
            )
          } else {
            this.map_items[j].latlng_line_left = this.getLatLng( 
              this.collisionAreas[myNum].x1 + 4, 
              this.collisionAreas[myNum].y1 + ((this.collisionAreas[myNum].y2 - this.collisionAreas[myNum].y1) / 2) + 6
            )
          }
          this.map_items[j].latlng_line_right = this.getLatLng(
            //this.collisionAreas[myNum].x2 + 2, 
            this.collisionAreas[myNum].x1 + elemArea.x2 - elemArea.x1 - 20,
            this.collisionAreas[myNum].y1 + ((this.collisionAreas[myNum].y2 - this.collisionAreas[myNum].y1) / 2) + 7
          )
          j++
        }
      }
      //console.log(this.map_items)
    },
    getXY: function (lat, lng) {
      //console.log('getXY')
      let map = this.$refs.map.mapObject
      let zoom = map.getZoom()
      let item = map.latLngToContainerPoint(L.latLng(lat, lng), zoom)
      return item
    },
    getLatLng: function (x, y) {
      //console.log('getLatLng')
      let map = this.$refs.map.mapObject
      let item = map.containerPointToLatLng(L.point(x, y))
      return item
    },
    isMarkerOutOfView: function (elem) {
      return this.$refs.map.mapObject.getBounds().contains(L.latLng(elem.lat, elem.lng))
    },
    getCollisionAreas: function () {
      //console.log('座標をpxへ変換し、判定する範囲を四角で追加していく')
      //console.log('getCollisionAreas')
      let map = this.$refs.map.mapObject
      let zoom = map.getZoom()
      let data = []
      // face
      if(this.isMarkerOutOfView(this.face)) {
        let faceXY = this.getXY(this.face.lat, this.face.lng)
        data.push({
          x1: faceXY.x - (38 / 2),
          y1: faceXY.y - (38 / 2),
          x2: faceXY.x + (38 / 2),
          y2: faceXY.y + (38 / 2),
          name: 'FACE',
          type: 'face'
        })

        let location_width = this.getTextWidth("仮の現在地： " + this.locationname + " ", 10, 12, 600)

        let text = "仮の現在地： " + this.locationname
        let face_width = this.getWidth(text, 10, 12, 600)
        if(this.face.lat >= this.item.lat) {
          data.push({
            x1: faceXY.x - (face_width / 2),
            y1: faceXY.y - (25 / 2) - (38 / 2),
            x2: faceXY.x + (face_width / 2),
            y2: faceXY.y - (38 / 2),
            name: "仮の現在地",
            type: 'location'
          })
        } else {
          data.push({
            x1: faceXY.x - (face_width / 2),
            y1: faceXY.y + (38 / 2),
            x2: faceXY.x + (face_width / 2),
            y2: faceXY.y + (25 / 2) + (38 / 2),
            name: "仮の現在地",
            type: 'location'
          })
        }
      }

      // location label
      //console.log('location label')
      let ll_text = this.item.name
      let ll_width = this.getWidth(ll_text, 10, 12, 600)
      let ll_XY = this.getXY(this.item.lat, this.item.lng)
      if(this.item.lat > this.face.lat) {
        data.push({
          x1: ll_XY.x - (ll_width / 2),
          y1: ll_XY.y - (25 / 2) - 8,
          x2: ll_XY.x + (ll_width / 2),
          y2: ll_XY.y - 8,
          name: "ll",
          type: 'll'
        })
      } else {
        data.push({
          x1: ll_XY.x - (ll_width / 2),
          y1: ll_XY.y + 8,
          x2: ll_XY.x + (ll_width / 2),
          y2: ll_XY.y + (25 / 2) + 8,
          name: "ll",
          type: 'll'
        })
      }
      
      // flag
      //console.log('flag')
      let flag_width = 56
      let flagXY = this.getXY(this.item.lat, this.item.lng)
      data.push({
        x1: flagXY.x - (flag_width / 2),
        y1: flagXY.y - (flag_width / 2),
        x2: flagXY.x + (flag_width / 2),
        y2: flagXY.y + (flag_width / 2),
        name: "FLAG",
        type: 'flag'
      })
      
      // meter
      //console.log('meter')
      let meter_width = 50
      let meterXY = this.getXY(this.meter_yx.lat, this.meter_yx.lng)
      data.push({
        x1: meterXY.x - (meter_width / 2),
        y1: meterXY.y - (16 / 2),
        x2: meterXY.x + (meter_width / 2),
        y2: meterXY.y + (16 / 2),
        name: "METER",
        type: 'meter'
      })

      //
      //console.log('text')
      //console.log(this.map_items)
      for (let elem of this.map_items) {
        if(elem.object_type != 'enclosure' && elem.object_type != 'photo' && !elem.name.startsWith('-') && elem.isInView) {
          let XY = this.getXY(elem.lat, elem.lng)
          let class_name = "dot_name c-py-00625 c-pl-025 has-text-weight-normal c-height-6 c-line-height-6 c-is-size-7h has-text-weight-semibold c-font-feature-settings-palt-1 letter-spacing-0 has-text-grey-dark"
          data.push({
            x1: XY.x,
            y1: XY.y - 12,
            x2: XY.x + this.getTextWidth(elem.name, 8, 0.625, 600, 'rem', class_name),
            y2: XY.y + 12,
            name: elem.name,
            id: elem.id,
            type: 'text'
          })
        }
        if(elem.object_type == 'photo' && elem.isInView) {
          let XY = this.getXY(elem.lat, elem.lng)
          data.push({
            x1: XY.x,
            y1: XY.y - 42,
            x2: XY.x + 42,
            y2: XY.y,
            name: 'photo',
            id: elem.id,
            type: 'text'
          })
        }
      }
      
      // arrow
      //console.log('arrow')
      for (let elem of this.map_items) {
        if(elem.object_type == 'arrow' && elem.isInView) {
          let XY = this.getXY(elem.lat, elem.lng)
          data.push({
            x1: XY.x - 56,
            y1: XY.y - 56,
            x2: XY.x + 56,
            y2: XY.y + 56,
            name: elem.name,
            id: elem.id,
            type: 'arrow'
          })
        }
      }
      
      // dot
      for (let elem of this.map_items) {
        if(elem.isInView) {
          let XY = this.getXY(elem.lat, elem.lng)
          data.push({
            x1: XY.x - 5,
            y1: XY.y - 5,
            x2: XY.x + 5,
            y2: XY.y + 5,
            name: elem.name,
            id: elem.id,
            type: 'dot'
          })
        }
      }
      //
      //console.log('getCollisionAreas')
      //console.log(data)
      return data
    },
    getCircleX(radians, radius) {
      return Math.cos(radians) * radius;
    },
    getCircleY(radians, radius) {
      return Math.sin(radians) * radius;
    },
    recursiveCollisionDetection(myArea, myNum, radians = 0, radius = 0, count = 0, position_yx = {
        y: 0,
        x: 0
      }) {
      let num = 15
      let collision = false
      let i = 0
      for(let area of this.collisionAreas) {
        if(myNum != i) {
          let y = Math.round(this.getCircleY(radians, radius))
          let x = Math.round(this.getCircleX(radians, radius))
          collision = this.detectAreaCollision({
            y1: myArea.y1 - y,
            y2: myArea.y2 - y,
            x1: myArea.x1 + x,
            x2: myArea.x2 + x
          }, area)
          if(collision) break
        }
        i++
      }
      if(collision) {
        if(radius == 0 || radians / Math.PI >= 2) {
          radius += num
          radians = 0
        } else {
          radians += (Math.PI / 2) / 9
        }
        if(count < 1000) {
          position_yx = this.recursiveCollisionDetection(myArea, myNum, radians, radius, count, position_yx = {
            y: -1 * Math.round(this.getCircleY(radians, radius)),
            x: Math.round(this.getCircleX(radians, radius))
          })
        } else {
          // 断念
          //console.log('探索断念')
          position_yx = {
            y: 0,
            x: 0
          }
        }
        count ++
      }
      return position_yx
    },
    detectAreaCollision(myArea, area) {
      let detection = false
      // 面で判定
      for (let y = myArea.y1; y <= myArea.y2; y+=10) {
        for (let x = myArea.x1; x <= myArea.x2; x+=10) {
          if(y >= area.y1 && y <= area.y2 && x >= area.x1 && x <= area.x2) {
            detection = true
          }
        }
      }
      return detection
    },
//....................................................................................................................................
//................................tTTTTTTTTTTTTT............................ttWW....WWWW...WWWWWWiii.........ddd.........tthh.........
//...........................ttt..tTTTTTTTTTTTTT......................xttt..ttWW....WWWW...WWWW.Wiii.........ddd...ttt...tthh.........
//...........................ttt..tTTTTTTTTTTTTT......................xttt..ttWW...WWWWW...WWWW.Wiii.........ddd...ttt...tthh.........
//...................e.......ttt.......TTTT........ee.................xttt...tWWW..WWWWW...WWWW..............ddd...ttt...tthh.........
//...gggggggggg...eeeeeee..tttttttt....TTTT.....eeeeeee..eexxx..xxxxxxxttttt.tWWW..WWWWWW..WWWW.Wiii...ddddddddd.ddtttttttthhhhhhhh...
//..ggggggggggg..eeeeeeeee.tttttttt....TTTT....Teeeeeeee..exxx.xxxxxxxxttttt.tWWW..WWWWWW.WWWW..Wiii.idddddddddd.ddtttttttthhhhhhhhh..
//..ggggggggggg.eeeeeeeeee.tttttttt....TTTT....Teeeeeeeee.exxxxxxxx.xxxttttt..WWW.WWWWWWW.WWWW..Wii..idddddddddd.ddtttttttthhhhhhhhh..
//.gggg...ggggg.eeee...eeee..ttt.......TTTT...TTee...eeee..xxxxxxxx...xttt....WWWWWWWWWWWWWWWW..Wii..iddd...dddd...ttt...tthhh..hhhh..
//.gggg....gggg.eeeeeeeeeee..ttt.......TTTT...TTeeeeeeeee...xxxxxx....xttt....WWWWWWW.WWWWWWW...Wii.iidd....dddd...ttt...tthhh...hhh..
//.gggg....gggg.eeeeeeeeeee..ttt.......TTTT...TTeeeeeeeee...xxxxx.....xttt....WWWWWWW.WWWWWWW...Wii.iidd.....ddd...ttt...tthh....hhh..
//.gggg....gggg.eeeeeeeeeee..ttt.......TTTT...TTeeeeeeeee...xxxxxx....xttt.....WWWWWW..WWWWWW...Wii.iidd.....ddd...ttt...tthh....hhh..
//.gggg...ggggg.eee....eee...ttt.......TTTT...TTee....ee...xxxxxxx....xttt.....WWWWWW..WWWWWW...Wii..idd....dddd...ttt...tthh....hhh..
//..ggggggggggg.eeee..eeeee..tttt.t....TTTT...TTeee..eeee.exxxxxxxx...xttt.t...WWWWW...WWWWW....Wii..iddd..ddddd...tttt.ttthh....hhh..
//..ggggggggggg.eeeeeeeeee...tttttt....TTTT....Teeeeeeeee.exxx.xxxxx..xtttttt..WWWWW....WWWW....Wii..idddddddddd...tttttttthh....hhh..
//...gggggggggg..eeeeeeeee...tttttt....TTTT.....eeeeeeee.eexxx..xxxxx..tttttt...WWWW....WWWW....Wiii..dddddddddd...tttttttthh....hhh..
//..ggg...ggggg....eeeee......ttttt....TTTT......eeeeee..eexx....xxxx..tttttt...WWWW....WWWW....Wiii...ddddddddd....ttttttthh....hhh..
//.ggggggggggg........................................................................................................................
//..gggggggggg........................................................................................................................
//...ggggggg..........................................................................................................................
//....................................................................................................................................
    getTextWidth: function (text, margin, fontSize = 12, fontWeight = '400', unit = 'px') {
      const div = document.createElement('div')
      div.innerText = text
      //div.style.letterSpacing = '0'
      div.style.fontFamily = 'Helvetica Neue, Arial, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif'
      div.style.fontWeight = fontWeight
      div.style.fontSize = fontSize + unit
      div.style.display = 'inline-block'
      document.body.appendChild(div)
      let width = div.getBoundingClientRect().width + margin || (text.length * fontSize + margin)
      // offsetWidthができたらすぐに消す  
      setTimeout(() => {
        document.body.removeChild(div)
      }, 0)
      //console.log(text + ":" + width)
      return width
    },
    // 
    // 
    // 
    maplink: function () {
      //console.log('maplink')
      this.maplink_on = !this.maplink_on
    },
    selectLocation: function () {
      this.$parent.$emit('selectLocation')
    },
    viewPhoto: function (id) {
      this.$parent.$emit('viewPhoto', id)
    },
    generateLandmarkIcons: function (num) {
      if(this.landmarks[num].icon === 'none') {
        return L.divIcon({className: 'has-text-grey-light has-text-weight-semibold c-text-shadow-03 c-line-height-7 is-size-7 c-font-feature-settings-palt-1 letter-spacing-0', html: '<div style="font-size: ' + 9 + 'px; line-height: ' + 9 + 'px">' + this.landmarks[num].name + '</div>', iconSize: [(this.landmarks[num].name.length * 9), 9]})
      } else {
        return L.divIcon({className: '', html: '<div><img src="/img/landmarks/' + this.landmarks[num].icon + '.svg?y20220212a"></div>', iconSize: [24, 24]})
      }
    },
    generateLandmarkText: function (num) {
      let name = this.landmarks[num].name
      let width = this.getTextWidth(name, 4, 12, 400, 'rem')
      return L.divIcon({className: 'fuchi_white has-text-grey-darker c-text-shadow-03 c-line-height-7 is-size-7 c-font-feature-settings-palt-1 letter-spacing-0 has-text-centered', html: '<div>' + name + '</div>', iconAnchor: [width/2, -10], iconSize: [width, 10]})
    },
    generateNameIcons: function (num) {
      //console.log('generateNameIcons')
      if(this.map_items[num].object_type != 'photo') {
        let name = ""
        let class_name = "dot_name c-py-00625 c-pl-025 has-text-weight-normal c-height-6 c-line-height-6 c-is-size-7h has-text-weight-semibold c-font-feature-settings-palt-1 letter-spacing-0 has-text-grey"
        name = this.map_items[num].name
        let width = this.getTextWidth(name, 8, 0.625, 600, 'rem', class_name)
        return L.divIcon({className: class_name, html: name, iconAnchor: [0, 10], iconSize: [width, (16)]})
      } else {
        let name = ""
        let class_name = "photo_background c-p-05"
        name = '<img src="' + this.map_items[num].picture_url + '" class="photo_radius" style="width: 36px; height: 36px;">'
        return L.divIcon({className: class_name, html: name, iconAnchor: [0, 36], iconSize: [42, 42]})
      }
    },
    generateMapItemIcons: function (num) {
      return L.divIcon({className: 'has-text-grey-light has-text-weight-semibold c-text-shadow-03 landmarks_text c-line-height-7 is-size-7 c-font-feature-settings-palt-1 letter-spacing-0', html: '<div style="font-size: ' + 9 + 'px; line-height: ' + 9 + 'px">' + this.map_items[num].name + '</div>', iconSize: [(this.map_items[num].name.length * 9), 9]})
    },
    generateMapItemArrows: function (num) {
      return L.divIcon({className: 'my_marker', html: '<div class="arrow_marker" style="transform: rotate(' + this.map_items[num].angle + 'deg);"></div>', iconSize: [56, 56]})
    },
    generateMapItemPhotos: function (num) {
      return L.divIcon({className: 'my_marker', html: '<div class="photo_marker" style="transform: rotate(' + this.map_items[num].angle + 'deg);"></div>', iconSize: [56, 56]})
    },
    generateMapItemPolygones: function () {
      //console.log('generateMapItemPolygones')
      const polygones = []
      if(this.map_items.length > 0) {
        
        const groupBy = (items, key) => items.reduce(
          (result, item) => ({
            ...result,
            [item[key]]: [
              ...(result[item[key]] || []),
              item,
            ],
          }), 
          {},
        )
        const groups = groupBy(this.map_items, 'object_type')
        if(groups['enclosure'] != undefined) {
          const enclosure_items = groupBy(groups['enclosure'], 'group_num')
          for(const key in enclosure_items) {
            let polygone = []
            for(let i = 0; i < enclosure_items[key].length; i++) {
              polygone.push(enclosure_items[key][i].latlng)
            }
            polygone.push(enclosure_items[key][0].latlng)
            polygones.push(polygone)
          }
        }
      }
      return polygones
    },
    generateMapItemPolylines: function () {
      //console.log('generateMapItemPolylines')
      const polylines = []
      if(this.map_items.length > 0) {
        
        const groupBy = (items, key) => items.reduce(
          (result, item) => ({
            ...result,
            [item[key]]: [
              ...(result[item[key]] || []),
              item,
            ],
          }), 
          {},
        )
        const groups = groupBy(this.map_items, 'object_type')
        if(groups['line'] != undefined) {
          const line_items = groupBy(groups['line'], 'group_num')
          for(const key in line_items) {
            let polyline = []
            for(let i = 0; i < line_items[key].length; i++) {
              polyline.push(line_items[key][i].latlng)
            }
            polylines.push(polyline)
          }
        }
      }
      return polylines
    },
    getWidth: function (text, margin, fontSize = 12, fontWeight = '400', unit = 'px', classList = '') {
      const div = document.createElement('div')
      div.innerText = text
      //div.style.letterSpacing = '0'
      div.style.fontFamily = 'Helvetica Neue, Arial, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif'
      div.style.fontWeight = fontWeight
      div.style.fontSize = fontSize + unit
      div.style.display = 'inline-block'
      if(classList != "") div.classList.add(classList)
      document.body.appendChild(div)
      let width = div.getBoundingClientRect().width + margin || (text.length * fontSize + margin)
      setTimeout(() => {
        document.body.removeChild(div)
      }, 0)
      return width
    }
  },
  mounted () {
    //console.log('[Vue] map-item.vue mounted')
    let _self = this
    this.map_items_org = this.map_items
    this.fitBounds()
  }
}
</script>
<style scoped>
.map-frame {
  z-index: 99999;
  overflow: hidden;
  width: 100%;
}
.maplink-button {
  position: absolute;
  z-index: 9999999;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
}
.maplink-circle {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  position: absolute;
  z-index: 9999999;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
}
.map-buttons {
  position: absolute;
  right: 15px;
  z-index: 9999999;
}
.map-button-shadow {
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
}

</style>
