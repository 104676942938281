<template>
  <div style="height: 150px; overflow-yscroll;">
    <!-- for home -->
    <template v-if="listType === 'home'">
    <div class="columns">
      <div class="column is-narrow has-text-left">
        <div id="list-head-box" data-action="click->home#filterButton">
          <div v-if="filter.motive == ''" class="py-0 pr-4"><button class="button is-rounded is-link is-italic is-clickable is-large is-fullwidth"><ion-icon name="arrow-forward-outline"></ion-icon>&nbsp;更新＆新着&nbsp;</button></div>
          <div v-if="filter.motive != ''" class="mb-1 py-0 pr-4"><button class="button is-rounded is-link has-text-weight-semibold is-clickable is-fullwidth">{{ filter.motive }}</button></div>
          <div v-if="filter.category != ''" class="mb-1 py-0 pr-4"><button class="button is-rounded is-link has-text-weight-semibold is-clickable is-fullwidth">{{ filter.category }}</button></div>
          <div v-if="filter.tag != ''" class="py-0 pr-4"><button class="button is-rounded is-link has-text-weight-semibold is-clickable is-fullwidth">{{ filter.tag }}</button></div>
          
          <!--<div class="mt-2 mb-1 has-text-grey-dark is-size-2 c-line-height-2 letter-spacing-0">{{ i_t_list.length }}<span class="pl-1 is-size-6 ">件</span></div>-->
        </div>
      </div>
      <div class="column has-text-left">
        <div class="mt-0 is-relative" :style="{height: listBoxHeight + 'px', 'overflow-y': (listBoxHeight < listBodyHeight ? 'scroll' : 'auto')}">
          <div id="list-body-box" class="pb-6 is-flex is-flex-wrap-wrap">
            <div v-for="(i_t, index) in i_t_list" :key="index">

              <template v-if="i_t[0] === 'item' && index !== selecteditem">
              <div @click="$parent.selectMarker(index)" class="mb-2 is-flex is-align-items-center is-clickable">
                <ion-icon name="ellipse-sharp" class="pr-1 has-text-warning is-size-5"></ion-icon>
                <div class="is-size-7 mr-2 c-white-space-nowrap">{{ items[i_t[1]].name }}</div>
              </div>
              </template>
              <template v-if="i_t[0] === 'item' && index === selecteditem">
              <div class="mb-2 is-flex is-align-items-center">
                <ion-icon name="ellipse-sharp" class="pr-1 has-text-warning is-size-5"></ion-icon>
                <div class="is-size-7 c-white-space-nowrap">{{ items[i_t[1]].name }}</div>
                <a class="px-2 py-0 ml-3 mr-4 has-text-centered is-flex is-align-items-center is-justify-content-center has-background-danger-light has-text-danger c-height-6 c-border-radius-8" v-bind:href="filter_path(items[i_t[1]].id)"><div class="c-height-8 c-line-height-8 c-is-size-8 c-white-space-nowrap">もっとみる</div></a>
              </div>
              <!--<div class="mr-1 mb-4 py-2 px-4 is-size-7 has-background-warning-light has-text-grey-darker c-pre-wrap" :style="{width: listBodyWidth + 'px'}" v-html="items[i_t[1]].open"></div>-->
              </template>

              <template v-if="i_t[0] === 'tweet' && index !== selecteditem">
              <div @click="$parent.selectMarker(index)" class="mb-2 is-flex is-align-items-center is-clickable">
                <ion-icon name="ellipse-sharp" class="pr-1 has-text-info is-size-5"></ion-icon>
                <div class="is-size-7 mr-2 c-white-space-nowrap">{{ tweets[i_t[1]].place_name }}</div>
              </div>
              </template>
              <template v-if="i_t[0] === 'tweet' && index === selecteditem">
              <div class="mb-2 is-flex is-align-items-center">
                <ion-icon name="ellipse-sharp" class="pr-1 has-text-info is-size-5"></ion-icon>
                <div class="is-size-7 c-white-space-nowrap">{{ tweets[i_t[1]].name }}</div>
                <a class="px-2 py-0 ml-3 mr-4 has-text-centered is-flex is-align-items-center is-justify-content-center has-background-link-light has-text-link c-height-6 c-border-radius-8" v-bind:href="tweets[i_t[1]].url" target="_blank" rel="noopener"><ion-icon name="arrow-redo" class="mr-1 is-size-7"></ion-icon><div class="c-height-8 c-line-height-8 c-is-size-8 c-white-space-nowrap">ツイートを見る</div></a>
              </div>
              </template>

            </div>
          </div>
        </div>
        <div class="scroll-bottom-white"></div>
      </div>
    </div>
    </template>
    <!-- /for home  -->

    <!-- for collection -->
    <template v-if="listType === 'collection'">
    </template>
    <!-- /for collection  -->
  </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm'
export default {
  props: ['filter', 'items', 'i_t_list', 'height', 'listType', 'selecteditem', 'tweets'],
  data: function() {
    return {
      listBoxHeight: 0,
      listBodyHeight: 0,
      listBodyWidth: 0
    }
  },
  watch: {
    selectedCard: function() {
      
    }
  },
  mounted: function() {
    //console.log("card/mounted")
    this.init()
  },
  methods: {
    filter_path: function (id) {
      let path = Routes.item_path(id)
      let f = this.filter
      if(f.motive   != '') path = Routes.item_motive_path(id, f.motive)
      if(f.category != '') path = Routes.item_category_path(id, f.motive, f.category)
      if(f.tag      != '') path = Routes.item_tag_path(id, f.motive, f.category, f.tag)
      return path
    },
    init: function() {
      //console.log("list/init")
      let _self = this
      if(this.listType === 'home') {
        setTimeout(() => {
          _self.listBoxHeight = 450 - document.getElementById('list-head-box').getBoundingClientRect().height -15
          _self.listBodyHeight = document.getElementById('list-body-box').getBoundingClientRect().height
          _self.listBodyWidth = document.getElementById('list-body-box').getBoundingClientRect().width
        }, 300)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.scroll-bottom-white {
  margin-top: -2rem;
  position: relative;
  width: 100%;
  height: 2rem;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}
</style>
