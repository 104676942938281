import { Controller } from "stimulus"
import Vue from 'vue/dist/vue.esm'
import Map from '../vue/map-collection.vue'
import * as Util from "./util.js"
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import relativeTime from 'dayjs/plugin/relativeTime';

export default class extends Controller {
  static targets = ["list", "landmark", "railline", 'locations', "map", 'query', 'search_list', 'search_results', "datetime"]

  initialize() {
    Util.consoleLog("collections initialize")
    let _app = this
    this.originalListEl = document.querySelector('#sorted-list').innerHTML
    this.originalMapEl = document.querySelector('#mapbox').innerHTML
    document.addEventListener('turbolinks:visit', function teardown() {
      document.querySelector('#sorted-list').innerHTML = _app.originalListEl
      document.querySelector('#mapbox').innerHTML = _app.originalMapEl
      document.removeEventListener('turbolinks:visit', teardown)
    })
  }

  connect() {
    Util.consoleLog("collections connect!")
    this.init()
  }

  disconnect() {
    Util.consoleLog("collections disconnect!")
    this.mapvue.$destroy()
    window.removeEventListener("scroll", this.function_scrolling)
    window.removeEventListener("resize", this.adjustNaviTop)
  }

  async init() {
    Util.consoleLog('init')
    this.map_params = Util.map_params(this.mapTarget.dataset, this.data)
    let myPosition = await Util.getMyPosition(this.map_params.locationNum)
    this.map_params.face = Util.face(this.locationsTargets, this.map_params.locationNum, this.mapTarget.dataset, myPosition)
    this.navi_params = Util.naviParams()
    this.search_params = { searchBox: false }
    this.setMapboxHeight()
    this.initScroll()
    this.initMapVue()
    this.listSelected(0)
    if(this.map_params.isPC) Util.drawPath(this.navi_params)
    document.querySelector('#selected_item').innerHTML = this.map_params.items[this.map_params.selectedItem].name
    Util.consoleLog('init end')
    window.addEventListener("resize", this.adjustNaviTop, false)
    Util.sendPageview()// analytics
    this.changeFormatDatetime()
  }

////////////////
// Datetime
////////////////
  changeFormatDatetime() {
    dayjs.locale('ja');
    dayjs.extend(relativeTime);
    this.datetimeTargets.forEach((el, i) => {
      el.innerHTML = dayjs(new Date(el.dataset.datetime)).fromNow()
    })
  }

////////////////
// modal
////////////////
  locationButton(e) {
    Util.consoleLog('locationButton')
    let toggle_el = document.getElementById('location-modal')
    toggle_el.classList.toggle('is-active')
  }

  locationModal(e) {
    Util.consoleLog('locationModal')
    let toggle_el = document.getElementById('location-modal')
    toggle_el.classList.toggle('is-active')
  }

  snsModal(e) {
    Util.consoleLog('snsModal')
    let toggle_el = document.getElementById('sns-modal')
    toggle_el.classList.toggle('is-active')
  }

////////////////
// MAP
////////////////
  prev() {
    let num = this.map_params.selectedItem - 1
    if(this.map_params.selectedItem == 0) num = this.map_params.items.length - 1
    this.selectMarker(num)
  }
  next() {
    let num = this.map_params.selectedItem + 1
    if(this.map_params.selectedItem == this.map_params.items.length - 1) num = 0
    this.selectMarker(num)
  }
  setMapboxHeight() {
    Util.consoleLog("item setMapboxHeight")
    var box = document.getElementById('mapbox')
    box.style.height = this.map_params.height + 'px'
  }

  initMapVue() {
    Util.consoleLog('initMapVue')
    let _app = this
    _app.map_params.items = Util.getCloserOrder(Util.getDatasets(this.listTargets), this.map_params.face)

    this.mapvue = new Vue({
      el: '#mapbox',
      data: {
        face: this.map_params.face,
        height: this.map_params.height,
        isPC: this.map_params.isPC,
        initialPositionToggle: true,
        landmarks: Util.getDatasets(this.landmarkTargets),
        locationName: Util.locationNames(this.locationsTargets)[this.map_params.locationNum],
        mapboxAt: this.map_params.mapbox_at,
        mapboxPath: this.map_params.mapbox_path,
        padding: this.map_params.isPC ? [80, 80] : [100, 100],
        position: this.map_params.position,
        raillines: Util.getDatasets(this.raillineTargets),
        selected: this.map_params.selectedItem,
        sp: this.data.get("sp") !== "false" ? true: false,
        type: 'collection',
        vItems: _app.map_params.items
      },
      components: { 'lmap': Map },
      beforeDestroy: function () {
        Util.consoleLog('map destroy')
      }
    })
    Util.consoleLog('after: new Vue')
    this.mapvue.$on('setSelectedNum', function (i) {
      Util.consoleLog('setSelectedNum')
      Util.consoleLog(i)
      _app.selectMarker(i)
      Util.setRouteLinks(_app.map_params.face, _app.map_params.items[i])
    })
    this.mapvue.$on('selectLocation', function () {
      _app.locationModal()
    })
    this.setInnerHtml(this.map_params.selectedItem)
  }

  selectMarker(num) {
    Util.consoleLog(num)
    this.map_params.selectedItem = num
    this.mapvue.$data.selected = this.map_params.selectedItem
    this.data.set("selectedIndex", num)
    this.setInnerHtml(num)
    this.listSelected(num)
  }

  setInnerHtml(num) {
    document.querySelector('#selected_item').innerHTML = this.map_params.items[num].name
    if(this.map_params.items[num].description != "") {
      document.querySelector('#selected_item_description').innerHTML = this.map_params.items[num].description
    }
  }

  listSelected(num) {
    Util.consoleLog(num)
    let id = this.map_params.items[num].id
    this.listTargets.forEach((el, i) => {
      el.classList.remove('is-active')
      if(el.dataset.id == id) {
        el.classList.add('is-active')
      }
    })
  }

  listMapButton(e) {
    Util.consoleLog('listMapButton')
    let num = 0
    let id = Number(e.currentTarget.dataset.id)
    Util.consoleLog(this.map_params)
    this.map_params.items.forEach(function(item, index){ 
      Util.consoleLog(item.id)
      Util.consoleLog(item.id == id)
      if(item.id == id) num = index
    })
    this.selectMarker(num)
    Util.naviScroll('#mapbox')
  }

  returnToInitialPosition() {
    Util.consoleLog('returnToInitialPosition')
    this.mapvue.$data.initialPositionToggle = !this.mapvue.$data.initialPositionToggle
  }

////////////////
// Scroll
////////////////
  initFilter() {
    if(document.getElementById('filter') != undefined) document.getElementById('filter').style.left = (this.map_params.sizes.w - 240)/2 + 'px'
  }
  initScroll() {
    this.initFilter()
    this.scrolly = 999
    this.scrolling()
    this.function_scrolling = this.scrolling.bind(this) // 一旦変数に入れないとremoveEventListenerが効かない
    window.addEventListener("scroll", this.function_scrolling, false)
  }

  scrollTop() {
    Util.consoleLog("scrollTop")
    let _self = this
    Util.utiltween(document.scrollingElement.scrollTop, 0, document.documentElement, "scrollTop")
  }

  scrolling () {
    Util.consoleLog('scrolling')
    this.scrolly = window.pageYOffset
    this.preScroll = this.scrolly

    if(this.scrolly < Util.getIOSWindowHeight() / 2) {
      if(document.getElementById('scrollTopRightBtn') != null) {
        document.getElementById('scrollTopRightBtn').classList.add("button_none")
      }
    } else {
      Util.consoleLog("home scrolltop remove")
      if(document.getElementById('scrollTopRightBtn') != null) {
        document.getElementById('scrollTopRightBtn').classList.remove("button_none")
      }
    }

    if(this.map_params.isPC) {
      Util.sync(this.navi_params)
      this.adjustNaviTop();
      //Util.naviToggle(this.scrolly, document.querySelector('#nav-box').clientHeight, document.querySelector('#footer').offsetTop)
    }
  }
  
  adjustNaviTop() {
    Util.consoleLog("adjustNaviTop")
    let height = document.querySelector('#nav-box .toc').clientHeight
    Util.naviToggle(this.scrolly, height, document.querySelector('#footer').offsetTop)
    document.querySelector('#nav-box .toc').style.top = document.querySelector( '#nav-box' ).offsetTop + 65 + "px"
    document.querySelector('#nav-box .toc').style.left = document.querySelector( '#main-box' ).offsetLeft - 8 + "px"
  }

  naviScroll(e) {
    e.preventDefault()
    Util.naviScroll('#' + e.target.dataset.id)
  }

////////////////
// Search
////////////////
  searchOn() {
    this.scrollTop()
    Util.searchOn(this.search_params)
  }
  searchNext() {
    Util.searchNext(this.queryTarget.value)
  }

}
