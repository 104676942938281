import { Controller } from "stimulus"
import Vue from 'vue/dist/vue.esm'
import Map from '../vue/map.vue'
import * as Util from "./util.js"
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import relativeTime from 'dayjs/plugin/relativeTime';

export default class extends Controller {
  static targets = ["list", "tweet", "landmark", "railline", "filter", 'locations', "map", 'query', 'search_collection', "tag", "datetime" ]

  initialize() {
    Util.consoleLog("home initialize")
    
    //Util.consoleLog(Routes.collection_path(1) )
    let _app = this
    this.originalTweetEl = document.querySelector('#tweet-list').innerHTML
    this.originalListEl = document.querySelector('#sorted-list').innerHTML
    this.originalMapEl = document.querySelector('#mapbox').innerHTML
    document.querySelector('.section').classList.remove("c-opacity-0")
    document.addEventListener('turbolinks:visit', function teardown() {
      document.querySelector('.section').classList.add("c-opacity-0")
      document.querySelector('#tweet-list').innerHTML = _app.originalTweetEl
      document.querySelector('#sorted-list').innerHTML = _app.originalListEl
      document.querySelector('#mapbox').innerHTML = _app.originalMapEl
      document.removeEventListener('turbolinks:visit', teardown)
    })
  }

  connect() {
    Util.consoleLog("home connect!")
    this.init()
  }

  disconnect() {
    Util.consoleLog("home disconnect!")
    this.mapvue.$destroy()
    window.removeEventListener("scroll", this.function_scrolling, false)
  }

  async init() {
    Util.consoleLog("home init")
    this.map_params = Util.map_params(this.mapTarget.dataset, this.data, true)
    let myPosition = await Util.getMyPosition(this.map_params.locationNum)
    this.map_params.face = Util.face(this.locationsTargets, this.map_params.locationNum, this.mapTarget.dataset, myPosition)
    this.navi_params = Util.naviParams()
    this.search_params = { searchBox: false }
    this.sortList()
    this.initScroll()
    this.initMapVue()
    this.setMapboxHeight()
    this.changeFormatDatetime()
    Util.consoleLog("home init end")
    Util.sendPageview()// analytics
  }

  changeFormatDatetime() {
    dayjs.locale('ja');
    dayjs.extend(relativeTime);
    this.datetimeTargets.forEach((el, i) => {
      el.innerHTML = dayjs(new Date(el.dataset.datetime)).fromNow()
    })
  }

  filterButton(e) {
    Util.consoleLog("home filterButton")
    let toggle_el = document.getElementById('filter-modal');
    toggle_el.classList.toggle('is-active')
  }

  locationModal(e) {
    Util.consoleLog("home locationModal")
    let toggle_el = document.getElementById('location-modal')
    toggle_el.classList.toggle('is-active')
  }

  snsModal(e) {
    Util.consoleLog("home snsModal")
    let toggle_el = document.getElementById('sns-modal')
    toggle_el.classList.toggle('is-active')
  }

  maplinkModal(e) {
    Util.consoleLog("home maplinkModal")
    let toggle_el = document.getElementById('maplink-modal')
    toggle_el.classList.toggle('is-active')
  }
  sortNameOrder() {
    Util.consoleLog("home sortNameOrder")
    if(this.map_params.sortOrder != 'name') {
      this.map_params.sortOrder = 'name'
      this.sortCheckmarkTggle()
      this.sortList()
    }
  }
  sortCloseOrder() {
    Util.consoleLog("home sortCloseOrder")
    if(this.map_params.sortOrder != 'close') {
      this.map_params.sortOrder = 'close'
      this.sortCheckmarkTggle()
      this.sortList()
    }
  }
  sortCheckmarkTggle() {
    document.getElementById('sort-button-close-check').classList.toggle('is-none')
    document.getElementById('sort-button-name-check').classList.toggle('is-none')
  }

  sortList() {
    let _self = this
    let container = document.querySelector('#list-item-box');
    [].slice.call(container.querySelectorAll('.list-item'))
      .map(function(v){
        let val = _self.map_params.sortOrder == 'close' ? Util.getMarkerDistance({
          lat: _self.map_params.face.lat,
          lng: _self.map_params.face.lng
        }, {
          lat: v.getAttribute('data-lat'),
          lng: v.getAttribute('data-lng')
        }) : Util.yomiToHiragana(v.getAttribute('data-yomi').toString())
        return { dom: v, value: val }
      })
      .sort(function(a,b){ 
        if(_self.map_params.sortOrder == 'close') {
          return a.value - b.value
        } else {
          if (a.value < b.value) {
            return -1
          } else if (a.value > b.value) {
            return 1
          }
          return 0
        }
      })
      .forEach(function(v){ 
        Util.consoleLog("home sortList")
        if(_self.map_params.sortOrder === 'close') _self.map_params.collateArray.push(Number(v.dom.getAttribute('data-id')))
        container.appendChild(v.dom) 
      })
  }

//............................................
//........................a...................
//.mmmmmmmmmmmmmmm....aaaaaaaa..appppppppp....
//.mmmmmmmmmmmmmmmm..maaaaaaaaa.apppppppppp...
//.mmmmmmmmmmmmmmmm..maaaaaaaaa.appppppppppp..
//.mmmm..mmmmm..mmmm.maa...aaaa.apppp...pppp..
//.mmm...mmmm...mmmm...aaaaaaaa.appp....pppp..
//.mmm...mmmm...mmmm.maaaaaaaaa.appp....pppp..
//.mmm...mmmm...mmmm.maaaaaaaaa.appp....pppp..
//.mmm...mmmm...mmmm.maaa..aaaa.appp....pppp..
//.mmm...mmmm...mmmm.maa..aaaaa.appppp.ppppp..
//.mmm...mmmm...mmmm.maaaaaaaaa.apppppppppp...
//.mmm...mmmm...mmmm.maaaaaaaaa.apppppppppp...
//.mmm...mmmm...mmmm..aaaaa.aaa.appp.pppp.....
//..............................appp..........
//..............................appp..........
//..............................appp..........
//............................................
  setMapboxHeight() {
    Util.consoleLog("home setMapboxHeight")
    let box = document.getElementById('mapbox')
    let card_height = this.map_params.isPC ? 0 : 150
    Util.consoleLog(card_height)
    box.style.height = this.map_params.height + card_height + 'px'
  }

  initMapVue() {
    Util.consoleLog("home initMapVue")
    let _app = this
    let items = Util.getCloserOrder(Util.getDatasets(this.listTargets), this.map_params.face)
    let tweets = Util.getCloserOrder(Util.getDatasets(this.tweetTargets), this.map_params.face)
    
    
    Util.consoleLog("home before: new Vue")
    Util.consoleLog(this.mapTarget.dataset.update_date)
    this.mapvue = new Vue({
      el: '#mapbox',
      data: {
        face: this.map_params.face,
        height: this.map_params.height,
        isPC: this.map_params.isPC,
        itlist: Util.getITList(items, tweets),
        landmarks: Util.getDatasets(this.landmarkTargets),
        locationName: Util.locationNames(this.locationsTargets)[this.map_params.locationNum],
        mapboxAt: this.map_params.mapbox_at,
        mapboxPath: this.map_params.mapbox_path,
        padding: this.map_params.isPC ? [80, 80] : [80, 80],
        position: this.map_params.position,
        raillines: Util.getDatasets(this.raillineTargets),
        selected: this.map_params.selectedItem,
        type: 'home',
        updateDate: this.mapTarget.dataset.update_date,
        vItems: items,
        vTweets: tweets,
        vFilter: Util.getDatasets(this.filterTargets)[0]
      },
      components: { 'lmap': Map },
      mounted: function () {
        Util.consoleLog("home map mounted")
      },
      beforeDestroy: function () {
        Util.consoleLog("home map destroy")
      }
    })
    Util.consoleLog("home after: new Vue")
    this.mapvue.$on('setSelectedNum', function (i) {
      _app.selectMarker(i)
    })
    this.mapvue.$on('selectLocation', function () {
      _app.locationModal()
    })
  }

  selectMarker(num) {
    Util.consoleLog("home selectMarker")
    this.map_params.selectedItem = num
    this.mapvue.$data.selected = this.map_params.selectedItem
    //this.headerTriger(num)
    this.data.set("selectedIndex", num)
  }


//...........................................................................
//......SSSSSSS.................................................llll...llll..
//....SSSSSSSSSSS...............................................lllll.lllll..
//...SSSSSSSSSSSSS..............................................lllll.lllll..
//...SSSSSSSSSSSSSS.............................................llll...llll..
//..SSSSSS...SSSSSS.............................................llll...llll..
//..SSSSS.....SSSSS.......ccc...............r.........ooo.......llll...llll..
//..SSSSS......SSSS.....cccccccc....rrrrrrrrrrr....ooooooooo....llll...llll..
//..SSSSSS............ccccccccccc...rrrrrrrrrrr..oooooooooooo...llll...llll..
//..SSSSSSSSSS........cccccccccccc..rrrrrrrrrrr..ooooooooooooo..llll...llll..
//...SSSSSSSSSSSS....cccccc..cccccc.rrrrrrrrrrr.oooooo..oooooo..llll...llll..
//....SSSSSSSSSSSS...ccccc....ccccc.rrrrrr......ooooo.....oooo..llll...llll..
//......SSSSSSSSSSS..cccc......c....rrrrr......ooooo......ooooo.llll...llll..
//.........SSSSSSSS..cccc...........rrrrr......ooooo......ooooo.llll...llll..
//............SSSSS..cccc...........rrrrr......ooooo......ooooo.llll...llll..
//.SSSSS.......SSSSS.cccc...........rrrrr......ooooo......ooooo.llll...llll..
//..SSSSS......SSSS..ccccc.....cccc.rrrrr.......oooo......oooo..llll...llll..
//..SSSSSS....SSSSS..ccccc....ccccc.rrrrr.......ooooo....ooooo..llll...llll..
//..SSSSSSSSSSSSSSS..cccccccccccccc.rrrrr.......oooooooooooooo..llll...llll..
//...SSSSSSSSSSSSSS...cccccccccccc..rrrrr........oooooooooooo...llll..lllll..
//....SSSSSSSSSSSS.....cccccccccc...rrrrr.........oooooooooo....lllll.lllll..
//.....SSSSSSSSS........cccccccc....rrrrr..........oooooooo.....lllll.lllll..
//...........................................................................
  initFilter() {
    console.log(document.getElementById('filter'))
    if(document.getElementById('filter') != undefined) document.getElementById('filter').style.left = (this.map_params.sizes.w - 240)/2 + 'px'
  }
  initScroll() {
    this.initFilter()
    this.scrolly = 999
    this.scrolling()
    this.function_scrolling = this.scrolling.bind(this) // 一旦変数に入れないとremoveEventListenerが効かない
    window.addEventListener("scroll", this.function_scrolling, false)
  }
  scrollTop() {
    Util.consoleLog("home scrollTop")
    Util.utiltween(document.scrollingElement.scrollTop, 0, document.documentElement, "scrollTop")
  }
  scrolling () {
    Util.consoleLog("home scrolling")
    //Util.consoleLog(window.pageYOffset)
    this.scrolly = window.pageYOffset

    //if(this.scrolly < this.preScroll) {
    //  if((this.preScroll - this.scrolly) > 6) {
    //    Util.consoleLog("home down")
    //    if(!this.map_params.isPC) document.getElementById('filter').classList.add("filter_on")
    //  }
    //} else {
    //  if((this.scrolly - this.preScroll) > 6) {
    //    Util.consoleLog("home up")
    //    if(!this.map_params.isPC) document.getElementById('filter').classList.remove("filter_on")
    //  }
    //}
    this.preScroll = this.scrolly

    if(this.scrolly < Util.getIOSWindowHeight() / 2) {
      if(document.getElementById('scrollTopRightBtn') != null) {
        document.getElementById('scrollTopRightBtn').classList.add("button_none")
      }
    } else {
      Util.consoleLog("home scrolltop remove")
      if(document.getElementById('scrollTopRightBtn') != null) {
        document.getElementById('scrollTopRightBtn').classList.remove("button_none")
      }
    }
  }

//.............................................................................................
//......SSSSSSS................................................................hhhh............
//....SSSSSSSSSSS.............................................................hhhhh............
//...SSSSSSSSSSSSS............................................................hhhhh............
//...SSSSSSSSSSSSSS............................................................hhhh............
//..SSSSSS...SSSSSS............................................................hhhh............
//..SSSSS.....SSSSS.......eeee...........aaaa..............rr........ccc.......hhhh............
//..SSSSS......SSSS.....eeeeeeee......aaaaaaaaaa....rrrrrrrrrrr...ccccccccc....hhhhhhhhhhhh....
//..SSSSSS............eeeeeeeeeee....aaaaaaaaaaaa...rrrrrrrrrrr..ccccccccccc...hhhhhhhhhhhhh...
//..SSSSSSSSSS........eeeeeeeeeeee...aaaaaaaaaaaaa..rrrrrrrrrr..ccccccccccccc..hhhhhhhhhhhhh...
//...SSSSSSSSSSSS....eeeeee..eeeeee.aaaaa....aaaaa..rrrrrrrrrr.cccccc..cccccc..hhhhhhh.hhhhh...
//....SSSSSSSSSSSS...eeeee....eeeee.aaaaa....aaaaa..rrrrrr.....ccccc....ccccc..hhhhh....hhhhh..
//......SSSSSSSSSSS..eeeeeeeeeeeeee.....aaaaaaaaaa..rrrrr......ccccc.....cc....hhhhh....hhhhh..
//.........SSSSSSSS..eeeeeeeeeeeeee...aaaaaaaaaaaa..rrrr.......cccc............hhhh.....hhhhh..
//............SSSSS..eeeeeeeeeeeeee..aaaaaaaaaaaaa..rrrr.......cccc............hhhh.....hhhhh..
//.SSSSS.......SSSSS.eeeeeeeeeeeeee.aaaaaaa..aaaaa..rrrr.......cccc............hhhh.....hhhhh..
//..SSSSS......SSSS..eeee......eee..aaaaa....aaaaa..rrrr.......ccccc.....cccc..hhhh.....hhhhh..
//..SSSSSS....SSSSS..eeeee....eeeee.aaaaa...aaaaaa..rrrr.......cccccc...ccccc..hhhh.....hhhhh..
//..SSSSSSSSSSSSSSS..eeeeeeeeeeeeee.aaaaaaaaaaaaaa..rrrr........ccccccccccccc..hhhh.....hhhhh..
//...SSSSSSSSSSSSSS...eeeeeeeeeeee..aaaaaaaaaaaaaa..rrrr........cccccccccccc..hhhhh.....hhhhh..
//....SSSSSSSSSSSS.....eeeeeeeeee....aaaaaaaaaaaaa..rrrr.........cccccccccc...hhhhh.....hhhhh..
//.....SSSSSSSSS........eeeeeeee......aaaaaaaaaaaa..rrrr...........ccccccc....hhhhh.....hhhhh..
//.............................................................................................
  searchOn() {
    this.scrollTop()
    Util.searchOn(this.search_params)
  }
  searchNext() {
    Util.searchNext(this.queryTarget.value)
  }

}
