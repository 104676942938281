import TWEEN from "@tweenjs/tween.js"
import Cookies from "js-cookie/src/js.cookie.js"
import { toast } from 'bulma-toast'

export function consoleLog(message) {
  //console.log(message)
}

export function getNameOrder(items) {
  //let _self = this
  let d = []
  for (let i = items.length - 1; i >= 0; i--) {
    d.push(items[i])
  }
  return d.sort(function(a, b) {
    a = yomiToHiragana(a.yomi.toString())
    b = yomiToHiragana(b.yomi.toString())
    if (a < b) {
      return -1
    } else if (a > b) {
      return 1
    }
    return 0
  })
}

export function yomiToHiragana(yomi) {
  return yomi.replace(/[\u30a1-\u30f6]/g, function(match) {
    var hira = match.charCodeAt(0) - 0x60
    return String.fromCharCode(hira)
  })
}

export function getITList(items, tweets) {
  let items_tweets = items.concat(tweets)
  items_tweets.sort(function(a,b){
    if(a.distance < b.distance) return -1;
    if(a.distance > b.distance) return 1;
    return 0;
  });
  let i_t_array = []
  let i_count = 0
  let t_count = 0
  for (let i = 0; i < items_tweets.length; i++) {
    if(typeof(items_tweets[i].screen_name) == 'undefined') {
      i_t_array.push(['item', i_count, items_tweets[i].distance])
      i_count++
    } else {
      i_t_array.push(['tweet', t_count, items_tweets[i].distance])
      t_count++
    }
  }
  return i_t_array
}

export function getCloserOrder(items, face) {
  let data = []
  items.forEach((el, i) => {
    el.distance = getMarkerDistance({
      lat: face.lat,
      lng: face.lng
    }, {
      lat: el.lat,
      lng: el.lng
    })
    data.push(el)
  })
  data = sortMarkers(data)
  return data
}

export function getMarkerDistance(mylatlng, position) {
  let d =
    6378.14 *
    Math.acos(
      Math.cos(getRadians(mylatlng.lat)) *
      Math.cos(getRadians(position.lat)) *
      Math.cos(getRadians(position.lng) - getRadians(mylatlng.lng)) +
      Math.sin(getRadians(mylatlng.lat)) * Math.sin(getRadians(position.lat))
    )
  return d
}

export function getRadians(deg) {
  return deg * Math.PI / 180
}

export function addDirection(array, face) {
  // 方角を計算
  for (let i = array.length - 1; i >= 0; i--) {
    array[i].direction = geoDirection(
      face,
      array[i]
    )
  }
  return array
}

export function geoDirection(mylatlng, position) {
  let Y = Math.cos(position.lng * Math.PI / 180) * Math.sin(position.lat * Math.PI / 180 - mylatlng.lat * Math.PI / 180)
  let X = Math.cos(mylatlng.lng * Math.PI / 180) * Math.sin(position.lng * Math.PI / 180) - Math.sin(mylatlng.lng * Math.PI / 180) * Math.cos(position.lng * Math.PI / 180) * Math.cos(position.lat * Math.PI / 180 - mylatlng.lat * Math.PI / 180)
  let dirE0 = 180 * Math.atan2(Y, X) / Math.PI
  if (dirE0 < 0) dirE0 = dirE0 + 360
  return (dirE0 + 90) % 360
}

export function sortMarkers(data) {
  let d = []
  for (let i = data.length - 1; i >= 0; i--) {
    d.push(data[i])
  }
  d.sort(function(a, b) {
    if (a.distance < b.distance) return -1
    if (a.distance > b.distance) return 1
    return 0
  })
  let dd = []
  d.forEach((el, i) => {
    el.sortIndex = i
    dd.push(el)
  })
  return dd
}

//....................................................
//................iiiii...............................
//................iiiii...............................
//................iiiii...............................
//................iiiii...............................
//................iiiii...............................
//.......sss...............................eee........
//....sssssssss...iiiii.zzzzzzzzzzzz....eeeeeeeee.....
//...sssssssssss..iiiii.zzzzzzzzzzzz...eeeeeeeeeee....
//..ssssssssssss..iiiii.zzzzzzzzzzzz..eeeeeeeeeeeee...
//..sssss..sssss..iiiii.zzzzzzzzzzzz..eeeee...eeeee...
//..sssss...sss...iiiii......zzzzzz..eeeee.....eeee...
//..sssssssss.....iiiii.....zzzzzz...eeeeeeeeeeeeeee..
//..sssssssssss...iiiii.....zzzzz....eeeeeeeeeeeeeee..
//....ssssssssss..iiiii....zzzzzz....eeeeeeeeeeeeeee..
//......sssssssss.iiiii...zzzzzz.....eeeeeeeeeeeeeee..
//..ssss....sssss.iiiii..zzzzzz......eeeee.....eee....
//.ssssss...sssss.iiiii.zzzzzz.......eeeeee....eeeee..
//..sssssssssssss.iiiii.zzzzzzzzzzzzz.eeeeeeeeeeeee...
//..ssssssssssss..iiiii.zzzzzzzzzzzzz.eeeeeeeeeeeee...
//...sssssssssss..iiiii.zzzzzzzzzzzzz..eeeeeeeeeee....
//....ssssssss....iiiii.zzzzzzzzzzzzz....eeeeeee......
//....................................................
export function getIOSWindowHeight() {
  let zoomLevel = document.documentElement.clientWidth / window.innerWidth
  return window.innerHeight * zoomLevel
}

export function getIOSWindowWidth() {
  let zoomLevel = document.documentElement.clientWidth / window.innerWidth
  // sizes.setOriginalWindowWidth = window.innerWidth * zoomLevel
  return window.innerWidth * zoomLevel > 768 ? 375 : window.innerWidth * zoomLevel
}

export function getHeightOfIOSToolbars() {
  let tH =
    (window.orientation === 0 ? screen.height : screen.width) -
    getIOSWindowHeight()
  return tH > 1 ? tH : 0
}



//......................................................
//...............................................viiii..
//...............................................viiii..
//...............................................viiii..
//...............................................viiii..
//...............................................viiii..
//......................aaaa............................
//.nnnn.nnnnnnn......aaaaaaaaaa..aavvv......vvvvvviiii..
//.nnnnnnnnnnnnn....aaaaaaaaaaaa.aavvv.....vvvvv.viiii..
//.nnnnnnnnnnnnnn..naaaaaaaaaaaa..avvvv....vvvvv.viiii..
//.nnnnnnn..nnnnn..naaaa....aaaa..avvvv....vvvv..viiii..
//.nnnnnn...nnnnn..naaa.....aaaaa.avvvv...vvvvv..viiii..
//.nnnnn.....nnnn......aaaaaaaaaa..vvvvv..vvvvv..viiii..
//.nnnn......nnnn....aaaaaaaaaaaa..vvvvv.vvvvv...viiii..
//.nnnn......nnnn...aaaaaaaaaaaaa...vvvv.vvvvv...viiii..
//.nnnn......nnnn..naaaaaa..aaaaa...vvvvvvvvv....viiii..
//.nnnn......nnnn..naaaa....aaaaa...vvvvvvvvv....viiii..
//.nnnn......nnnn..naaa....aaaaaa....vvvvvvvv....viiii..
//.nnnn......nnnn..naaaaaaaaaaaaa....vvvvvvv.....viiii..
//.nnnn......nnnn..naaaaaaaaaaaaa.....vvvvvv.....viiii..
//.nnnnn.....nnnn...aaaaaaaaaaaaa.....vvvvv......viiii..
//.nnnnn.....nnnn....aaaaaa.aaaaa.....vvvvv......viiii..
//......................................................
export function sync(navi_params) {

  //console.log('sync')
  let windowHeight = window.innerHeight

  let pathStart = navi_params.pathLength
  let pathEnd = 0

  let visibleItems = 0

  navi_params.tocItems.forEach( function( item ) {

    let targetBounds = item.target.getBoundingClientRect()

    if( targetBounds.bottom > windowHeight * navi_params.TOP_MARGIN && targetBounds.top < windowHeight * ( 1 - navi_params.BOTTOM_MARGIN ) ) {
      pathStart = Math.min( item.pathStart, pathStart )
      pathEnd = Math.max( item.pathEnd, pathEnd )

      visibleItems += 1

      item.listItem.classList.add( 'visible' )
    }
    else {
      item.listItem.classList.remove( 'visible' )
    }

  } )

  if( visibleItems > 0 && pathStart < pathEnd ) {
    if( pathStart !== navi_params.lastPathStart || pathEnd !== navi_params.lastPathEnd ) {
      navi_params.tocPath.setAttribute( 'stroke-dashoffset', '1' )
      navi_params.tocPath.setAttribute( 'stroke-dasharray', '1, '+ pathStart +', '+ ( pathEnd - pathStart ) +', ' + navi_params.pathLength )
      navi_params.tocPath.setAttribute( 'opacity', 1 )
      navi_params.tocPath.setAttribute( 'stroke-width', 1 )
    }
  }
  else {
    navi_params.tocPath.setAttribute( 'opacity', 0 )
  }

  navi_params.lastPathStart = pathStart
  navi_params.lastPathEnd = pathEnd

}

export function drawPath(navi_params) {
  //console.log(navi_params)
  //
  let _self = this
  navi_params.tocItems = [].slice.call( navi_params.toc.querySelectorAll( 'li' ) )
  navi_params.tocItems = navi_params.tocItems.map( function( item ) {
    let anchor = item.querySelector( 'a' )
    let target = document.getElementById( anchor.getAttribute( 'href' ).slice( 1 ) )

    return {
      listItem: item,
      anchor: anchor,
      target: target
    }
  } )

  navi_params.tocItems = navi_params.tocItems.filter( function( item ) {
    return !!item.target
  } )

  let path = []
  let pathIndent

  navi_params.tocItems.forEach( function( item, i ) {

    let x = item.anchor.offsetLeft - 8
    let y = item.anchor.offsetTop
    let height = item.anchor.offsetHeight

    if( i === 0 ) {
      path.push( 'M', x, y, 'L', x, y + height )
      item.pathStart = 0
    }
    else {
      if( pathIndent !== x ) path.push( 'L', pathIndent, y )

      path.push( 'L', x, y )

      navi_params.tocPath.setAttribute( 'd', path.join( ' ' ) )
      item.pathStart = navi_params.tocPath.getTotalLength() || 0

      path.push( 'L', x, y + height )
    }

    pathIndent = x

    navi_params.tocPath.setAttribute( 'd', path.join( ' ' ) )
    item.pathEnd = navi_params.tocPath.getTotalLength()

  } )

  navi_params.pathLength = navi_params.tocPath.getTotalLength()
  sync(navi_params)
}

export function naviScroll(id) {
  utiltween(document.scrollingElement.scrollTop, document.querySelector(id).getBoundingClientRect().top + window.pageYOffset, document.documentElement, "scrollTop")
}

export function naviToggle(scrolly, height, top) {
  if((scrolly + height + 200) > top) {
    document.querySelector('#nav-box').classList.add("is-invisible")
  } else {
    document.querySelector('#nav-box').classList.remove("is-invisible")
  }
}

//..........................................................................................
//..........................................................................hhhh............
//.........................................................................hhhhh............
//.........................................................................hhhhh............
//..........................................................................hhhh............
//..........................................................................hhhh............
//.......sss...........eeee...........aaaa..............rr........ccc.......hhhh............
//....sssssssss......eeeeeeee......aaaaaaaaaa....rrrrrrrrrrr...ccccccccc....hhhhhhhhhhhh....
//...sssssssssss...eeeeeeeeeee....aaaaaaaaaaaa...rrrrrrrrrrr..ccccccccccc...hhhhhhhhhhhhh...
//..ssssssssssss...eeeeeeeeeeee...aaaaaaaaaaaaa..rrrrrrrrrr..ccccccccccccc..hhhhhhhhhhhhh...
//..sssss..sssss..eeeeee..eeeeee.aaaaa....aaaaa..rrrrrrrrrr.cccccc..cccccc..hhhhhhh.hhhhh...
//..sssss...sss...eeeee....eeeee.aaaaa....aaaaa..rrrrrr.....ccccc....ccccc..hhhhh....hhhhh..
//..sssssssss.....eeeeeeeeeeeeee.....aaaaaaaaaa..rrrrr......ccccc.....cc....hhhhh....hhhhh..
//..sssssssssss...eeeeeeeeeeeeee...aaaaaaaaaaaa..rrrr.......cccc............hhhh.....hhhhh..
//....ssssssssss..eeeeeeeeeeeeee..aaaaaaaaaaaaa..rrrr.......cccc............hhhh.....hhhhh..
//......sssssssss.eeeeeeeeeeeeee.aaaaaaa..aaaaa..rrrr.......cccc............hhhh.....hhhhh..
//..ssss....sssss.eeee......eee..aaaaa....aaaaa..rrrr.......ccccc.....cccc..hhhh.....hhhhh..
//.ssssss...sssss.eeeee....eeeee.aaaaa...aaaaaa..rrrr.......cccccc...ccccc..hhhh.....hhhhh..
//..sssssssssssss.eeeeeeeeeeeeee.aaaaaaaaaaaaaa..rrrr........ccccccccccccc..hhhh.....hhhhh..
//..ssssssssssss...eeeeeeeeeeee..aaaaaaaaaaaaaa..rrrr........cccccccccccc..hhhhh.....hhhhh..
//...sssssssssss....eeeeeeeeee....aaaaaaaaaaaaa..rrrr.........cccccccccc...hhhhh.....hhhhh..
//....ssssssss.......eeeeeeee......aaaaaaaaaaaa..rrrr...........ccccccc....hhhhh.....hhhhh..
//..........................................................................................
export function searchOn(search_params) {
  document.getElementById('search-box').classList.toggle("search_box_on")
  search_params.searchBox = !search_params.searchBox
  if(search_params.searchBox) {
    //document.getElementById('search-box-input').focus()
  }
}
export function searchNext(filterText = '') {
  //console.log('searchNext')
  const params = new URLSearchParams()
  params.set('words', filterText)
  document.getElementById('search-list').innerHTML = ""
  fetch('/home/search/?' + params.toString())
  .then(response => response.text())
  .then(items => {
    //element.innerHTML = html
    let t = document.querySelector('#search_template')
    JSON.parse(items).forEach((item, i) => {
      t.content.querySelector('.search-item-name').innerHTML = item[1]
      t.content.querySelector('.search-item-link').href = Routes.item_path(item[0])
      let clone = document.importNode(t.content, true)
      document.getElementById('search-list').appendChild(clone)
    })
  })
}

//.......................................................................................................
//.llll...........................................................tiiii..................................
//.lllll..........................................................tiiii..................................
//.lllll.................................................ttttt....tiiii..................................
//.llll..................................................ttttt....tiiii..................................
//.llll..................................................ttttt....tiiii..................................
//.llll........ooo.............ccc...........aaaa........ttttt................oooo.......................
//.llll.....ooooooooo.......ccccccccc.....aaaaaaaaaa..aattttttttt.tiiii....oooooooooo....nnnnnnnnnnnn....
//.llll....ooooooooooo.....ccccccccccc...aaaaaaaaaaaa.aattttttttt.tiiii...oooooooooooo...nnnnnnnnnnnnn...
//.llll...ooooooooooooo...ccccccccccccc..aaaaaaaaaaaaaaattttttttt.tiiii..ooooooooooooo...nnnnnnnnnnnnnn..
//.llll..looooo...oooooo.occccc..cccccc.caaaa....aaaaaaattttttttt.tiiii..oooooo..oooooo..nnnnnnn.nnnnnn..
//.llll..loooo.....ooooo.occcc....ccccc.caaaa....aaaaa...ttttt....tiiii.ioooo.....ooooo..nnnnnn...nnnnn..
//.llll..loooo......oooo.occcc.....cc.......aaaaaaaaaa...ttttt....tiiii.ioooo......oooo..nnnnn....nnnnn..
//.llll..looo.......oooo.occc.............aaaaaaaaaaaa...ttttt....tiiii.ioooo......ooooo.nnnn.....nnnnn..
//.llll..looo.......oooo.occc............aaaaaaaaaaaaa...ttttt....tiiii.iooo.......ooooo.nnnn.....nnnnn..
//.llll..looo.......oooo.occc...........caaaaaa..aaaaa...ttttt....tiiii.ioooo......oooo..nnnn.....nnnnn..
//.llll..loooo.....ooooo.occcc.....cccc.caaaa....aaaaa...ttttt....tiiii.ioooo......oooo..nnnn.....nnnnn..
//.llll..looooo...oooooo.occccc...ccccc.caaaa...aaaaaa...ttttt....tiiii.iooooo....ooooo..nnnn.....nnnnn..
//.llll...ooooooooooooo...ccccccccccccc.caaaaaaaaaaaaa...tttttttt.tiiii..oooooooooooooo..nnnn.....nnnnn..
//.llll...ooooooooooooo...cccccccccccc..caaaaaaaaaaaaa....ttttttt.tiiii..ooooooooooooo...nnnn.....nnnnn..
//.lllll...ooooooooooo.....cccccccccc....aaaaaaaaaaaaa....ttttttt.tiiii...ooooooooooo....nnnn.....nnnnn..
//.lllll.....ooooooo.........ccccccc......aaaaaaaaaaaa.....tttttt.tiiii.....oooooooo.....nnnn.....nnnnn..
//.......................................................................................................
export function getMyPosition(locationNum) {
  return new Promise(function(resolve, reject) {
    if(locationNum === 0 && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          resolve(position)
        },
        function (error) {
          let errorInfo = [
            '原因不明のエラーが発生しました。(エラーコード:' + error.code + ')',
            '位置情報の取得が許可されていません。',
            '位置情報が取得できませんでした。',
            'タイムアウトしました。'
          ]
          resolve(errorInfo[error.code])
        },
        {
          'enableHighAccuracy': false,
          'timeout': 8000,
          'maximumAge': 2000
        }
      )
    } else {
      resolve(false)
    }
  })
}

export function utiltween(oldValue, newValue, obj, ooo, callback = "") {
  let tw = new TWEEN.Tween({ tweeningNumber: oldValue })
  function animate() {
    if (TWEEN.update()) {
      requestAnimationFrame(animate)
    } else {
      TWEEN.removeAll()
      if (callback !== "" && typeof callback === "function") {
        callback()
      }
    }
  }
  tw
    .easing(TWEEN.Easing.Quadratic.Out)
    .to({ tweeningNumber: newValue }, 300)
    .onUpdate(function(tobj) {
      obj[ooo] = tobj.tweeningNumber.toFixed(0)
    })
    .start()
  animate()
}

//............................................................................................................
//.............................................................dddd...........................................
//............................................................ddddd...........................................
//.................................ttttt.......................dddd..................ttttt....................
//.................................ttttt.......................dddd..................ttttt....................
//.................................ttttt.......................dddd...................tttt....................
//.......sss...........eeee........ttttt..................d....dddd.......aaaa........tttt.........aaaa.......
//....sssssssss......eeeeeeee...etttttttttt...........ddddddddddddd....aaaaaaaaaa..attttttttt...aaaaaaaaaa....
//...sssssssssss...eeeeeeeeeee..etttttttttt..........dddddddddddddd...aaaaaaaaaaaa.attttttttt..aaaaaaaaaaaa...
//..ssssssssssss...eeeeeeeeeeee.etttttttttt.........ddddddddddddddd..aaaaaaaaaaaaa.attttttttt..aaaaaaaaaaaaa..
//..sssss..sssss..eeeeee..eeeeeeetttttttttt.........dddddd..ddddddd..aaaaa...aaaaa.attttttttt.aaaaa....aaaaa..
//..sssss...sss...eeeee....eeeee...tttt.............ddddd....dddddd..aaaa.....aaaa....tttt....aaaaa....aaaaa..
//..sssssssss.....eeeeeeeeeeeeee...tttt............ddddd......ddddd......aaaaaaaaa....tttt........aaaaaaaaaa..
//..sssssssssss...eeeeeeeeeeeeee...tttt............ddddd......ddddd...aaaaaaaaaaaa....tttt......aaaaaaaaaaaa..
//....ssssssssss..eeeeeeeeeeeeee...tttt............ddddd......ddddd..aaaaaaaaaaaaa....tttt.....aaaaaaaaaaaaa..
//......sssssssss.eeeeeeeeeeeeee...tttt............ddddd......ddddd..aaaaaaa..aaaa....tttt....aaaaaaa..aaaaa..
//..ssss....sssss.eeee......eee....tttt.............dddd......ddddd..aaaa.....aaaa....tttt....aaaaa....aaaaa..
//.ssssss...sssss.eeeee....eeeee...ttttt............ddddd....dddddd.aaaaa....aaaaa....tttt....aaaaa...aaaaaa..
//..sssssssssssss.eeeeeeeeeeeeee...tttttttt.........ddddddddddddddd..aaaaaaaaaaaaa....ttttttt.aaaaaaaaaaaaaa..
//..ssssssssssss...eeeeeeeeeeee....tttttttt..........dddddddddddddd..aaaaaaaaaaaaa....ttttttttaaaaaaaaaaaaaa..
//...sssssssssss....eeeeeeeeee......ttttttt...........ddddddddddddd...aaaaaaaaaaaa....tttttttt.aaaaaaaaaaaaa..
//....ssssssss.......eeeeeeee.......ttttttt............ddddddd.dddd....aaaaaa.aaaa.....ttttttt..aaaaaaaaaaaa..
//............................................................................................................

export function map_params(mapTarget, data, isTop = false) {
  return {
    collateArray: [],
    face: {},
    //height: mapTarget.is_pc == 'true' ? 650 : (getIOSWindowHeight() / 3) * 2 + (isTop ? 40 : 0),
    height: mapTarget.is_pc == 'true' ? (isTop ? 600 : 400) : (getIOSWindowHeight() / 3) * 2 + (isTop ? 60 : 0),
    isPC: mapTarget.is_pc == 'true' ? true : false,
    locationNum: setLocationNum(data),
    mapbox_at: mapTarget.mapbox_at,
    mapbox_path: mapTarget.mapbox_path,
    mapvue: {},
    position: {
      left_top_lat: mapTarget.left_top_lat,
      left_top_lng: mapTarget.left_top_lng,
      right_bottom_lat: mapTarget.right_bottom_lat, 
      right_bottom_lng: mapTarget.right_bottom_lng
    },
    selectedItem: data.get("selectedIndex") != null ? Number(data.get("selectedIndex")) : 0,
    sizes: {
      h: getIOSWindowHeight(),
      w: getIOSWindowWidth()
    },
    sortOrder: 'close',
    updated_at: mapTarget.updated_at
  }
}

export function setLocationNum(data) {
  // for test
  let num = 1
  if(typeof(Cookies.get('locationNum')) !== 'undefined') {
    num = Cookies.get('locationNum')
  }
  if(data.get("locationNum") !== '999') {
    num = data.get("locationNum")
  }
  Cookies.set('locationNum', isNaN(num) ? 1 : Number(num))
  
  return Number(num)
}

export function getDatasets(object) {
  let data = []
  object.forEach((el, i) => {
    let item = {}
    for(const [key, value] of Object.entries(el.dataset)) {
      if(key != 'target') item[key] = value
    }
    // latlng追加
    if (typeof(item.lat) !== 'undefined' && typeof(item.lng) !== 'undefined') {
      item.lat = Number(item.lat)
      item.lng = Number(item.lng)
      item.latlng = L.latLng(item.lat, item.lng)
    }
    // 文字列を数値へ変換
    if (typeof(item.index) !== 'undefined') {
      item.index = Number(item.index)
    }

    data.push(item)
  })
  return data
}

export function locationNames(locationsTargets) {
  let locationNames = []
  locationsTargets.forEach((el, i) => {
    locationNames.push(locationsTargets[i].dataset.name)
    if(Number(locationsTargets[i].dataset.index) !== Number(map_params.locationNum)) {
      el.classList.toggle('my-custom-shake' + i.toString(10))
      el.classList.toggle('shake-constant')
    } else {
      el.classList.toggle('active')
    }
  })
  return locationNames
}
export function resolveAfter2Seconds() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve('resolved');
    }, 2000);
  });
}

export function face(locationsTargets, num, mapTarget, myPosition) {
  //console.log('face')
  locationsTargets[0].dataset.lat = locationsTargets[1].dataset.lat
  locationsTargets[0].dataset.lng = locationsTargets[1].dataset.lng
  if(num == 0) {
    // 現在位置取得チェック
    if(typeof(myPosition.coords) != 'undefined') {
      locationsTargets[0].dataset.lat = myPosition.coords.latitude
      locationsTargets[0].dataset.lng = myPosition.coords.longitude
    } else {
      toast({
        message: myPosition + " " + locationsTargets[1].dataset.name + 'が現在地として表示されます。',
        duration: 5000,
        type: 'is-warning',
        dismissible: true,
        position: 'top-center'
      })
    }
    // 範囲外チェック
    let check = true
    if(mapTarget.left_top_lat < locationsTargets[0].dataset.lat) {
      check = false
    }
    if(mapTarget.right_bottom_lat > locationsTargets[0].dataset.lat) {
      check = false
    }
    if(mapTarget.left_top_lngg > locationsTargets[0].dataset.ln) {
      check = false
    }
    if(mapTarget.right_bottom_lng < locationsTargets[0].dataset.lng) {
      check = false
    }
    if(!check) {
      locationsTargets[0].dataset.lat = locationsTargets[1].dataset.lat
      locationsTargets[0].dataset.lng = locationsTargets[1].dataset.lng
      toast({
        message: '現在位置が範囲外です。'　+ " " + locationsTargets[1].dataset.name + 'が現在地として表示されます。',
        duration: 5000,
        type: 'is-warning',
        dismissible: true,
        position: 'top-center'
      })
    }
    
  }
  let lat = Number(locationsTargets[num].dataset.lat)
  let lng = Number(locationsTargets[num].dataset.lng)
  //console.log(lat, lng)
  return {
    lat: lat,
    lng: lng,
    latlng: L.latLng(lat, lng)
  }
}

export function naviParams() {
  return {
    toc: document.querySelector( '.toc' ),
    tocPath: document.querySelector( '.toc-marker path' ),
    tocItems: [],
    TOP_MARGIN: 0.1,
    BOTTOM_MARGIN: 0.2,
    pathLength: 0,
    lastPathStart: 0,
    lastPathEnd: 0
  }
}

export function setRouteLinks(face, item) {
  // Apple
  let url_apple = "http://maps.apple.com?saddr="
  url_apple += face.lat + ',' + face.lng
  url_apple += '&daddr=' + Number(item.lat) + ',' + Number(item.lng)
  url_apple += '&center=' + face.lat + ',' + face.lng
  let link_el_apple = document.getElementById('route_link_apple')
  link_el_apple.href = url_apple
  
  // YAHOO
  let url_yahoo = "https://beta-map.yahoo.co.jp/route/walk?"
  //url_yahoo += "from=" + encodeURI('ああああ')
  //url_yahoo += "&to=" + encodeURI('ああああ')
  url_yahoo += "&fromLat=" + face.lat + "&fromLon=" + face.lng + "&"
  url_yahoo += "toLat=" + Number(item.lat) + "&toLon=" + Number(item.lng) + "&"
  //url_yahoo += "t=1&y=202011&d=24&h=11&m=50&"
  url_yahoo += "lat=" + face.lat + "&lon=" + face.lng + "&"
  url_yahoo += "sort=1&zoom=9&maptype=basic"
  let link_el_yahoo = document.getElementById('route_link_yahoo')
  link_el_yahoo.href = url_yahoo

  // Google
  let url_google = "http://maps.google.com?saddr="
  url_google += face.lat + ',' + face.lng
  url_google += '&daddr=' + Number(item.lat) + ',' + Number(item.lng)
  url_google += '&center=' + face.lat + ',' + face.lng
  url_google += '&directionsmode=walking&zoom=17'
  let link_el_google = document.getElementById('route_link_google')
  link_el_google.href = url_google
}

// analytics page view
export function sendPageview() {
  if(window.ga != undefined) {
    ga('set', 'location', location.href.split('#')[0])
    ga('send', 'pageview')
  }
}