<template>
  <div>
    <div class="columns is-gapless">
      <div class="column">
        <div class="map-frame m-0 p-0 is-relative is-clipped">
        <template v-if="collisionAreas.length > 0"></template>
<!--
//............................................
//........................a...................
//.mmmmmmmmmmmmmmm....aaaaaaaa..appppppppp....
//.mmmmmmmmmmmmmmmm..maaaaaaaaa.apppppppppp...
//.mmmmmmmmmmmmmmmm..maaaaaaaaa.appppppppppp..
//.mmmm..mmmmm..mmmm.maa...aaaa.apppp...pppp..
//.mmm...mmmm...mmmm...aaaaaaaa.appp....pppp..
//.mmm...mmmm...mmmm.maaaaaaaaa.appp....pppp..
//.mmm...mmmm...mmmm.maaaaaaaaa.appp....pppp..
//.mmm...mmmm...mmmm.maaa..aaaa.appp....pppp..
//.mmm...mmmm...mmmm.maa..aaaaa.appppp.ppppp..
//.mmm...mmmm...mmmm.maaaaaaaaa.apppppppppp...
//.mmm...mmmm...mmmm.maaaaaaaaa.apppppppppp...
//.mmm...mmmm...mmmm..aaaaa.aaa.appp.pppp.....
//..............................appp..........
//..............................appp..........
//..............................appp..........
//............................................
-->
          <l-map ref="map" :style="{height: height - 150 + 'px' }" :zoom="12" :max-bounds="maxBounds" :center="center" :options="mapOptions" @update:zoom="zoomUpdated" @movestart="moveStart" @moveend="moveEnd">
            <l-tile-layer :options="{ maxZoom: 19 }" :url="url_l" :attribution="attribution"></l-tile-layer>

<!--
//.........................
//.lll.....................
//.lll.....................
//.lll.....................
//.lll.....................
//.lll..mmmmmmmmmmmmmmm....
//.lll..mmmmmmmmmmmmmmmm...
//.lll..mmmmmmmmmmmmmmmm...
//.lll..mmmm..mmmmm..mmmm..
//.lll..mmm...mmmm...mmmm..
//.lll..mmm...mmmm...mmmm..
//.lll..mmm...mmmm...mmmm..
//.lll..mmm...mmmm...mmmm..
//.lll..mmm...mmmm...mmmm..
//.lll..mmm...mmmm...mmmm..
//.lll..mmm...mmmm...mmmm..
//.lll..mmm...mmmm...mmmm..
//.........................
-->
            <template v-for="(obj, index) in landmarks">
              <template v-if="zoom >= obj.zoom">
                <l-marker :lat-lng="obj.latlng" :icon="generateLandmarkIcons(index)" :zIndexOffset="0">
                  <l-tooltip v-if="obj.icon != 'none'" :content="obj.name" />
                </l-marker>
                <template v-if="obj.icon != 'none' && obj.name.endsWith('駅')">
                  <l-marker :lat-lng="obj.latlng" :icon="generateLandmarkText(index)" :zIndexOffset="0">
                  </l-marker>
                </template>
              </template>
            </template>
<!--
//................................
//.....................aiii.illl..
//.....................aiii.ill...
//.....................aiii.ill...
//..............a...........ill...
//.rrrrrrrr..aaaaaaa...aiii.ill...
//.rrrrrrrr.aaaaaaaaa..aiii.ill...
//.rrrrrrrrraaaaaaaaa..aii..ill...
//.rrrrr...raaa...aaaa.aii..ill...
//.rrrr......aaaaaaaaa.aii..ill...
//.rrr......aaaaaaaaaa.aii..ill...
//.rrr.....raaaaaaaaaa.aii..ill...
//.rrr.....raaa...aaaa.aii..ill...
//.rrr.....raaa..aaaaa.aii..ill...
//.rrr.....raaaaaaaaaa.aii..ill...
//.rrr......aaaaaaaaaa.aiii.illl..
//.rrr.......aaaaaaaaa.aiii.illl..
//................................
-->

          <template v-if="zoom <= 13">
            <template v-for="(obj, index) in rls">
              <l-polyline
                :lat-lngs="obj"
                color="#ddd" :weight="3" dashArray='8, 8' dashOffset='0'>
              </l-polyline>
            </template>
          </template>

<!--
//...................................
//.........dddd......................
//..........ddd..............tttt....
//..........ddd...............ttt....
//..........ddd......o........ttt....
//...dddddddddd...oooooooo.ottttttt..
//..ddddddddddd..ooooooooooottttttt..
//..ddddddddddd.oooooooooooottttttt..
//.ddddd..ddddd.oooo...oooo...ttt....
//.dddd....dddd.oooo....oooo..ttt....
//.dddd....dddd.ooo.....oooo..ttt....
//.dddd....dddd.ooo.....oooo..ttt....
//.dddd....dddd.oooo....oooo..ttt....
//..dddd..ddddd.ooooo..oooo...ttt....
//..ddddddddddd..oooooooooo...ttttt..
//...dddddddddd..ooooooooo....ttttt..
//....ddddd.ddd....oooooo......tttt..
//...................................
-->
            <template v-for="(i_t, index) in i_t_list">
              <template v-if="i_t[0] === 'item' && items[i_t[1]].isInView">
                <template v-if="index==selecteditem">
                  <l-marker :lat-lng="items[i_t[1]].latlng" :icon="active_icon" :zIndexOffset="999" :ref="'mk' + index"></l-marker>
                </template>
                <template v-else>
                  <l-marker @click="selectMarker(index)" :lat-lng="items[i_t[1]].latlng" :icon="dot_icon" :zIndexOffset="index+99" :ref="'mk' + index"></l-marker>
                </template>
              </template>
              <template v-if="i_t[0] === 'tweet' && tweets[i_t[1]].isInView">
                <template v-if="index==selecteditem">
                  <l-marker :lat-lng="tweets[i_t[1]].latlng" :icon="active_tweet_icon" :zIndexOffset="999" :ref="'mk' + index"></l-marker>
                </template>
                <template v-else>
                  <l-marker @click="selectMarker(index)" :lat-lng="tweets[i_t[1]].latlng" :icon="dot_tweet_icon" :zIndexOffset="index+99" :ref="'mk' + index"></l-marker>
                </template>
              </template>
            </template>
<!--
//.........................................
//...ttt...........................tttt....
//...ttt...........................tttt....
//...ttt........e..................tttt....
//.tttttttt..eeeeeee..exxxx..xxxxxttttttt..
//.tttttttt.eeeeeeeee..xxxx.xxxxxxttttttt..
//.tttttttteeeeeeeeeee.xxxxxxxxx.xttttttt..
//...ttt...eeee...eeee..xxxxxxxx...tttt....
//...ttt...eeeeeeeeeee...xxxxxx....tttt....
//...ttt...eeeeeeeeeee...xxxxx.....tttt....
//...ttt...eeeeeeeeeee...xxxxxx....tttt....
//...ttt...eeee....ee...xxxxxxx....tttt....
//...tttt.teeeee..eeee.xxxxxxxxx...tttt.t..
//...tttttt.eeeeeeeeee.xxxx.xxxxx..tttttt..
//...tttttt.eeeeeeeee.exxxx..xxxxx..ttttt..
//....ttttt...eeeeee..exxx....xxxx..ttttt..
//.........................................
-->
            <template v-for="(i_t, index) in i_t_list">
              <template v-if="i_t[0] === 'item' && index == selecteditem && items[i_t[1]].isInView">
                <l-marker :lat-lng="items[i_t[1]].latlng" :icon="location_label" :zIndexOffset="999"></l-marker>
              </template>
              <template v-if="i_t[0] === 'tweet' && index == selecteditem && tweets[i_t[1]].isInView">
                <l-marker :lat-lng="tweets[i_t[1]].latlng" :icon="location_label" :zIndexOffset="999"></l-marker>
              </template>
              <template v-if="i_t[0] === 'item' && index != selecteditem && items[i_t[1]].isInView">
              <l-marker @click="selectMarker(index)" :lat-lng="items[i_t[1]].latlng2 == null ? items[i_t[1]].latlng : items[i_t[1]].latlng2" :icon="generateNameIcons(index, index == selecteditem)" :zIndexOffset="(index==selecteditem?index+999:index+landmarks.length)"></l-marker>
              </template>
              <template v-if="i_t[0] === 'tweet' && index != selecteditem && tweets[i_t[1]].isInView">
              <l-marker @click="selectMarker(index)"  :lat-lng="tweets[i_t[1]].latlng2 == null ? tweets[i_t[1]].latlng : tweets[i_t[1]].latlng2" :icon="generateNameIcons(index, index==selecteditem)" :zIndexOffset="(index==selecteditem?index+999:index+landmarks.length)"></l-marker>
              </template>
            </template>
<!--
//...................................
//.lll..iii..........................
//.lll..iii..........................
//.lll..iii..........................
//.lll.......................ee......
//.lll..iii.innnnnnnnn....eeeeeee....
//.lll..iii.innnnnnnnnn..eeeeeeeee...
//.lll..iii.innnnnnnnnn..eeeeeeeeee..
//.lll..iii.innnn..nnnn.neee...eeee..
//.lll..iii.innnn...nnn.neeeeeeeeee..
//.lll..iii.innn....nnn.neeeeeeeeee..
//.lll..iii.innn....nnn.neeeeeeeeee..
//.lll..iii.innn....nnn.neee....ee...
//.lll..iii.innn....nnn.neeee..eeee..
//.lll..iii.innn....nnn..eeeeeeeeee..
//.lll..iii.innn....nnn...eeeeeeee...
//.lll..iii.innn....nnn....eeeeee....
//...................................
-->
            <template v-for="(i_t, index) in i_t_list">
              <template v-if="i_t[0] === 'item' && index != selecteditem && items[i_t[1]].isInView && items[i_t[1]].latlng_line_left != null  && items[i_t[1]].latlng != items[i_t[1]].latlng_line_left">
              <l-polyline
                :lat-lngs="[items[i_t[1]].latlng, items[i_t[1]].latlng_line_left, items[i_t[1]].latlng_line_right]"
                :color="label_polyline.color" :weight="1">
              </l-polyline>
              </template>
              <template v-if="i_t[0] === 'tweet' && index != selecteditem && tweets[i_t[1]].isInView && tweets[i_t[1]].latlng_line_left != null  && tweets[i_t[1]].latlng != tweets[i_t[1]].latlng_line_left">
              <l-polyline
                :lat-lngs="[tweets[i_t[1]].latlng, tweets[i_t[1]].latlng_line_left, tweets[i_t[1]].latlng_line_right]"
                :color="label_polyline_tweet.color" :weight="1">
              </l-polyline>
              </template>
            </template>
<!--
//...........................................................
//...............................tttt........................
//................................ttt........................
//.......................ee.......ttt........e...............
//.mmmmmmmmmmmmmmm.....eeeeeee.eettttttt..eeeeeee...rrrrrrr..
//.mmmmmmmmmmmmmmmm...eeeeeeee.eettttttt.eeeeeeeee..rrrrrrr..
//.mmmmmmmmmmmmmmmm..meeeeeeeeeeetttttttteeeeeeeee..rrrrrrr..
//.mmmm..mmmmm..mmmm.meee..eeee...ttt...teee...eeee.rrrrr....
//.mmm...mmmm...mmmmmmeeeeeeeeee..ttt...teeeeeeeeee.rrrr.....
//.mmm...mmmm...mmmmmmeeeeeeeeee..ttt...teeeeeeeeee.rrr......
//.mmm...mmmm...mmmmmmeeeeeeeeee..ttt...teeeeeeeeee.rrr......
//.mmm...mmmm...mmmmmmee....eee...ttt...tee....eee..rrr......
//.mmm...mmmm...mmmm.meee..eeeee..ttt..tteee..eeeee.rrr......
//.mmm...mmmm...mmmm.meeeeeeeee...ttttttteeeeeeeee..rrr......
//.mmm...mmmm...mmmm..eeeeeeeee...tttttt.eeeeeeeee..rrr......
//.mmm...mmmm...mmmm...eeeeee......ttttt...eeeee....rrr......
//...........................................................
-->
            <template v-if="selecteditem != 99999">
              <template v-if="meter > 50">
                <l-marker :lat-lng="meter_yx" :icon="meter_icon" :zIndexOffset="888"></l-marker>
              </template>
              <l-polyline
                :lat-lngs="[face.latlng, (i_t_list[selecteditem][0] === 'item' ? items[i_t_list[selecteditem][1]].latlng : tweets[i_t_list[selecteditem][1]].latlng)]" :weight="0.5" :zIndexOffset="777" color="#444">
              </l-polyline>
            </template>

<!--
//..............................................................................
//.lll............................................tiii..........................
//.lll.....................................tttt...tiii..........................
//.lll.....................................tttt...tiii..........................
//.lll.......o..........c...........a......tttt.............o...................
//.lll...oooooooo....ccccccc....aaaaaaaa.aatttttt.tiii...ooooooo...onnnnnnnnn...
//.lll..oooooooooo..ccccccccc..caaaaaaaaaaatttttt.tiii..ooooooooo..onnnnnnnnnn..
//.lll..oooooooooo..cccccccccc.caaaaaaaaaaatttttt.tiii.ioooooooooo.onnnnnnnnnn..
//.lll.loooo...oooooccc...cccc.caa...aaaa..tttt...tiii.iooo...oooo.onnnn..nnnn..
//.lll.looo....oooooccc....c.....aaaaaaaa..tttt...tiiiiioo....oooo.onnn...nnnn..
//.lll.looo....oooooccc........caaaaaaaaa..tttt...tiiiiioo.....ooo.onnn...nnnn..
//.lll.looo....oooooccc........caaaaaaaaa..tttt...tiiiiioo.....ooo.onnn...nnnn..
//.lll.looo....oooooccc....ccc.caaa..aaaa..tttt...tiiiiioo....oooo.onnn...nnnn..
//.lll.loooo..oooooocccc..cccc.caa..aaaaa..tttt.t.tiii.iooo..ooooo.onnn...nnnn..
//.lll..oooooooooo..cccccccccc.caaaaaaaaa..ttttttttiii.iooooooooo..onnn...nnnn..
//.lll...oooooooo....cccccccc..caaaaaaaaa...tttttttiii..ooooooooo..onnn...nnnn..
//.lll....oooooo......cccccc....aaaaa.aaa...tttttttiii...oooooo....onnn...nnnn..
//..............................................................................
-->
            <template v-if="selecteditem == 99999 || i_t_list.length == 1">
            <l-marker :lat-lng="location_yx" :icon="location_label_icon" :zIndexOffset="999"></l-marker>
            </template>

<!--
//.............................................
//....ffffff...................................
//...fffffff...................................
//...ffff.ff...................................
//...ffff........a..........c..........ee......
//.ffffffff..aaaaaaaa....ccccccc.....eeeeeee...
//.ffffffff.aaaaaaaaaa..ccccccccc...eeeeeeee...
//.ffffffff.aaaaaaaaaa..cccccccccc.eeeeeeeeee..
//...ffff...aaaa...aaa.cccc...cccc.eeee..eeee..
//...ffff.....aaaaaaaa.cccc....c..ceeeeeeeeee..
//...ffff....aaaaaaaaa.cccc.......ceeeeeeeeee..
//...ffff...aaaaaaaaaa.cccc.......ceeeeeeeeee..
//...ffff...aaaa..aaaa.cccc....cccceee....eee..
//...ffff...aaaa..aaaa.ccccc..cccc.eeee..eeee..
//...ffff...aaaaaaaaaa..cccccccccc.eeeeeeeeee..
//...ffff...aaaaaaaaaa...cccccccc...eeeeeeeee..
//...ffff.....aaaa.aaa....cccccc.....eeeeee....
//.............................................
-->
            <l-marker @click="selectLocation()" :lat-lng="face.latlng" :icon="face_icon" :zIndexOffset="999"></l-marker>

<!--
//.......................................................
//.............iiii.....................llll.............
//.............iiii.....................lll..............
//.............iiii.....................lll..............
//......c........................c......lll......ee......
//...ccccccc...iiii.rrrrrrrr..ccccccc...lll...eeeeeee....
//..ccccccccc..iiii.rrrrrrrr.ccccccccc..lll..eeeeeeeee...
//..cccccccccc.iii..rrrrrrrr.cccccccccc.lll..eeeeeeeeee..
//.cccc...cccc.iii..rrrrr...cccc...cccc.lll.leee...eeee..
//.cccc....c...iii..rrrr....cccc....c...lll.leeeeeeeeee..
//.cccc........iii..rrr.....cccc........lll.leeeeeeeeee..
//.cccc........iii..rrr.....cccc........lll.leeeeeeeeee..
//.cccc....ccc.iii..rrr.....cccc....ccc.lll.leee....ee...
//.ccccc..cccc.iii..rrr.....ccccc..cccc.lll.leeee..eeee..
//..cccccccccc.iii..rrr......cccccccccc.lll..eeeeeeeeee..
//...cccccccc..iiii.rrrr......cccccccc..llll..eeeeeeee...
//....cccccc...iiii.rrrr.......cccccc...llll...eeeeee....
//.......................................................
-->
            <template v-if="selecteditem != 99999">

              <template v-if="zoom < 15">
                <l-circle
                  :lat-lng="face.latlng"
                  :radius="circles_radius"
                  :fillColor="circles_color3"
                  :stroke="false"
                  :fillOpacity="0.1"
                ></l-circle>
                <template v-for="(i_t, index) in i_t_list">
                  <template v-if="index == selecteditem && i_t[0] === 'item' && items[i_t[1]].isInView">
                    <l-circle
                      :lat-lng="items[i_t[1]].latlng"
                      :radius="circles_radius"
                      :fillColor="circles_color3"
                      :stroke="false"
                      :fillOpacity="0.1"
                    ></l-circle>
                  </template>
                  <template v-if="index == selecteditem && i_t[0] === 'tweet' && tweets[i_t[1]].isInView">
                    <l-circle
                      :lat-lng="tweets[i_t[1]].latlng"
                      :radius="circles_radius"
                      :fillColor="circles_color3"
                      :stroke="false"
                      :fillOpacity="0.1"
                    ></l-circle>
                  </template>
                </template>
              </template>

            </template>


          </l-map>

        </div>
<!--
//......................................................
//...................................ttt................
//...................................ttt................
//..............o....................ttt........e.......
//.rrrrrrrr..oooooooo...uuu....uuuuuutttttt..eeeeeee....
//.rrrrrrrr.oooooooooo..uuu....uuuuuutttttt.eeeeeeeee...
//.rrrrrrrrroooooooooo..uuu....uuu.uuttttttteeeeeeeeee..
//.rrrrr...rooo...oooo..uuu....uuu...ttt...teee...eeee..
//.rrrr....rooo....oooo.uuu....uuu...ttt...teeeeeeeeee..
//.rrr.....roo.....oooo.uuu....uuu...ttt...teeeeeeeeee..
//.rrr.....roo.....oooo.uuu....uuu...ttt...teeeeeeeeee..
//.rrr.....rooo....oooo.uuu...uuuu...ttt...teee....ee...
//.rrr.....roooo..oooo..uuuu.uuuuu...tttt.tteeee..eeee..
//.rrr......oooooooooo..uuuuuuuuuu...tttttt.eeeeeeeeee..
//.rrr......ooooooooo...uuuuuuuuuuu..tttttt.eeeeeeeee...
//.rrr........oooooo.....uuuuu.uuuu...ttttt...eeeeee....
//......................................................
-->
          <a v-if="selecteditem != 99999 && maplink_on" class="is-size-7 has-text-grey-darker" v-bind:href="'http://maps.apple.com?saddr='+face.lat+','+face.lng+'&daddr=' + (i_t_list[selecteditem][0] == 'item' ? items[i_t_list[selecteditem][1]].lat : tweets[i_t_list[selecteditem][1]].lat) + ',' + (i_t_list[selecteditem][0] == 'item' ? items[i_t_list[selecteditem][1]].lng : tweets[i_t_list[selecteditem][1]].lng) +'&center='+(i_t_list[selecteditem][0] == 'item' ? items[i_t_list[selecteditem][1]].lat : tweets[i_t_list[selecteditem][1]].lat)+','+(i_t_list[selecteditem][0] == 'item' ? items[i_t_list[selecteditem][1]].lng : tweets[i_t_list[selecteditem][1]].lng)">
            <div class="maplink-button is-flex is-align-items-center px-4 py-0 has-background-white c-height-3 c-border-radius-6 has-text-centered" :style="{top: height - 281 + 'px', left: '20px'}">
              <ion-icon name="arrow-redo-sharp" class="is-size-6 pr-1 has-text-link"></ion-icon>
              Apple マップ
            </div>
          </a>
          
          <a v-if="selecteditem != 99999 && maplink_on" class="is-size-7 has-text-grey-darker" v-bind:href="'yjmap://route/?lat0='+face.lat+'&lon0='+face.lng+'&to='+encodeURI((i_t_list[selecteditem][0] == 'item' ? items[i_t_list[selecteditem][1]].name : tweets[i_t_list[selecteditem][1]].name))+'&lat1='+(i_t_list[selecteditem][0] == 'item' ? items[i_t_list[selecteditem][1]].lat : tweets[i_t_list[selecteditem][1]].lat)+'&lon1='+(i_t_list[selecteditem][0] == 'item' ? items[i_t_list[selecteditem][1]].lng : tweets[i_t_list[selecteditem][1]].lng)+'&trans=walk'">
            <div class="maplink-button is-flex is-align-items-center px-4 py-0 has-background-white c-height-3 c-border-radius-6 has-text-centered" :style="{top: height - 246 + 'px', left: '45px'}">
              <ion-icon name="arrow-redo-sharp" class="is-size-6 pr-1 has-text-link"></ion-icon>
              Yahoo! マップ
            </div>
          </a>
          
          <a v-if="selecteditem != 99999 && maplink_on" class="is-size-7 has-text-grey-darker" v-bind:href="'http://maps.google.com?saddr='+face.lat+','+face.lng+'&daddr='+(i_t_list[selecteditem][0] == 'item' ? items[i_t_list[selecteditem][1]].lat : tweets[i_t_list[selecteditem][1]].lat)+','+(i_t_list[selecteditem][0] == 'item' ? items[i_t_list[selecteditem][1]].lng : tweets[i_t_list[selecteditem][1]].lng)+'&center='+(i_t_list[selecteditem][0] == 'item' ? items[i_t_list[selecteditem][1]].lat : tweets[i_t_list[selecteditem][1]].lat)+','+(i_t_list[selecteditem][0] == 'item' ? items[i_t_list[selecteditem][1]].lng : tweets[i_t_list[selecteditem][1]].lng)+'&directionsmode=walking&zoom=17'">
            <div class="maplink-button is-flex is-align-items-center px-4 py-0 has-background-white c-height-3 c-border-radius-6 has-text-centered" :style="{top: height - 211 + 'px', left: '70px'}">
              <ion-icon name="arrow-redo-sharp" class="is-size-6 pr-1 has-text-link"></ion-icon>
              Google Maps
            </div>
          </a>

          <div v-if="selecteditem != 99999" @click="maplink()" class="ml-4 p-0 maplink-circle is-flex is-align-items-center is-justify-content-center is-clickable has-background-white" :style="{top: height - 215 + 'px'}">
            <ion-icon name="close-outline" v-if="maplink_on" class="is-size-3"></ion-icon>
            <div class="has-text-weight-semibold c-is-size-8" v-if="!maplink_on">ルート</div>
          </div>

<!--
//.............................................................................
//.bbb.........................................................................
//.bbb......................tttt....tttt.......................................
//.bbb.......................ttt.....ttt.......................................
//.bbb.......................ttt.....ttt........o..............................
//.bbbbbbbbbb..buuu....uuuuuttttttttttttttt..ooooooo...onnnnnnnnn....sssssss...
//.bbbbbbbbbbb.buuu....uuuuuttttttttttttttt.ooooooooo..onnnnnnnnnn..sssssssss..
//.bbbbbbbbbbb.buuu....uuuuuttttttttttttttttoooooooooo.onnnnnnnnnn.nsssssssss..
//.bbbb...bbbb.buuu....uuu...ttt.....ttt...tooo...oooo.onnnn..nnnn.nsss..ssss..
//.bbbb....bbb.buuu....uuu...ttt.....ttt...too.....ooo.onnn...nnnn.nsssssss....
//.bbb.....bbbbbuuu....uuu...ttt.....ttt...too.....ooo.onnn...nnnn..ssssssss...
//.bbb.....bbbbbuuu...uuuu...ttt.....ttt...too.....ooo.onnn...nnnn...ssssssss..
//.bbbb...bbbb.buuu...uuuu...ttt.....ttt...tooo...oooo.onnn...nnnn.nsss..ssss..
//.bbbbb.bbbbb.buuuuuuuuuu...ttt..t..ttt..ttoooo..oooo.onnn...nnnn.nsss..ssss..
//.bbbbbbbbbbb..uuuuuuuuuu...tttttt..tttttttoooooooooo.onnn...nnnn.nsssssssss..
//.bbbbbbbbbb...uuuuuuuuuu...tttttt..tttttt.ooooooooo..onnn...nnnn..sssssssss..
//.bbbbbbbbb.....uuuuu.uuu....ttttt...ttttt...ooooo....onnn...nnnn...ssssss....
//.............................................................................
-->
        <div v-if="selecteditem != 99999" class="map-buttons has-text-right is-flex is-align-items-center is-justify-content-flex-end" style="margin-left: auto; height: 40px; width: 200px;" :style="{top: height - 220 + 'px'}">
          <button @click="flyto('', $event)" class="mr-2 button is-small is-rounded is-white">拡大</button>
          
          <button @click="fitBounds()" class="button is-small is-rounded is-white map-button-shadow">最初の位置</button>
        </div>

      </div>
<!--
//.........................................................
//.........................................dddd............
//.........................................dddd............
//.........................................dddd............
//......c...........a......................dddd............
//...ccccccc....aaaaaaaa..rrrrrrrrr..dddddddddd..sssssss...
//..ccccccccc..aaaaaaaaaa.rrrrrrrrr.ddddddddddd.sssssssss..
//..cccccccccc.aaaaaaaaaa.rrrrrrrrrdddddddddddd.sssssssss..
//.cccc...cccc.aaa...aaaa.rrrrr....dddd...ddddd.ssss..sss..
//.cccc....c.....aaaaaaaa.rrrr.....ddd.....dddd.sssssss....
//.cccc........aaaaaaaaaa.rrrr.....ddd.....dddd.sssssssss..
//.cccc........aaaaaaaaaa.rrrr.....ddd.....dddd...sssssss..
//.cccc....ccc.aaaa..aaaa.rrrr.....dddd...ddddd.sss..ssss..
//.ccccc..cccc.aaa..aaaaa.rrrr.....ddddd.dddddddssss..sss..
//..cccccccccc.aaaaaaaaaa.rrrr.....dddddddddddd.sssssssss..
//...cccccccc..aaaaaaaaaa.rrrr......ddddddddddd.sssssssss..
//....cccccc....aaaaa.aaa.rrrr.......dddddddddd...ssssss...
//.........................................................
-->
      <div class="column" v-if="!is_pc && type != 'item'">
        <cards-box :filter="filter" :items="items" :tweets="tweets" :selecteditem="selecteditem" :listType="type" :i_t_list="i_t_list" :update_date="update_date" />
      </div>
    </div>
    <div class="columns is-gapless">
<!--
//..............................
//.lll..iii.....................
//.lll..iii.............tttt....
//.lll..iii.............tttt....
//.lll..................tttt....
//.lll..iii...sssssss.sstttttt..
//.lll..iii..ssssssssssstttttt..
//.lll..iii.issssssssssstttttt..
//.lll..iii.isss..ssss..tttt....
//.lll..iii.isssssss....tttt....
//.lll..iii..ssssssss...tttt....
//.lll..iii...ssssssss..tttt....
//.lll..iii.isss..ssss..tttt....
//.lll..iii.isss..ssss..tttt.t..
//.lll..iii.isssssssss..tttttt..
//.lll..iii..sssssssss...ttttt..
//.lll..iii...ssssss.....ttttt..
//..............................
--> 
      <div class="column is-hidden-mobile">
        <template v-if="type != 'item'">
        <list-box :filter="filter" :items="items" :tweets="tweets" :selecteditem="selecteditem" :listType="type" :i_t_list="i_t_list" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
/* global L */
import Vue from 'vue/dist/vue.esm'
import {LMap, LTileLayer, LImageOverlay, LCircle, LMarker, LPolyline, LTooltip} from 'vue2-leaflet'
import Cards from "./card.vue"
import List from "./list.vue"

export default {
  replace: true,
  props: {
    face: Object,
    filter: Object,
    height: Number,
    is_pc: Boolean,
    items: Array,
    i_t_list: Array,
    landmarks: Array,
    locationname: String,
    mapbox_at: String,
    mapbox_path: String,
    padding: Array,
    position: Object,
    raillines: Array,
    selecteditem: Number,
    update_date: String,
    tweets: Array,
    type: String
  },
  components: {
    LMap,
    LTileLayer,
    LImageOverlay,
    LCircle,
    LMarker,
    LPolyline,
    LTooltip,
    "cards-box": Cards,
    "list-box": List
  },
  data: function () {
    return {
      url_l: 'https://api.mapbox.com/styles/v1/' + this.mapbox_path + '/tiles/256/{z}/{x}/{y}@2x?access_token=' + this.mapbox_at,
      crs: L.CRS.Simple,
      maxBounds: new L.LatLngBounds([this.position['left_top_lat'], this.position['left_top_lng']], [this.position['right_bottom_lat'], this.position['right_bottom_lng']]),
      zoom: 1,
      move: false,
      center: L.latLng(this.face.lat, this.face.lng),
      mapOptions: {
        zoomControl: false,
        attributionControl: true
      },
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a>, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      dot_icon: L.divIcon({className: 'my_marker', html: '<span class="dot"></span>', iconSize: [10, 10]}),
      dot_tweet_icon: L.divIcon({className: 'my_marker', html: '<span class="tweet_dot"></span>', iconSize: [10, 10]}),
      landmark_icons: [],
      polyline: {
        type: 'polyline'
      },
      label_polyline: {
        type: 'polyline',
        color: '#333'
      },
      label_polyline_tweet: {
        type: 'polyline',
        color: '#333'
      },
      collisionAreas: [],
      maplink_on: false,
      items_org: [],
      tweets_org: [],
      circles_color: '#E02020',
      circles_color2: '#485fc7',
      circles_color3: '#555'
    }
  },
  computed: {
    face_icon: function () {
      if(this.move != undefined && this.move) return L.divIcon({className: 'face opacity02', html: '', iconSize: [38, 38]})
      return L.divIcon({className: 'face', html: '', iconSize: [38, 38]})
    },
    location_yx: function () {
      let lat = this.face.lat
      let lng = this.face.lng
      return L.latLng(lat, lng)
    },
    active_icon: function () {
      return L.divIcon({className: 'my_marker selected', html: '<a href="' + this.filter_path(this.items[this.i_t_list[this.selecteditem][1]].id) + '" class="dot"></a>', iconAnchor: [28, 28], iconSize: [56, 56]})
    },
    active_tweet_icon: function () {
      let my_class = "selected"
      if(this.i_t_list[this.selecteditem][0] !== 'item') {
        my_class = "selected_tweet"
      }
      return L.divIcon({className: 'my_marker ' + my_class, html: '<a href="' + this.tweets[this.i_t_list[this.selecteditem][1]].url + '" target="_blank" rel="noopener" class="dot"></a>', iconAnchor: [28, 28], iconSize: [56, 56]})
    },
    rls: function () {
      let rls = []
      let name = this.raillines[0].name
      let points = []
      for(let i = 0; i < this.raillines.length; i++) {
        if(this.raillines[i].name != name && points.length > 0) {
          rls.push(points)
          points = []
          name = this.raillines[i].name
        }
        points.push(this.raillines[i].latlng)
      }
      rls.push(points)
      return rls
    },
//..............................................................................
//.lll............................................tiii..........................
//.lll.....................................tttt...tiii..........................
//.lll.....................................tttt...tiii..........................
//.lll.......o..........c...........a......tttt.............o...................
//.lll...oooooooo....ccccccc....aaaaaaaa.aatttttt.tiii...ooooooo...onnnnnnnnn...
//.lll..oooooooooo..ccccccccc..caaaaaaaaaaatttttt.tiii..ooooooooo..onnnnnnnnnn..
//.lll..oooooooooo..cccccccccc.caaaaaaaaaaatttttt.tiii.ioooooooooo.onnnnnnnnnn..
//.lll.loooo...oooooccc...cccc.caa...aaaa..tttt...tiii.iooo...oooo.onnnn..nnnn..
//.lll.looo....oooooccc....c.....aaaaaaaa..tttt...tiiiiioo....oooo.onnn...nnnn..
//.lll.looo....oooooccc........caaaaaaaaa..tttt...tiiiiioo.....ooo.onnn...nnnn..
//.lll.looo....oooooccc........caaaaaaaaa..tttt...tiiiiioo.....ooo.onnn...nnnn..
//.lll.looo....oooooccc....ccc.caaa..aaaa..tttt...tiiiiioo....oooo.onnn...nnnn..
//.lll.loooo..oooooocccc..cccc.caa..aaaaa..tttt.t.tiii.iooo..ooooo.onnn...nnnn..
//.lll..oooooooooo..cccccccccc.caaaaaaaaa..ttttttttiii.iooooooooo..onnn...nnnn..
//.lll...oooooooo....cccccccc..caaaaaaaaa...tttttttiii..ooooooooo..onnn...nnnn..
//.lll....oooooo......cccccc....aaaaa.aaa...tttttttiii...oooooo....onnn...nnnn..
//..............................................................................
    location_label: function () {
      let item = this.i_t_list[this.selecteditem][0] === "item" ? this.items[this.i_t_list[this.selecteditem][1]] : this.tweets[this.i_t_list[this.selecteditem][1]]
      let text = this.i_t_list[this.selecteditem][0] === "item" ? this.items[this.i_t_list[this.selecteditem][1]].name : this.tweets[this.i_t_list[this.selecteditem][1]].place_name
      let width = this.getTextWidth(text, 14, 12, 600)
      let anchor_lng = width / 2
      if(item.lat > this.face.lat) {
        let location_label_icon_class = 'location_label_top'
        return L.divIcon({className: location_label_icon_class, html: '<div class="location_label_text has-text-weight-semibold has-text-centered">' + text + '</div>', iconAnchor: [anchor_lng, 40], iconSize: [width, 25]})

      } else {
        let location_label_icon_class = 'location_label_bottom'
        return L.divIcon({className: location_label_icon_class, html: '<div class="location_label_text has-text-weight-semibold has-text-centered">' + text + '</div>', iconAnchor: [anchor_lng, -14], iconSize: [width, 25]})
      }
    },
    location_label_icon: function () {
      let text = "仮の現在地： " + this.locationname + " "
      let width = this.getTextWidth(text, 10, 12, 600)
      let opacity02 = (this.move != undefined && this.move) ? ' opacity02' : ''
      return L.divIcon({className: 'location_label_icon' + opacity02, html: '<div class="location_label_text has-text-weight-semibold has-text-centered">' + text + '</div>', iconAnchor: [0, -18], iconSize: [width, 30]})
    },
//...........................................................
//...............................tttt........................
//................................ttt........................
//.......................ee.......ttt........e...............
//.mmmmmmmmmmmmmmm.....eeeeeee.eettttttt..eeeeeee...rrrrrrr..
//.mmmmmmmmmmmmmmmm...eeeeeeee.eettttttt.eeeeeeeee..rrrrrrr..
//.mmmmmmmmmmmmmmmm..meeeeeeeeeeetttttttteeeeeeeee..rrrrrrr..
//.mmmm..mmmmm..mmmm.meee..eeee...ttt...teee...eeee.rrrrr....
//.mmm...mmmm...mmmmmmeeeeeeeeee..ttt...teeeeeeeeee.rrrr.....
//.mmm...mmmm...mmmmmmeeeeeeeeee..ttt...teeeeeeeeee.rrr......
//.mmm...mmmm...mmmmmmeeeeeeeeee..ttt...teeeeeeeeee.rrr......
//.mmm...mmmm...mmmmmmee....eee...ttt...tee....eee..rrr......
//.mmm...mmmm...mmmm.meee..eeeee..ttt..tteee..eeeee.rrr......
//.mmm...mmmm...mmmm.meeeeeeeee...ttttttteeeeeeeee..rrr......
//.mmm...mmmm...mmmm..eeeeeeeee...tttttt.eeeeeeeee..rrr......
//.mmm...mmmm...mmmm...eeeeee......ttttt...eeeee....rrr......
//...........................................................
    meter: function () {
      function radians(deg) {
          return deg * Math.PI / 180
      }
      let latlng = this.i_t_list[this.selecteditem][0] === "item" ? this.items[this.i_t_list[this.selecteditem][1]] : this.tweets[this.i_t_list[this.selecteditem][1]]
      let lat1 = this.face.lat
      let lng1 = this.face.lng
      let d2 =6378137.0 * Math.acos(Math.cos(radians(lat1))* 
        Math.cos(radians(latlng.lat))*
        Math.cos(radians(latlng.lng)-radians(lng1))+
        Math.sin(radians(lat1))*
        Math.sin(radians(latlng.lat)))
      return Math.round(d2)
    },
    meter_yx: function () {
      let yx = this.i_t_list[this.selecteditem][0] === "item" ? this.items[this.i_t_list[this.selecteditem][1]].latlng : this.tweets[this.i_t_list[this.selecteditem][1]].latlng
      let lat = this.face.latlng.lat + ((yx.lat - this.face.latlng.lat) / 2)
      let lng = this.face.latlng.lng + ((yx.lng - this.face.latlng.lng) / 2)
      return L.latLng(lat, lng)
    },
    meter_icon: function () {
      function radians(deg) {
          return deg * Math.PI / 180
      }
      let text = ""
      let latlng = this.i_t_list[this.selecteditem][0] === "item" ? this.items[this.i_t_list[this.selecteditem][1]] : this.tweets[this.i_t_list[this.selecteditem][1]]
      let lat1 = this.face.lat
      let lng1 = this.face.lng
      let d =6378137.0 * Math.acos(Math.cos(radians(lat1))* 
        Math.cos(radians(this.meter_yx.lat))*
        Math.cos(radians(this.meter_yx.lng)-radians(lng1))+
        Math.sin(radians(lat1))*
        Math.sin(radians(this.meter_yx.lat)))
      //if(this.zoom > 13) text += Math.round(d).toLocaleString() + 'm' + " / "
      text += this.meter.toLocaleString() + 'm'
      let width = this.getTextWidth(text, 6, 12)
      return L.divIcon({className: 'is-size-7 c-line-height-6 c-font-feature-settings-palt-1 has-background-grey-dark has-text-white has-text-weight-semibold has-text-centered c-border-radius-10', html: text, iconSize: [width, 16]})
    },
    circles_radius: function() {
      function radians(deg) {
          return deg * Math.PI / 180
      }
      let latlng = this.i_t_list[this.selecteditem][0] === "item" ? this.items[this.i_t_list[this.selecteditem][1]] : this.tweets[this.i_t_list[this.selecteditem][1]]
      let lat1 = this.face.lat
      let lat2 = latlng.lat
      let lng1 = this.face.lng
      let lng2 = latlng.lng
      let d =6378137.0 * Math.acos(Math.cos(radians(lat1))* 
        Math.cos(radians(lat2))*
        Math.cos(radians(lng2)-radians(lng1))+
        Math.sin(radians(lat1))*
        Math.sin(radians(lat2)))
      return Math.round(d) / 4
    },
//....................................................
//...................................llll.............
//...................................lll..............
//...................................lll..............
//.................c..........a......lll......ee......
//...sssssss....ccccccc....aaaaaaaa..lll....eeeeeee...
//..sssssssss..ccccccccc..aaaaaaaaa..lll...eeeeeeee...
//..sssssssss.cccccccccccaaaaaaaaaaa.lll..eeeeeeeeee..
//..ssss.ssss.cccc...ccccaaaa...aaaa.lll..eeee..eeee..
//..sssssss...cccc...cc.....aaaaaaaa.lll.leeeeeeeeee..
//..sssssssss.ccc.........aaaaaaaaaa.lll.leeeeeeeeee..
//...ssssssss.cccc........aaaaaaaaaa.lll.leeeeeeeeee..
//..sss..ssssscccc...ccccaaaa...aaaa.lll.leee....eee..
//.ssss...ssssccccc..ccccaaaa..aaaaa.lll..eeee..eeee..
//.ssssssssss..ccccccccccaaaaaaaaaaa.lll..eeeeeeeeee..
//..sssssssss..ccccccccc..aaaaaaaaaa.llll..eeeeeeeee..
//...ssssss......cccccc....aaaaaaaaa.llll...eeeeee....
//....................................................
    scale_face_yx: function () {
      let yx = this.i_t_list[this.selecteditem][0] === "item" ? this.items[this.i_t_list[this.selecteditem][1]].latlng : this.tweets[this.i_t_list[this.selecteditem][1]].latlng
      let lat = this.face.latlng.lat + ((yx.lat - this.face.latlng.lat) / 4)
      let lng = this.face.latlng.lng + ((yx.lng - this.face.latlng.lng) / 4)
      return L.latLng(lat, lng)
    },
    scale_marker_yx: function () {
      let yx = this.i_t_list[this.selecteditem][0] === "item" ? this.items[this.i_t_list[this.selecteditem][1]].latlng : this.tweets[this.i_t_list[this.selecteditem][1]].latlng
      let lat = this.face.latlng.lat + (((yx.lat - this.face.latlng.lat) / 4) * 3)
      let lng = this.face.latlng.lng + (((yx.lng - this.face.latlng.lng) / 4) * 3)
      return L.latLng(lat, lng)
    },
    scale_face_icon: function () {
      function radians(deg) {
          return deg * Math.PI / 180
      }
      let latlng = this.i_t_list[this.selecteditem][0] === "item" ? this.items[this.i_t_list[this.selecteditem][1]] : this.tweets[this.i_t_list[this.selecteditem][1]]
      let lat1 = this.face.lat
      let lat2 = this.scale_face_yx.lat
      let lng1 = this.face.lng
      let lng2 = this.scale_face_yx.lng
      let d =6378137.0 * Math.acos(Math.cos(radians(lat1))* 
        Math.cos(radians(lat2))*
        Math.cos(radians(lng2)-radians(lng1))+
        Math.sin(radians(lat1))*
        Math.sin(radians(lat2)))
      let text = Math.round(d).toLocaleString() + 'm'
      let width = this.getTextWidth(text, 6, 12)
      return L.divIcon({className: 'is-size-7 c-line-height-6 c-font-feature-settings-palt-1 has-background-grey-light has-text-white has-text-weight-semibold has-text-centered c-border-radius-10', html: text, iconSize: [width, 16]})
    },
    scale_marker_icon: function () {
      function radians(deg) {
          return deg * Math.PI / 180
      }
      let latlng = this.i_t_list[this.selecteditem][0] === "item" ? this.items[this.i_t_list[this.selecteditem][1]] : this.tweets[this.i_t_list[this.selecteditem][1]]
      let lat1 = this.face.lat
      let lat2 = this.scale_marker_yx.lat
      let lng1 = this.face.lng
      let lng2 = this.scale_marker_yx.lng
      let d =6378137.0 * Math.acos(Math.cos(radians(lat1))* 
        Math.cos(radians(lat2))*
        Math.cos(radians(lng2)-radians(lng1))+
        Math.sin(radians(lat1))*
        Math.sin(radians(lat2)))
      let text = Math.round(d).toLocaleString() + 'm'
      let width = this.getTextWidth(text, 6, 12)
      return L.divIcon({className: 'is-size-7 c-line-height-6 c-font-feature-settings-palt-1 has-background-grey-light has-text-white has-text-weight-semibold has-text-centered c-border-radius-10', html: text, iconSize: [width, 16]})
    },
  },
  watch: {
    selecteditem: function () {
      //console.log('watch selecteditem')
      //this.flyTo()
      this.fitBounds()
    }
  },
  methods: {
    moveStart: function () {
      this.move = true
    },
    moveEnd: function () {
      this.move = false
    },
    zoomUpdated: function () {
      //console.log('zoomUpdated')
      let map = this.$refs.map.mapObject
      this.zoom = map.getZoom()
      this.items = this.items_org
      this.tweets = this.tweets_org
      this.textCollisionDetection()
    },
//...................................................................................
//....CCCCCCCCC...............llll.llll.iii............iiii..........................
//...CCCCCCCCCCC..............lll..lll..iii............iiii..........................
//..CCCCCCCCCCCC..............lll..lll..iii............iiii..........................
//..CCCCC...CCCCC......o......lll..lll...........................o...................
//..CCCC.....CCCC...ooooooo...lll..lll..iii...sssssss..iiii...ooooooo...nnnnnnnnnn...
//..CCC......CC....ooooooooo..lll..lll..iii..sssssssss.iiii..ooooooooo..nnnnnnnnnnn..
//.CCCC...........ooooooooooo.lll..lll..iii..sssssssss.iiii.ooooooooooo.nnnnnnnnnnn..
//.CCCC...........oooo...oooo.lll..lll..iii..ssss.ssss.iiii.oooo...oooo.nnnnn..nnnn..
//.CCCC..........Cooo....oooo.lll..lll..iii..sssssss...iiii.ooo.....ooo.nnnn...nnnn..
//.CCCC..........Cooo.....ooo.lll..lll..iii..sssssssss.iiii.ooo.....ooo.nnnn...nnnn..
//..CCCC.....CCCCCooo.....ooo.lll..lll..iii...ssssssss.iiii.ooo.....ooo.nnnn...nnnn..
//..CCCC.....CCCCCooo....oooo.lll..lll..iii..sss..sssssiiii.oooo...oooo.nnnn...nnnn..
//..CCCCCC.CCCCCC.oooo..ooooo.lll..lll..iii.ssss...ssssiiii.ooooo..oooo.nnnn...nnnn..
//...CCCCCCCCCCC..oooooooooo..lll..lll..iii.ssssssssss.iiii.ooooooooooo.nnnn...nnnn..
//....CCCCCCCCC....ooooooooo..llll.llll.iii..sssssssss.iiii..ooooooooo..nnnn...nnnn..
//.....CCCCCCC......oooooo....llll.llll.iii...ssssss...iiii....ooooo....nnnn...nnnn..
//...................................................................................
    textCollisionDetection: function () {
      //console.log('textCollisionDetection')
      let _self = this
      if(typeof(this.$refs.map) == 'undefined') {
        setTimeout(_self.textCollisionDetection, 2000)
        return false
      } else {
        let jj = 0
        let countIsInView = 0
        for(let elem of this.items) {
          this.items[jj].isInView = this.isMarkerOutOfView(elem)
          if(this.items[jj].isInView) countIsInView++
          jj++
        }
        if(countIsInView ==0) return false;
        //console.log(this.items)
        jj = 0
        for(let elem of this.tweets) {
          this.tweets[jj].isInView = this.isMarkerOutOfView(elem)
          jj++
        }
        this.collisionAreas = this.getCollisionAreas()
        let j = 0
        for(let elem of this.items) {
          //if(elem.isInView) {
            let XY = this.getXY(elem.lat, elem.lng)
            let elemArea = {
              x1: XY.x - 10,
              y1: XY.y - 16,
              x2: XY.x + this.getTextWidth(elem.name, 8, 0.625, 600, 'rem') + 10,
              y2: XY.y + 10
            }
            //
            let i = 0
            let myNum = 0
            for(let area of this.collisionAreas) {
              if(area.type == 'item') {
                if(area.id == elem.id) {
                  myNum = i
                }
              }
              i++
            }
            // 
            let position_yx = {}
            if(this.selecteditem != 99999 && this.i_t_list[this.selecteditem][0] == 'item' && elem.id == this.items[this.i_t_list[this.selecteditem][1]].id) {
            } else {
              position_yx = this.recursiveCollisionDetection(elemArea, myNum)
              this.collisionAreas[myNum].y1 += position_yx.y
              this.collisionAreas[myNum].y2 += position_yx.y
              this.collisionAreas[myNum].x1 += position_yx.x
              this.collisionAreas[myNum].x2 += position_yx.x
            }
            this.items[j].latlng2 = this.getLatLng(
              this.collisionAreas[myNum].x1, 
              this.collisionAreas[myNum].y1 + ((this.collisionAreas[myNum].y2 - this.collisionAreas[myNum].y1) / 2)
            )
            this.items[j].latlng_line_left = this.getLatLng(
              this.collisionAreas[myNum].x1, 
              this.collisionAreas[myNum].y1 + ((this.collisionAreas[myNum].y2 - this.collisionAreas[myNum].y1) / 2) + 7
            )
            this.items[j].latlng_line_right = this.getLatLng(
              //this.collisionAreas[myNum].x2 + 2, 
              this.collisionAreas[myNum].x1 + elemArea.x2 - elemArea.x1 - 20,
              this.collisionAreas[myNum].y1 + ((this.collisionAreas[myNum].y2 - this.collisionAreas[myNum].y1) / 2) + 7
            )
            j++
          //}
        }
        j = 0
        for(let elem of this.tweets) {
          //if(elem.isInView) {
            let XY = this.getXY(elem.lat, elem.lng)
            let elemArea = {
              x1: XY.x - 10,
              y1: XY.y - 16,
              x2: XY.x + this.getTextWidth(elem.place_name, 8, 0.625, 600, 'rem') + 10,
              y2: XY.y + 10
            }
            // 判定する場所を特定する numを得る
            let i = 0
            let myNum = 0
            for(let area of this.collisionAreas) {
              if(area.id == elem.id && area.type == 'tweet') {
                myNum = i
              }
              i++
            }
            // 
            let position_yx = {}
            if(this.selecteditem != 99999 && this.i_t_list[this.selecteditem][0] == 'tweet' && elem.id == this.tweets[this.i_t_list[this.selecteditem][1]].id) {
            } else {
              position_yx = this.recursiveCollisionDetection(elemArea, myNum)
              this.collisionAreas[myNum].y1 += position_yx.y
              this.collisionAreas[myNum].y2 += position_yx.y
              this.collisionAreas[myNum].x1 += position_yx.x
              this.collisionAreas[myNum].x2 += position_yx.x
            }

            this.tweets[j].latlng2 = this.getLatLng(
              this.collisionAreas[myNum].x1, 
              this.collisionAreas[myNum].y1 + ((this.collisionAreas[myNum].y2 - this.collisionAreas[myNum].y1) / 2)
            )
            this.tweets[j].latlng_line_left = this.getLatLng(
              this.collisionAreas[myNum].x1, 
              this.collisionAreas[myNum].y1 + ((this.collisionAreas[myNum].y2 - this.collisionAreas[myNum].y1) / 2) + 7
            )
            this.tweets[j].latlng_line_right = this.getLatLng(
              //this.collisionAreas[myNum].x2 + 2, 
              this.collisionAreas[myNum].x1 + elemArea.x2 - elemArea.x1 - 20,
              this.collisionAreas[myNum].y1 + ((this.collisionAreas[myNum].y2 - this.collisionAreas[myNum].y1) / 2) + 7
            )
            j++
          //}
        }
      }
    },
    getXY: function (lat, lng) {
      //console.log('getXY')
      let map = this.$refs.map.mapObject
      let zoom = map.getZoom()
      let item = map.latLngToContainerPoint(L.latLng(lat, lng), zoom)
      return item
    },
    getLatLng: function (x, y) {
      //console.log('getLatLng')
      let map = this.$refs.map.mapObject
      let item = map.containerPointToLatLng(L.point(x, y))
      return item
    },
    isMarkerOutOfView: function (elem) {
      return this.$refs.map.mapObject.getBounds().contains(L.latLng(elem.lat, elem.lng))
    },
    getCollisionAreas: function () {
      //console.log('判定する範囲を設定する')
      //console.log('getCollisionAreas')
      let map = this.$refs.map.mapObject
      let zoom = map.getZoom()
      let data = []
      // face
      if(this.isMarkerOutOfView(this.face)) {
        let faceXY = this.getXY(this.face.lat, this.face.lng)
        let location_width = this.getTextWidth("仮の現在地： " + this.locationname + " ", 10, 12, 600)
        data.push({
          x1: faceXY.x - (38 / 2),
          y1: faceXY.y - (38 / 2),
          x2: faceXY.x + (38 / 2),
          y2: faceXY.y + (38 / 2),
          name: 'FACE',
          type: 'face'
        })
        data.push({
          x1: faceXY.x,
          y1: faceXY.y + 18,
          x2: faceXY.x + location_width,
          y2: faceXY.y + 18 + 30,
          name: "仮の現在地",
          type: 'location'
        })
      }
      if(this.selecteditem != 99999) {
        // meter
        //console.log('meter')
        let meter_width = 50
        let meterXY = this.getXY(this.meter_yx.lat, this.meter_yx.lng)
        data.push({
          x1: meterXY.x - (meter_width / 2),
          y1: meterXY.y - (16 / 2),
          x2: meterXY.x + (meter_width / 2),
          y2: meterXY.y + (16 / 2),
          name: "METER",
          type: 'meter'
        })
      }
      // selected flag or dot
      //console.log('selected flag')
      for (let elem of this.items) {
        let XY = this.getXY(elem.lat, elem.lng)
        if(elem.isInView) {
          if(this.selecteditem != 99999 && this.i_t_list[this.selecteditem][0] == 'item' && elem.id == this.items[this.i_t_list[this.selecteditem][1]].id) {
            data.push({
              x1: XY.x - 10,
              y1: XY.y - (56 / 2),
              x2: XY.x + (56 / 2),
              y2: XY.y + 10,
              name: 'FLAG',
              id: elem.id,
              type: 'flag'
            })
            data.push({
              x1: XY.x + 12,
              y1: XY.y - 9,
              x2: XY.x + this.getTextWidth(elem.name, 8, 0.625, 600, 'rem') + 12,
              y2: XY.y + 7,
              name: elem.name,
              id: elem.id,
              type: 'item'
            })
          } else {
            data.push({
              x1: XY.x,
              y1: XY.y - 9,
              x2: XY.x + this.getTextWidth(elem.name, 8, 0.625, 600, 'rem'),
              y2: XY.y + 7,
              name: elem.name,
              id: elem.id,
              type: 'item'
            })
          }
        }
      }
      for (let elem of this.tweets) {
        if(elem.isInView) {
          let XY = this.getXY(elem.lat, elem.lng)
          if(this.selecteditem != 99999 && this.i_t_list[this.selecteditem][0] == 'tweet' && elem.id == this.tweets[this.i_t_list[this.selecteditem][1]].id) {
            data.push({
              x1: XY.x - 10,
              y1: XY.y - (56 / 2),
              x2: XY.x + (56 / 2),
              y2: XY.y + 10,
              name: 'FLAG',
              id: elem.id,
              type: 'flag'
            })
            // selected text
            data.push({
              x1: XY.x + 12,
              y1: XY.y - 9,
              x2: XY.x + this.getTextWidth(elem.place_name, 8, 0.625, 600, 'rem') + 12,
              y2: XY.y + 7,
              name: elem.place_name,
              id: elem.id,
              type: 'tweet'
            })
          } else {
            data.push({
              x1: XY.x,
              y1: XY.y - 9,
              x2: XY.x + this.getTextWidth(elem.place_name, 8, 0.625, 600, 'rem'),
              y2: XY.y + 7,
              name: elem.place_name,
              id: elem.id,
              type: 'tweet'
            })
          }
        }
      }
      // dot
      for (let e of [this.items, this.tweets]) {
        for (let elem of e) {
          if(elem.isInView) {
            let XY = this.getXY(elem.lat, elem.lng)
            data.push({
              x1: XY.x - 5,
              y1: XY.y - (12 / 2),
              x2: XY.x + 5,
              y2: XY.y + (12 / 2),
              name: elem.name,
              id: elem.id,
              type: 'dot'
            })
          }
        }
      }
      //
      return data
    },
    getCircleX(radians, radius) {
      return Math.cos(radians) * radius;
    },
    getCircleY(radians, radius) {
      return Math.sin(radians) * radius;
    },
    recursiveCollisionDetection(myArea, myNum, radians = 0, radius = 0, count = 0, position_yx = {
        y: 0,
        x: 0
      }) {
      let num = 15
      let collision = false
      let i = 0
      for(let area of this.collisionAreas) {
        if(myNum != i) {
          let y = Math.round(this.getCircleY(radians, radius))
          let x = Math.round(this.getCircleX(radians, radius))
          collision = this.detectAreaCollision({
            y1: myArea.y1 - y,
            y2: myArea.y2 - y,
            x1: myArea.x1 + x,
            x2: myArea.x2 + x
          }, area)
          if(collision) break
        }
        i++
      }
      if(collision) {
        if(radius == 0 || radians / Math.PI >= 2) {
          radius += num
          radians = 0
        } else {
          radians += (Math.PI / 2) / 9
        }
        if(count < 1000) {
          position_yx = this.recursiveCollisionDetection(myArea, myNum, radians, radius, count,
            position_yx = {
              y: -1 * Math.round(this.getCircleY(radians, radius)),
              x: Math.round(this.getCircleX(radians, radius))
            }
          )
        } else {
          // 断念
          //console.log('探索断念')
          position_yx = {
            y: 0,
            x: 0
          }
        }
        count ++
      }
      return position_yx
    },
    detectAreaCollision(myArea, area) {
      let detection = false
      // 面で判定
      for (let y = myArea.y1; y <= myArea.y2; y+=10) {
        for (let x = myArea.x1; x <= myArea.x2; x+=10) {
          if(y >= area.y1 && y <= area.y2 && x >= area.x1 && x <= area.x2) {
            detection = true
          }
        }
      }
      return detection
    },
    // 
    // 
    // 
    filter_path: function (id) {
      let path = Routes.item_path(id)
      let f = this.filter
      //console.log(Routes)
      if(f.motive   != '') path = Routes.item_motive_path(id, f.motive)
      if(f.category != '') path = Routes.item_category_path(id, f.motive, f.category)
      if(f.tag      != '') path = Routes.item_tag_path(id, f.motive, f.category, f.tag)
      return path
    },
    //
    flyTo2: function () {
      //console.log('flyTo2')
      this.$refs.map.mapObject.flyTo(this.face.latlng, (this.is_pc ? 14 : 15), {
        animate: false
      })
    },
    flyto: function (face = '', event) {
      //console.log('flyto')
      if(face == 'face') {
        this.$refs.map.mapObject.flyTo(this.face.latlng, 18, {
          animate: true,
          duration: 0.2
        })
      } else {
        //console.log('yyyyyyyy')
        let yx = this.i_t_list[this.selecteditem][0] === "item" ? this.items[this.i_t_list[this.selecteditem][1]].latlng : this.tweets[this.i_t_list[this.selecteditem][1]].latlng
        this.$refs.map.mapObject.flyTo(yx, 18, {
          animate: true,
          duration: 0.2
        })
      }
    },
    fitBounds: function () {
      if(this.selecteditem !== 99999) {
        //console.log('fitBounds')
        let yx = this.i_t_list[this.selecteditem][0] === "item" ? this.items[this.i_t_list[this.selecteditem][1]].latlng : this.tweets[this.i_t_list[this.selecteditem][1]].latlng
        // flagかラベルの外側位置
        //if(yx.lat != this.face.latlng.lat && yx.lng != this.facenglng.lng) {
          this.$refs.map.mapObject.fitBounds([yx, this.face.latlng], {
            padding: this.padding,
            //animate: false,
            animate: this.i_t_list.length < 20
          })
        //}
      } else {
        this.flyTo2()
      }
    },
    selectMarker: function (i) {
      this.$parent.$emit('setSelectedNum', i)
    },
    selectLocation: function () {
      this.$parent.$emit('selectLocation')
    },
    maplink: function () {
      //console.log('maplink')
      this.maplink_on = !this.maplink_on
      //this.$parent.$emit('maplink')
    },
//....................................................
//.iii................................................
//.iii................................................
//.iii................................................
//..........c...........o.............................
//.iii...ccccccc.....ooooooo...onnnnnnnn....sssssss...
//.iii..ccccccccc...ooooooooo..onnnnnnnnn..nssssssss..
//.iii..cccccccccc.coooooooooo.onnnnnnnnnn.nssssssss..
//.iii.iccc...cccc.cooo...oooo.onnnn..nnnn.nsss..sss..
//.iii.iccc....c..ccoo....oooo.onnn...nnnn.nssssss....
//.iii.iccc.......ccoo.....ooo.onn....nnnn.nssssssss..
//.iii.iccc.......ccoo.....ooo.onn....nnnn...sssssss..
//.iii.iccc....cccccoo....oooo.onn....nnnn.nss..ssss..
//.iii.icccc..cccc.cooo..ooooo.onn....nnnnnnsss..sss..
//.iii..cccccccccc.cooooooooo..onn....nnnn.nssssssss..
//.iii...cccccccc...ooooooooo..onnn...nnnn.nssssssss..
//.iii....cccccc.....oooooo....onnn...nnnn...ssssss...
//....................................................
    generateLandmarkIcons: function (num) {
      if(this.landmarks[num].icon === 'none') {
        return L.divIcon({className: 'has-text-grey-light has-text-weight-semibold c-text-shadow-03 c-line-height-7 is-size-7 c-font-feature-settings-palt-1 letter-spacing-0', html: '<div style="font-size: ' + 9 + 'px; line-height: ' + 9 + 'px">' + this.landmarks[num].name + '</div>', iconSize: [(this.landmarks[num].name.length * 9), 9]})
      } else {
        return L.divIcon({className: '', html: '<div><img src="/img/landmarks/' + this.landmarks[num].icon + '.svg?y20220212"></div>', iconSize: [24, 24]})
      }
    },
    generateLandmarkText: function (num) {
      let name = this.landmarks[num].name
      let width = this.getTextWidth(name, 4, 12, 400, 'rem')
      return L.divIcon({className: 'fuchi_white has-text-grey-darker c-text-shadow-03 c-line-height-7 is-size-7 c-font-feature-settings-palt-1 letter-spacing-0 has-text-centered', html: '<div>' + name + '</div>', iconAnchor: [width/2, -10], iconSize: [width, 10]})
    },
    generateNameIcons: function (num, isActive) {
      //console.log('generateNameIcons')
      let name = ""
      let class_name = "dot_name c-py-00625 c-pl-025 c-height-6 c-line-height-6 c-is-size-7h c-font-feature-settings-palt-1 letter-spacing-0 has-text-grey-dark has-text-weight-semibold"
      if(this.i_t_list[num][0] === 'item') {
        name = this.items[this.i_t_list[num][1]].name
      } else {
        // tweet
        name = this.tweets[this.i_t_list[num][1]].place_name
      }
      let width = this.getTextWidth(name, 8, 0.625, 600, 'rem')
      return L.divIcon({className: class_name, html: name, iconAnchor: [0, 10], iconSize: [width, (16)]})
    },
//....................................................................................................................................
//................................tTTTTTTTTTTTTT............................ttWW....WWWW...WWWWWWiii.........ddd.........tthh.........
//...........................ttt..tTTTTTTTTTTTTT......................xttt..ttWW....WWWW...WWWW.Wiii.........ddd...ttt...tthh.........
//...........................ttt..tTTTTTTTTTTTTT......................xttt..ttWW...WWWWW...WWWW.Wiii.........ddd...ttt...tthh.........
//...................e.......ttt.......TTTT........ee.................xttt...tWWW..WWWWW...WWWW..............ddd...ttt...tthh.........
//...gggggggggg...eeeeeee..tttttttt....TTTT.....eeeeeee..eexxx..xxxxxxxttttt.tWWW..WWWWWW..WWWW.Wiii...ddddddddd.ddtttttttthhhhhhhh...
//..ggggggggggg..eeeeeeeee.tttttttt....TTTT....Teeeeeeee..exxx.xxxxxxxxttttt.tWWW..WWWWWW.WWWW..Wiii.idddddddddd.ddtttttttthhhhhhhhh..
//..ggggggggggg.eeeeeeeeee.tttttttt....TTTT....Teeeeeeeee.exxxxxxxx.xxxttttt..WWW.WWWWWWW.WWWW..Wii..idddddddddd.ddtttttttthhhhhhhhh..
//.gggg...ggggg.eeee...eeee..ttt.......TTTT...TTee...eeee..xxxxxxxx...xttt....WWWWWWWWWWWWWWWW..Wii..iddd...dddd...ttt...tthhh..hhhh..
//.gggg....gggg.eeeeeeeeeee..ttt.......TTTT...TTeeeeeeeee...xxxxxx....xttt....WWWWWWW.WWWWWWW...Wii.iidd....dddd...ttt...tthhh...hhh..
//.gggg....gggg.eeeeeeeeeee..ttt.......TTTT...TTeeeeeeeee...xxxxx.....xttt....WWWWWWW.WWWWWWW...Wii.iidd.....ddd...ttt...tthh....hhh..
//.gggg....gggg.eeeeeeeeeee..ttt.......TTTT...TTeeeeeeeee...xxxxxx....xttt.....WWWWWW..WWWWWW...Wii.iidd.....ddd...ttt...tthh....hhh..
//.gggg...ggggg.eee....eee...ttt.......TTTT...TTee....ee...xxxxxxx....xttt.....WWWWWW..WWWWWW...Wii..idd....dddd...ttt...tthh....hhh..
//..ggggggggggg.eeee..eeeee..tttt.t....TTTT...TTeee..eeee.exxxxxxxx...xttt.t...WWWWW...WWWWW....Wii..iddd..ddddd...tttt.ttthh....hhh..
//..ggggggggggg.eeeeeeeeee...tttttt....TTTT....Teeeeeeeee.exxx.xxxxx..xtttttt..WWWWW....WWWW....Wii..idddddddddd...tttttttthh....hhh..
//...gggggggggg..eeeeeeeee...tttttt....TTTT.....eeeeeeee.eexxx..xxxxx..tttttt...WWWW....WWWW....Wiii..dddddddddd...tttttttthh....hhh..
//..ggg...ggggg....eeeee......ttttt....TTTT......eeeeee..eexx....xxxx..tttttt...WWWW....WWWW....Wiii...ddddddddd....ttttttthh....hhh..
//.ggggggggggg........................................................................................................................
//..gggggggggg........................................................................................................................
//...ggggggg..........................................................................................................................
//....................................................................................................................................
    getTextWidth: function (text, margin, fontSize = 12, fontWeight = '400', unit = 'px') {
      const div = document.createElement('div')
      div.innerText = text
      //div.style.letterSpacing = '0'
      div.style.fontFamily = 'Helvetica Neue, Arial, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif'
      div.style.fontWeight = fontWeight
      div.style.fontSize = fontSize + unit
      div.style.display = 'inline-block'
      document.body.appendChild(div)
      let width = div.getBoundingClientRect().width + margin || (text.length * fontSize + margin)
      // offsetWidthができたらすぐに消す  
      setTimeout(() => {
        document.body.removeChild(div)
      }, 0)
      //console.log(text + ":" + width)
      return width
    }
  },
//........................................................................................
//..................................................................................dddd..
//........................................................tttt......................dddd..
//........................................................tttt......................dddd..
//........................o...............................tttt.......ee.............dddd..
//.mmmmmmmmmmmmmmm.....ooooooo...ouuu...uuuu.unnnnnnnn..nntttttt...eeeeeee....dddddddddd..
//.mmmmmmmmmmmmmmmm...ooooooooo..ouu....uuuu.unnnnnnnnn.nntttttt..eeeeeeee...ddddddddddd..
//.mmmmmmmmmmmmmmmm..moooooooooo.ouu....uuuu.unnnnnnnnnnnntttttt.teeeeeeeee.eddddddddddd..
//.mmmm..mmmmm..mmmm.mooo...oooo.ouu....uuuu.unnnn..nnnn..tttt...teee..eeee.eddd...ddddd..
//.mmm...mmmm...mmmmmmoo....oooo.ouu....uuuu.unnn...nnnn..tttt..tteeeeeeeeeeeddd....dddd..
//.mmm...mmmm...mmmmmmoo.....ooo.ouu....uuuu.unn....nnnn..tttt..tteeeeeeeeeeedd.....dddd..
//.mmm...mmmm...mmmmmmoo.....ooo.ouu....uuuu.unn....nnnn..tttt..tteeeeeeeeeeeddd....dddd..
//.mmm...mmmm...mmmmmmoo....oooo.ouuu..uuuuu.unn....nnnn..tttt..ttee....eee.eddd....dddd..
//.mmm...mmmm...mmmm.mooo..ooooo.ouuu.uuuuuu.unn....nnnn..tttt.t.teee..eeeeeedddd..ddddd..
//.mmm...mmmm...mmmm.mooooooooo..ouuuuuuuuuu.unn....nnnn..tttttttteeeeeeeee..ddddddddddd..
//.mmm...mmmm...mmmm..ooooooooo...uuuuuuuuuu.unnn...nnnn...tttttt.eeeeeeeee..ddddddddddd..
//.mmm...mmmm...mmmm...oooooo......uuuuuuuuu.unnn...nnnn...tttttt..eeeeee......ddddddddd..
//........................................................................................
  mounted () {
    //console.log('map.vue mounted')
    let _self = this
    this.items_org = this.items
    this.tweets_org = this.tweets
    this.fitBounds()
  }
}
</script>
<!--
//..................................................................
//...SSSSSSSSS.STTTTTTTTTTTTTYYYY.....YYYYYLLLL.......EEEEEEEEEEEE..
//..SSSSSSSSSSSSTTTTTTTTTTTTTYYYYY....YYYY.LLLL........EEEEEEEEEEE..
//..SSSSSSSSSSSSTTTTTTTTTTTTT.YYYY...YYYYY.LLLL........EEEEEEEEEEE..
//..SSSS...SSSS.....TTTT......YYYYY.YYYYY..LLLL........EEE..........
//..SSSS....SSS.....TTTT.......YYYY.YYYY...LLLL........EEE..........
//..SSSSS...........TTTT.......YYYYYYYYY...LLLL........EEE..........
//..SSSSSSSSS.......TTTT........YYYYYYY....LLLL........EEEEEEEEEE...
//...SSSSSSSSS......TTTT.........YYYYYY....LLLL........EEEEEEEEEE...
//....SSSSSSSSS.....TTTT.........YYYYY.....LLLL........EEEEEEEEEE...
//........SSSSS.....TTTT..........YYY......LLLL........EEE..........
//.SSSS.....SSSS....TTTT..........YYY......LLLL........EEE..........
//.SSSS.....SSSS....TTTT..........YYY......LLLL........EEE..........
//.SSSSSS.SSSSS.....TTTT..........YYY......LLLLLLLLLLL.EEEEEEEEEEE..
//..SSSSSSSSSSS.....TTTT..........YYY......LLLLLLLLLLL.EEEEEEEEEEE..
//...SSSSSSSSS......TTTT..........YYYY.....LLLLLLLLLLLEEEEEEEEEEEE..
//....SSSSSSS.......TTTT..........YYY......LLLLLLLLLLLEEEEEEEEEEEE..
//..................................................................
-->
<style scoped>
.map-frame {
  z-index: 99999;
}
.maplink-button {
  position: absolute;
  z-index: 9999999;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
}
.maplink-circle {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  position: absolute;
  z-index: 9999999;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
}
.map-buttons {
  position: absolute;
  right: 15px;
  z-index: 9999999;
}
.map-button-shadow {
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
}
</style>
